﻿import { useState, useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    Theme,
    teamsLightTheme,
    teamsDarkTheme,
    teamsHighContrastTheme,
    webLightTheme,
    webDarkTheme,
    themeToTokensObject,
    Button,
    DataGrid,
    tokens,
    Menu,
    MenuItem,
    MenuTrigger,
    MenuButton,
    MenuPopover,
    MenuList,
    makeStyles,
    TabList,
    Tab,
    shorthands,
    TableColumnDefinition,
    createTableColumn,
    TableCellLayout,
    DataGridHeader,
    DataGridRow,
    DataGridHeaderCell,
    DataGridBody,
    DataGridCell,
    TableHeaderCell,
    TableRowId,
    DataGridProps,
    OverlayDrawer,
    DrawerHeader,
    DrawerHeaderTitle,
    DrawerBody,
    Drawer,
    Field,
    Input,
    Label,
    DialogContent,
    Dialog,
    DialogSurface,
    DialogBody,
    DialogTitle,
    DialogActions,
    ToolbarToggleButton,
    MessageBar,
    MessageBarBody,
    MessageBarTitle,
    MessageBarActions,
    MessageBarIntent,
    Table,
    TableHeader,
    TableRow,
    Textarea,
    TableSelectionCell,
    renderCombobox_unstable,
    TableBody,
    TableCell,
    useTableFeatures,
    useTableSelection,
    Spinner,
    DialogTrigger,
    ToolbarGroup,
    Checkbox,
    TableColumnId
} from "@fluentui/react-components";
import { AddRegular, ArrowExportRegular, ArrowImportRegular, BookSearchRegular, CheckmarkRegular, DeleteRegular, Dismiss24Regular, DismissRegular, EditRegular, LockClosedRegular, LockOpenRegular, NavigationRegular, QuestionCircleRegular, RenameRegular, SignOutRegular, TableRegular, CallConnectingRegular } from "@fluentui/react-icons";
import { Dropdown, Option, Toolbar, ToolbarButton } from "@fluentui/react-components";
import useStore from "../../apiFunctions/store/store";
import usePersistentStore from "../../apiFunctions/store/persistentStore";
import { Header, CustomMenuButton } from "documentdrafter-components";
import { ISlimWorkspace, ScreenSize, CustomFluentProvider } from "documentdrafter-components";
import { getCustomTheme } from "documentdrafter-components";
import { getEndpoints, setEndpoints, MainPageSignalRWrapper, ISignalRUpdate, SignalRUpdateType, ISlimCachedDirectory } from "documentdrafter-components";
import "@fluentui/react/dist/css/fabric.min.css";
import React from "react";
import { IUserProps, IUserShare } from "../entitiesAPI";
import { Selection, SelectionMode } from '@fluentui/react/lib/Selection';
import { v4 as uuidv4 } from 'uuid';


import {
    DocumentTableRegular,
    ListRegular

} from "@fluentui/react-icons";
import { IDataSet, IDataSetColumn, IDataSetColumnType, apiendpointtype } from "../ddentities";
import { _copyAndSort, classNames, classNamesMessageBar, formatDate, newGuid } from "../basefunctions";
import { getAuthObject } from "../../apiFunctions/authenticateFunctions";
import { ChangeDataSetSqlTableEnabled, ChangeEndPointTypeDataSet, EditColumnDataSetSqlTable, getShareUsers, RefreshTable } from "../../apiFunctions/baseFunctions/baseFunctions";
import { Sticky, StickyPositionType } from '@fluentui/react/lib/Sticky';
import { forEach } from "lodash";
import { set } from "idb-keyval";
import { FixedSizeList as List } from 'react-window';
import { DetailsList, DetailsListLayoutMode } from "@fluentui/react/lib/DetailsList";
import InfiniteScroll from "react-infinite-scroll-component";
import { SearchBox } from "@fluentui/react-search-preview";
import { DatePicker } from "@fluentui/react-datepicker-compat";


interface IdataSetGrid {

    dataSet: IDataSet;
    refresh: any;
    closeGrid: any;
    updateDataSet: (dataSet: IDataSet) => void;

}

export const SqlTablesManage: React.FC<IdataSetGrid> = (props) => {


    const [items, setItems] = useState([]);
    const [activeDataSet, setActiveDataset] = React.useState<IDataSet>(props.dataSet);
    const [continuationToken, setContinuationToken] = React.useState(null);
    const [totalCount, setTotalCount] = React.useState<number>(0);
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(50);
    const [hasMoreData, setHasMoreData] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);

    const containerRef = React.useRef(null);
    const tableRef = React.useRef(null);


    const [activeColumn, setActiveColumn] = React.useState<IDataSetColumn>(undefined);
    const [showDeleteVerification, setShowDeleteVerification] = React.useState<boolean>(false);
    const [addColumnOpen, setAddColumnOpen] = React.useState<boolean>(false);
    const [selectedItems, setSelectedItems] = React.useState([]);
    const [showMessageBar, setShowMessageBar] = React.useState<boolean>(false);
    const [messageBarText, setMessageBarText] = React.useState<string>("");
    const [messageBarType, setMessageBarType] = React.useState<MessageBarIntent>("info");


    const [noRefreshData, setNoRefreshData] = React.useState<boolean>(false);
    const [activeInput, setactiveInput] = React.useState<HTMLInputElement>(null);
    const [multiLineEditItem, setMultiLineEditItem] = React.useState<DataSetColumnsType>(null);
    const [multiLineEditCol, setMultiLineEditCol] = React.useState<string>(null);
    const [multiLineEditValue, setMultiLineEditValue] = React.useState<string>(null);
    const [multiLineEditParent, setMultiLineEditParent] = React.useState<string>(null);
    const [updatePostData, setUpdatePostData] = React.useState<string>(null);
    const isDark = usePersistentStore().darkMode;
    const portalConfig = useStore().portalConfig;
    const theme = isDark ? webDarkTheme : webLightTheme;
    const customTokens = themeToTokensObject(getCustomTheme(!isDark));
    const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState("");

    const [key, setKey] = useState(0);

    // Function to force re-render
    const forceUpdate = () => setKey(prevKey => prevKey + 1);

    const inputColumnRef = React.useRef<HTMLInputElement>(null);

    var dirtyRow = false;
    const [selectedRows, setSelectedRows] = React.useState(
        new Set<TableRowId>([])
    );


    function isDevelopment(): boolean {
        if (window.location.hostname.indexOf('localhost') > -1) return true;
        if (window.location.hostname.indexOf('v2020') > -1) return true;
        return false;
    }


    interface IItem {
        id: string;
        name: string;
        value: string;
    }



    const itemsRef = React.useRef(items);
    
    
    React.useEffect(() => {
       console.log('refrehs')
    }, [activeDataSet])


   


    type DataSetColumnsType = {
        [K in keyof typeof activeDataSet.columns]: string;
    }



  


    

    function setDefaultValue(item: DataSetColumnsType, colId: string) {

        try {
            return item[colId].toString()
        } catch (e) {

            return "";
        }
    }


    const [focusedCell, setFocusedCell] = useState(null);
    

    
  



    

    const UpdateEnabledOnDataSet = (dataSet: IDataSet) => {
        getAuthObject().then((authResult) => {
            ChangeDataSetSqlTableEnabled(authResult, dataSet.id, dataSet.enabled).then((newDataSet) => {
                setShowMessageBar(true);
                setMessageBarType("success");
                setMessageBarText(newDataSet.enabled ? "Table now enabled" : "Table now disabled");
                setActiveDataset(newDataSet);
                props.refresh();
                setNoRefreshData(true);

            })
                .catch(error => {
                    setShowMessageBar(true);
                    setMessageBarType("error");
                    setMessageBarText("Data table could not be enabled : " + error);
                    var tmpset = dataSet;
                    tmpset.enabled = false;
                    setActiveDataset(tmpset);


                });
        });
    }

    const UpdateEndPointTypeDataSet = (dataSet: IDataSet) => {
        getAuthObject().then((authResult) => {
            ChangeEndPointTypeDataSet(authResult, dataSet.id, dataSet.endPointType).then((newDataSet) => {
                setShowMessageBar(true);
                setMessageBarType("success");
                setMessageBarText(newDataSet.endPointType === apiendpointtype.list ? "Drop down now enabled" : "Search now enabled");
                setActiveDataset(newDataSet);
                props.refresh();
                setNoRefreshData(true);

            })
                .catch(error => {
                    setShowMessageBar(true);
                    setMessageBarType("error");
                    setMessageBarText("Data table could not be enabled : " + error);
                    var tmpset = dataSet;
                    tmpset.enabled = false;
                    setActiveDataset(tmpset);

                });
        });
    }

   
    
   
   
    const bottomBoundaryRef = React.useRef(null);
    const dataGridRef = React.useRef(null);

  

    const EditColumnDirect = (currentColumn: IDataSetColumn) => {
        getAuthObject().then((authResult) => {
            EditColumnDataSetSqlTable(authResult, activeDataSet.id, currentColumn).then((dataset) => {
              
                setActiveDataset(dataset);

            });

        });
    }


    const useStyles = makeStyles({
        headerContainer:
        {
            display: 'flex',
            justifyContent: 'space-between',

        },
        headerColumn1: {
            marginRight: 'auto'
        },
        tableHeader:
        {
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: tokens.colorNeutralBackground1
        }
        ,
        toolbar: {
            justifyContent: "space-between",
        },


    })
    const styles = useStyles();

    function Header() {
        return (
            <div className={styles.headerContainer}
                style={{
                    marginLeft: tokens.spacingHorizontalXL,
                    marginRight: tokens.spacingHorizontalXL,
                    marginTop: tokens.spacingVerticalXXXL,
                    marginBottom: tokens.spacingVerticalXL,
                }}

            >
                <div

                    className={styles.headerColumn1}>


                    <h3>Configuration of table {props.dataSet.name}</h3>
                </div>


                <Toolbar


                >
                    <ToolbarButton

                        icon={<DismissRegular />} onClick={() => {

                            props.closeGrid(true);


                        }} />
                </Toolbar>
            </div>

        )
    }

 
   
    function getColumnType(colType: IDataSetColumnType) {
        if (colType === IDataSetColumnType.SingleLineText)
            return "Single line text";
        if (colType === IDataSetColumnType.MultiLineText)
            return "Multi line text";
        if (colType === IDataSetColumnType.Date)
            return "Date";
        if (colType === IDataSetColumnType.Number)
            return "Number";
        if (colType === IDataSetColumnType.GuidanceNote)
            return "Guidance note";
    }

    function stringToColumnType(colType: string) {
        if (colType === "Single line text") return IDataSetColumnType.SingleLineText;
        if (colType === "Multi line text") return IDataSetColumnType.MultiLineText;
        if (colType === "Date") return IDataSetColumnType.Date;
        if (colType === "Number") return IDataSetColumnType.Number;
        if (colType === "Guidance note") return IDataSetColumnType.GuidanceNote;
    }
  

    function ShowMessageDialog() {

        return (


            <MessageBar key={messageBarType} intent={messageBarType}   >
                <MessageBarBody>
                    <MessageBarTitle>{messageBarText}</MessageBarTitle>


                </MessageBarBody>
                <MessageBarActions
                    containerAction={
                        <Button
                            aria-label="dismiss"
                            appearance="transparent"
                            icon={<DismissRegular />}
                            onClick={() => setShowMessageBar(false)}
                        />
                    }
                >
                </MessageBarActions>
            </MessageBar>


        )
    }

    

    const [listHeight, setListHeight] = useState(window.innerHeight * 0.7);
    useEffect(() => {
        const handleResize = () => {
            setListHeight(window.innerHeight * 0.7); // Update the height to be 70% of the viewport height
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    React.useEffect(() => {
        if (addColumnOpen && inputColumnRef.current) {
            inputColumnRef.current.focus();
        }
    }, [addColumnOpen]);


    React.useEffect(() => {


    }, [showDeleteDialog])

   

    const renderCellAction = (columnID:TableColumnId, item: IDataSetColumn, rowId: TableRowId) => {
        if (columnID === "Name")
            return (<div>{item.name}</div>);
        if (columnID === "columntype") 
            return (<div>{getColumnType(item.columnType)}</div>);
        if (columnID === "Searchable") 
            return (
                
                <Checkbox
                    checked={item.searchable}
                    onClick={(e) => {
                            activeDataSet.columns[rowId].searchable = !item.searchable
                            EditColumnDirect(activeDataSet.columns[rowId])
                    }}
                    disabled={activeDataSet.columns[rowId].columnType === IDataSetColumnType.Date || activeDataSet.columns[rowId].columnType === IDataSetColumnType.Number  }
                />);
        if (columnID === "indropdown")
            return (<Checkbox checked={item.inDropDown} onClick={() => {
                activeDataSet.columns[rowId].inDropDown = !item.inDropDown
                EditColumnDirect(activeDataSet.columns[rowId])


            }}
                disabled={activeDataSet.columns[rowId].columnType === IDataSetColumnType.Date || activeDataSet.columns[rowId].columnType === IDataSetColumnType.Number}
            />);
        if (columnID === "autofill")
            return (<Checkbox checked={item.autoFill} onClick={() => {
                activeDataSet.columns[rowId].autoFill = !item.autoFill
                EditColumnDirect(activeDataSet.columns[rowId])

            }}
                
            />);

    };


    const columns: TableColumnDefinition<IDataSetColumn>[] = [
        createTableColumn<IDataSetColumn>({
            columnId: "Name",
           
            renderHeaderCell: () => {
                return "Name";
            },
           
        }),
        createTableColumn<IDataSetColumn>({
            columnId: "columntype",
            
            renderHeaderCell: () => {
                return "Type";
            },
        
        }),
        createTableColumn<IDataSetColumn>({
            columnId: "Searchable",
            renderHeaderCell: () => {
                return "Searchable";
            },
          
        }),
        createTableColumn<IDataSetColumn>({
            columnId: "indropdown",
            renderHeaderCell: () => {
                return "In dropdown";
            },
          
        }),
        createTableColumn<IDataSetColumn>({
            columnId: "autofill",
            renderHeaderCell: () => {
                return "Only autofill";
            },
         
        }),
    ];

 

    return (

        <CustomFluentProvider isDark={isDark} colorConfiguration={portalConfig?.colorConfiguration}>
            <div
                style={{
                    height: "100vh",
                    width: "100vw",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 9999,
                    background:
                        `radial-gradient(circle at 64% 46%,${customTokens.gradientBackground1} 0,${customTokens.gradientBackground2} 57%,${customTokens.gradientBackground3} 100%)`

                }}
            >
                {showMessageBar && ShowMessageDialog()}
                {Header()}
                
           

                <div
                    style={{
                        overflowX: "hidden",

                        backgroundColor: tokens.colorNeutralBackground1,
                        color: tokens.colorNeutralForeground1,
                        borderTopLeftRadius: tokens.borderRadiusXLarge,
                        borderTopRightRadius: tokens.borderRadiusXLarge,
                        marginLeft: tokens.spacingHorizontalL,
                        marginRight: tokens.spacingHorizontalL,
                        height: "80dvh",
                        width: "97vw"


                    }}>

                    <Toolbar aria-label="Vertical Button" className={useStyles().toolbar}  >
                        <ToolbarGroup>
                           
                            <ToolbarButton icon={activeDataSet.enabled ? <LockOpenRegular /> : <LockClosedRegular />} onClick={() => {
                                var dataSet = activeDataSet;
                                dataSet.enabled = !dataSet.enabled;
                                UpdateEnabledOnDataSet(dataSet);


                            }}>{activeDataSet.enabled ? "Disable" : "Enable"}
                            </ToolbarButton>

                            <ToolbarButton disabled={activeDataSet.enabled} icon={activeDataSet.endPointType === apiendpointtype.list ? <TableRegular /> : <BookSearchRegular />} onClick={() => {
                                var dataSet = activeDataSet;
                                if (dataSet.endPointType == apiendpointtype.list) {
                                    dataSet.endPointType = apiendpointtype.selector;
                                }
                                else {
                                    dataSet.endPointType = apiendpointtype.list;
                                }

                                UpdateEndPointTypeDataSet(dataSet);

                            }}>Display model
                            </ToolbarButton>
                            <ToolbarButton
                                icon={<CallConnectingRegular />}
                                onClick={async () => {
                                    let authObj = await getAuthObject();
                                    let result = await RefreshTable(authObj, activeDataSet.id);
                                    setActiveDataset(result);
                                    props.updateDataSet(result);
                                }}
                            >

                            </ToolbarButton>
                        </ToolbarGroup >


                    </Toolbar>
                    {/* {items.length} {hasMoreData?"HasMoreData":"NoMoreData"}*/}

                    <DataGrid
                        items={activeDataSet.columns}
                        columns={columns}
                        style={{ minWidth: "550px", paddingLeft:"8px", paddingRight:"8px" }}
                    >
                        <DataGridHeader>
                            <DataGridRow>
                                {({ renderHeaderCell }) => (
                                    <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                                )}
                            </DataGridRow>
                        </DataGridHeader>

                        <DataGridBody<IDataSetColumn>>
                            {({ item, rowId }) => (
                                <DataGridRow<IDataSetColumn>
                                    key={rowId}
                                 
                                >
                                    {({ renderCell, columnId }) => (
                                        <DataGridCell>
                                            {renderCellAction(columnId,item,rowId)}
                                        </DataGridCell>
                                    )}
                                </DataGridRow>
                            )}
                        </DataGridBody>


                    </DataGrid>






                </div>
                <div
                    style={{
                        height: "33px",
                        width: "97vw",
                        backgroundColor: tokens.colorNeutralBackground1,
                        borderBottomRightRadius: tokens.borderRadiusXLarge,
                        borderBottomLeftRadius: tokens.borderRadiusXLarge,
                        marginLeft: tokens.spacingHorizontalL,
                        marginRight: tokens.spacingHorizontalL,
                    }}
                />

            </div>

        </CustomFluentProvider>

    )
}