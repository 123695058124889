import React, { FC, useState, useEffect } from "react";
import { Preview } from "documentdrafter-components";
import { IApiEndpoints, IDirtyAnswer, IQuestionnaireObj, IUserProps } from "documentdrafter-components";
import { setEndpoints } from "documentdrafter-components";
import { Theme, themeToTokensObject } from "@fluentui/react-components";
import { getCustomTheme,CustomFluentProvider } from "documentdrafter-components";
import { isDark } from "@fluentui/react";
import useStore from "../../apiFunctions/store/store";

declare global {
    interface Window {
        changeThemeInPreviewWindow: (isDark: boolean) => void;
    }
}

export const PreviewWindow: FC = () => {
    const [initialized, setInitialized] = useState<boolean>(false);
    const [questionnaireObj, setQuestionnaireObj] = useState<IQuestionnaireObj>();
    const [dirtyAnswers, setDirtyAnswers] = useState<IDirtyAnswer[]>([]);
    const [userObj, setUserObj] = useState<IUserProps>();
    const [apiEndpoints, setApiEndpoints] = useState<IApiEndpoints>();
    //const [theme, setTheme] = useState<Theme>();
    const [isDarktheme, setIsDarkTheme] = useState<boolean>(false);
    //const [previewList, setPreviewList] = useState<IPreviewInfo[]>([]);
    //const [previewHTML, setPreviewHTML] = useState<string>("");

    // const [activePreviewId, setActivePreviewId] = useState<string>("");
    // const [previewLoading, setPreviewLoading] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const portalConfig = useStore().portalConfig

    useEffect(() => {
        const localQuestionnaireObj: IQuestionnaireObj = window.opener["questionnaireObj"];
        const localUserObj: IUserProps = window.opener["userObj"];
        const localApiEndpoints: IApiEndpoints = window.opener["apiEndpoints"];
        //const localTheme: Theme = window.opener["theme"];
        const isDark: boolean = window.opener["isDark"];
        //const localFullFiles: IFileCondition[] = window.opener["fullFileList"];
        //const localPreviewList: IPreviewInfo[] = window.opener["previewList"];

        setApiEndpoints(localApiEndpoints);
        console.log("endpoints: ", localApiEndpoints);
        setQuestionnaireObj(localQuestionnaireObj);
        setUserObj(localUserObj);
        //setPreviewList(localPreviewList);
        setEndpoints(localApiEndpoints);
        //setTheme(localTheme);
        setIsDarkTheme(isDark);

        setTimeout(() => {
            setInitialized(true);
            setIsOpen(true);
        }, 250);

        window.addEventListener("beforeunload", function () {
            window.opener.closePreviewNewWindow();
        });
    }, []);

    window.changeThemeInPreviewWindow = function (isDark: boolean) {
        setIsDarkTheme(isDark);
    };

    if (initialized) {
        const customTokens = themeToTokensObject(getCustomTheme(!isDarktheme));

        return (
            <CustomFluentProvider isDark={isDarktheme} colorConfiguration={portalConfig?.colorConfiguration}>
                
                    <div
                        style={{
                            width: "100vw",
                            height: "100vh",
                            background: `radial-gradient(circle at 64% 46%,${customTokens.gradientBackground1} 0,${customTokens.gradientBackground2} 57%,${customTokens.gradientBackground3} 100%)`,
                        }}
                    >
                        <Preview
                            userObj={userObj!}
                            questionnaireObj={questionnaireObj!}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            dirtyAnswers={dirtyAnswers}
                            showDialog={(type: string) => {
                                console.log(type);
                            }}
                        isDarkTheme={isDarktheme}
                            endpoints={apiEndpoints!}
                            previewWindow={null}
                            setPreviewWindow={(_pWindow) => { }}
                            isOwnWindow={true}
                        />
                    </div>
                
            </CustomFluentProvider>
        );
    } else return null;
};
