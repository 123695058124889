﻿import { GetAntiForgeryToken, GetOpenIdProvider, Oauth2Login, setCookieAuth } from '../apiFunctions/authenticateFunctions';
import { IConfigLogin, ILoginstatus } from './ddentities';
import { TextField, PrimaryButton, Stack, Link, mergeStyleSets, loadTheme, IPartialTheme, IPalette, ITextField, IButtonStyles, IStackStyles, Icon } from '@fluentui/react';
import { AuthenticationResult, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import useStore from "../apiFunctions/store/store";
import usePersistentStore from "../apiFunctions/store/persistentStore";
import { getEndpoints, setEndpoints, GetWorkspaces } from "documentdrafter-components";
import { IUserProps } from './entitiesAPI';
import validator from "validator";
import { FC, useEffect, useState, createRef } from 'react';
import { getCustomTheme } from "documentdrafter-components";
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Divider,
    Field,
    Input,
    Spinner,
    themeToTokensObject,
    tokens,
    typographyStyles,
} from "@fluentui/react-components";
import { CheckmarkCircle20Regular, CheckmarkCircle20Filled } from '@fluentui/react-icons';
import React from 'react';
import { UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';
import { Navigate, useNavigate } from 'react-router';


interface loginProps {
    ConfigLogin: IConfigLogin;
    Provider: string


}
export const OpenIdLogin: React.FC<loginProps> = (props) => {
    /* const [companyInfo, setCompanyInfo] = useState<IConfigLogin>(null);*/
    const theme = usePersistentStore().currentTheme;
    const isDark = usePersistentStore().darkMode;
    const customTokens = themeToTokensObject(getCustomTheme(!isDark));
    
    
    const [step, setStep] = useState(0);
    

    
    const [loaderMessage, setLoaderMessage] = useState(sessionStorage.getItem("loaderMessage") || "");
    const [errorMessage, setErrorMessage] = useState("");
    const [loginGuid, setLoginGuid] = useState(null);
    const isUserAuthenticated = useStore().isUserAuthenticated;
    const authenticatedUser = useStore().userObj;
    const [currentStep, setCurrentStep] = useState("signin");
    const [userManager, setUserManager] = useState(null);
    const [authInProcess, setAuthInProcess] = useState(false);


    var hostUrl: string = document.location.hostname;
    

    let ruri = window.location.origin + '/user/oidc/' + props.Provider;

    const SetDoNavigate= (url:string)=> {
        sessionStorage.removeItem("nextUrl");
        sessionStorage.setItem("loaderMessage", "");
        window.location.href = url;

    }

    useEffect(() => {

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const nextUrl = params.get("nextUrl");

        if (nextUrl != undefined) {
            sessionStorage.setItem("nextUrl",nextUrl);
        }

        const fetchConfig = async () => {

            try {

                var openIdConfig: any = await GetOpenIdProvider(hostUrl, props.Provider);
                await GetAntiForgeryToken();
                var tempMgr = new UserManager(openIdConfig);
                setUserManager(tempMgr);
              

            }
            catch (error) {
                //not able to load config
                setErrorMessage(error);

            }

        }
        fetchConfig();

    }, [])

    useEffect(() => {



        if (userManager != null && authInProcess===false) {
            // UserManager is ready to use, e.g., for login
            console.log('UserManager is ready to use', userManager);
            if (window.location.href.indexOf("code") > -1 || window.location.href.indexOf("access_token") > -1 || window.location.href.indexOf("id_token") > -1) {

                userManager.signinRedirectCallback().then(user => {
                    // Handle the user object here
                    setAuthInProcess(true);
                    processUser(user);
                    


                }).catch(err => {
                    console.error(err);
                });
            }
            else {

                try {
                    userManager.signinRedirect().catch((error) => {
                        setErrorMessage(error);
                    });
                } catch (e) {
                    setErrorMessage(e);
                }
            }



        }
    }, [userManager]);

    
    const processUser = async (authUser) => {
       

        if (authUser == null) return;

        if (authUser.profile != undefined) {
            var emailToUse = "";
            if (authUser.profile.email.length > 0) emailToUse = authUser.profile.email;
            if (authUser.profile.preferredMail != null) emailToUse = authUser.profile.email;

            var loginObj: any = await Oauth2Login(window.location.hostname, props.Provider, authUser, "");

            if (loginObj.status === "verified") {

                var nexturl = window.location.search;
                if (nexturl.toLowerCase().indexOf("/user/") > -1) {
                    nexturl = "/";
                }
                var csrfToken = getCookie("RequestVerificationToken");
                var session = await fetch('/api/session', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'RequestVerificationToken': csrfToken
                    },
                    body: JSON.stringify(loginObj.session)
                });
                
                sessionStorage.setItem("user", JSON.stringify(loginObj.session));
                const newUserObj: IUserProps = {
                    requestToken: "",
                    userid: "",
                    useremail: loginObj.session.portalUser.Description.email,
                    username: loginObj.session.portalUser.Description.name,
                    casenumberRequired: false,
                    customerName: "",
                    rightslist: loginObj.session.rightslist,
                    permissions: loginObj.session.permissions,
                    customerid: loginObj.session.customerid,
                    guestUser: loginObj.session.portalUser.Groups.indexOf("55555555") > -1,
                    mainUrl: loginObj.session.MainUrl
                };
                newUserObj.requestToken = loginObj.session.requestToken;
                newUserObj.userid = loginObj.session.portalUser.id;
                newUserObj.casenumberRequired = loginObj.session.casenumberrequired;
                newUserObj.customerName = loginObj.session.customername;
                useStore.setState({ isUserAuthenticated: true, portalConfig: loginObj.PortalConfig });
                sessionStorage.setItem("config", JSON.stringify(loginObj.PortalConfig));
                useStore.setState({ userObj: newUserObj });
                var portalUrl = window.location.hostname.indexOf('localhost') > -1 ? 'v2020.dev.documentdrafter.com' : window.location.hostname;
                setCookieAuth("dd.sec.session", loginObj.authenticationKey, 1);
                var endPointResult = await getEndpoints(portalUrl);
                useStore.setState({ endpoints: endPointResult });
                setEndpoints(endPointResult);
                var workspacesResult = await GetWorkspaces(newUserObj);
                useStore.setState({ availableWorkspaces: workspacesResult });
                const search = window.location.search;

                var nextUrl = sessionStorage.getItem("nextUrl");
                if (nextUrl === null || nextUrl === undefined || (nextUrl != undefined && nextUrl.length === 0))
                    nextUrl = "/";
                    

                if (nextUrl.indexOf('addinload') > -1 || sessionStorage.getItem("addinload") || search.indexOf('addinload') > -1) {
                    sessionStorage.removeItem("addinload");
                    SetDoNavigate("/addinstatus?userid=" + newUserObj.useremail + "&customerid=" + newUserObj.customerid);
                    return;
                }
                else {
                    SetDoNavigate(nextUrl);
                }

            }
            else {
                setLoaderMessage("");
                setErrorMessage("User not able to login to Document Drafter, Error: "+loginObj.status);

            }

        } else {
            setLoaderMessage("");
            setErrorMessage("Authentication for provider could not be obtained");

            if (document.location.search.indexOf("addinload") > -1 || sessionStorage.getItem("addinload") !== null) {
                setStep(0);
            } else {
                setStep(0);
            }

        }



    }



    //async function handleTokenResponse(response: AuthenticationResult) {
    //    // handle token response
    //    await GetAntiForgeryToken();
    //    sessionStorage.setItem("accessToken", response.accessToken);
    //    localStorage.setItem("loginUser", response.account.username);
    //    setUserEmail(response.account.username);
    //    var loginResult = await Oauth2Login("","","","");
    //    setCookie("GMo365", 1, 365);
    //    setLoginObj(loginResult);
    //};


    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }





    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#f3f2f1', // Replace with your theme color
        },
        box: {
            padding: '20px',
            backgroundColor: 'white', // Replace with your theme color
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
            borderRadius: '5px',
            maxWidth: '400px',
            width: '100%',
        },
        header: {
            marginBottom: '20px',
            textAlign: 'center',
        },
        logo: {
            width: '100px',
            height: '100px',
            marginBottom: '20px',
            // Add styles for your logo here
        },
    });



    interface IPasswordChecker {
        password: string
    }








    let logoHeight = "40px";
    if (props.ConfigLogin.config?.loginLogoHeight?.length) {
        logoHeight = props.ConfigLogin.config.loginLogoHeight;
        if (logoHeight.indexOf("px") < 0)
            logoHeight += "px";
    }

    let logoMarginTop = "0px";
    if (props.ConfigLogin.config?.loginLogoTopMargin?.length) {
        logoMarginTop = props.ConfigLogin.config.loginLogoTopMargin;
        if (logoMarginTop.indexOf("px") < 0)
            logoMarginTop += "px";
    }

    function getLogo() {
        if (isDark) {
            if (props.ConfigLogin.config?.loginLogoPathDark?.length)
                return (
                    <img
                        key="logo"
                        style={{ height: logoHeight, marginTop: logoMarginTop }}
                        src={props.ConfigLogin.config?.loginLogoPathDark}
                        alt="slim logo" />
                )

        }
        else {
            if (props.ConfigLogin.config?.loginLogoPath?.length)
                return (
                    <img
                        key="logo"
                        style={{ height: logoHeight, marginTop: logoMarginTop }}
                        src={props.ConfigLogin.config?.loginLogoPath}
                        alt="slim logo"
                    />
                )
        }

        return null
    }

    return (
        <>

            <div
                style={{
                    height: "100vh",
                    width: "100vw",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: `radial-gradient(circle at 64% 46%,${customTokens.gradientBackground1} 0,${customTokens.gradientBackground2} 57%,${customTokens.gradientBackground3} 100%)`,
                }}
            >
               

                <Card
                    style={{
                        width: 380,
                        minHeight: 263,
                        padding: `${tokens.spacingVerticalXXL} ${tokens.spacingVerticalXXXL} ${tokens.spacingVerticalXXXL}`,
                        backgroundColor: tokens.colorNeutralBackground1,
                    }}
                >
                    <CardHeader
                        image={getLogo()}
                    />

                    {loaderMessage ? (
                        <div style={{ transform: "translateY(20%)" }}>
                            <Spinner size={"medium"} />

                            <div
                                style={{
                                    ...typographyStyles.subtitle1,
                                    textAlign: "center",
                                    marginTop: tokens.spacingVerticalS,
                                }}
                            >
                                {loaderMessage}
                            </div>
                        </div>
                    ) : (
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>




                            {currentStep === "signin" && (
                                <>
                                    <div style={{ ...typographyStyles.subtitle1, marginBottom: tokens.spacingVerticalXS }}>
                                            {errorMessage === "" ? "Login in process - please wait" : "Error during login"}
                                    </div>
                                    <div style={{ marginBottom: tokens.spacingVerticalXXXL, color: tokens.colorStatusWarningForeground1 }}>
                                            {errorMessage}
                                    </div>

                                    <CardFooter>
                                        {/*<div style={{ width: "100%" }}>*/}
                                        {/*    <Button*/}
                                        {/*        appearance="primary"*/}
                                        {/*        style={{ width: "100%" }}*/}


                                        {/*    >*/}
                                        {/*        Resend email*/}
                                        {/*    </Button>*/}

                                        {/*    <Button*/}
                                        {/*        style={{ width: "100%", marginTop: tokens.spacingVerticalS }}*/}
                                        {/*        onClick={() => {*/}
                                        {/*            setUserPassword("");*/}
                                        {/*            setCurrentStep("password");*/}
                                        {/*        }}*/}
                                        {/*    >*/}
                                        {/*        Back*/}
                                        {/*    </Button>*/}
                                        {/*</div>*/}
                                    </CardFooter>
                                </>
                            )}



                            {currentStep === "success" && (
                                <>
                                    <div style={typographyStyles.subtitle1}>Success!</div>
                                    <div>Your password has been successfully reset.</div>

                                    <CardFooter>
                                        <div style={{ width: "100%" }}>
                                            <Button
                                                appearance="primary"
                                                style={{ width: "100%" }}
                                                onClick={() => {
                                                    setCurrentStep("password");
                                                }}
                                            >
                                                Sign in
                                            </Button>
                                        </div>
                                    </CardFooter>
                                </>
                            )}
                        </div>
                    )}
                </Card>
            </div>


        </>
    );
}
