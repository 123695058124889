﻿import React from "react";

import { typographyStyles } from "@fluentui/react-components";
import { CustomFluentProvider } from "documentdrafter-components";
import usePersistentStore from "../apiFunctions/store/persistentStore";

export const ErrorHandler = () => {
    //const { resetErrorBoundary } = useErrorBoundary();
    const isDark = usePersistentStore().darkMode;
    return (
        <CustomFluentProvider isDark={isDark} colorConfiguration={undefined}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "calc(100dvh - 20dvh)",
                    flexDirection: "column",
                    marginBottom: "20dvh",
                }}
            >
                <div style={typographyStyles.title1}>Something went wrong!</div>
                <div style={typographyStyles.subtitle2}>If the issue persists, contact your administrator</div>
                {/* <Button
                    onClick={() => {
                        resetErrorBoundary();
                    }}
                >
                    Reload
                </Button> */}
            </div>
        </CustomFluentProvider>
    );
};
export default ErrorHandler;
