﻿import { GetAntiForgeryToken, getPortalConfigLogin, LoginAAD, LoginNative, VerifyLoginMethod, LoginViaEmail, LoginCheckViaEmail, AcceptLoginViaEmail, getAuthObject } from '../apiFunctions/authenticateFunctions';
import { IConfigLogin, ILoginstatus } from './ddentities';
import { TextField, PrimaryButton, Stack, Link, mergeStyleSets, loadTheme, IPartialTheme, IPalette, ITextField } from '@fluentui/react';
import { AuthenticationResult, PublicClientApplication } from '@azure/msal-browser';
import useStore from "../apiFunctions/store/store";
import usePersistentStore from "../apiFunctions/store/persistentStore";
import { getEndpoints, setEndpoints, GetWorkspaces } from "documentdrafter-components";
import { IUserProps } from './entitiesAPI';
import validator from "validator";
import { FC, useEffect, useState, createRef } from 'react';
import { getCustomTheme } from "documentdrafter-components";
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Divider,
    Field,
    Input,
    Spinner,
    themeToTokensObject,
    tokens,
    typographyStyles,
} from "@fluentui/react-components";
import React from 'react';
import { setSharePointTenant } from '../apiFunctions/baseFunctions/baseFunctions';

interface loginProps {
    ConfigLogin: IConfigLogin;


}
export const EntraConnected: React.FC<loginProps> = (props) => {
    /* const [companyInfo, setCompanyInfo] = useState<IConfigLogin>(null);*/
    const theme = usePersistentStore().currentTheme;
    const isDark = usePersistentStore().darkMode;
    const customTokens = themeToTokensObject(getCustomTheme(!isDark));
    const [loaderMessage, setLoaderMessage] = useState("");
    const urlParams = new URLSearchParams(window.location.search);
    const tenant = urlParams.get('tenant');
    const admin_consent = urlParams.get('admin_consent');
    const state = urlParams.get('state');

    const portalConfig = useStore().portalConfig;
    const isUserAuthenticated = useStore().isUserAuthenticated;


    useEffect(() => {

        if (admin_consent === "True")
        {

            setLoaderMessage("You have now connected your Microsoft Entra instance to the Document Drafter System");
        }
        else {
            setLoaderMessage("Please try link again - consent was not given")

        }




    }, []);


    

    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#f3f2f1', // Replace with your theme color
        },
        box: {
            padding: '20px',
            backgroundColor: 'white', // Replace with your theme color
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
            borderRadius: '5px',
            maxWidth: '400px',
            width: '100%',
        },
        header: {
            marginBottom: '20px',
            textAlign: 'center',
        },
        logo: {
            width: '100px',
            height: '100px',
            marginBottom: '20px',
            // Add styles for your logo here
        },
    });

    let logoHeight = "40px";
    if (props.ConfigLogin.config?.loginLogoHeight?.length) {
        logoHeight = props.ConfigLogin.config.loginLogoHeight;
        if (logoHeight.indexOf("px") < 0)
            logoHeight += "px";
    }

    let logoMarginTop = "0px";
    if (props.ConfigLogin.config?.loginLogoTopMargin?.length) {
        logoMarginTop = props.ConfigLogin.config.loginLogoTopMargin;
        if (logoMarginTop.indexOf("px") < 0)
            logoMarginTop += "px";
    }

    function getLogo() {
        if (isDark) {
            if (props.ConfigLogin.config?.loginLogoPathDark?.length)
                return (
                    <img
                        key="logo"
                        style={{ height: logoHeight, marginTop: logoMarginTop }}
                        src={props.ConfigLogin.config?.loginLogoPathDark}
                        alt="slim logo" />
                )

        }
        else {
            if (props.ConfigLogin.config?.loginLogoPath?.length)
                return (
                    <img
                        key="logo"
                        style={{ height: logoHeight, marginTop: logoMarginTop }}
                        src={props.ConfigLogin.config?.loginLogoPath}
                        alt="slim logo"
                    />
                )
        }

        return null
    }


    return (

        <div
            style={{
                height: "100vh",
                width: "100vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: `radial-gradient(circle at 64% 46%,${customTokens.gradientBackground1} 0,${customTokens.gradientBackground2} 57%,${customTokens.gradientBackground3} 100%)`,
            }}
        >
            <Card
                style={{
                    width: 380,
                    minHeight: 263,
                    padding: `${tokens.spacingVerticalXXL} ${tokens.spacingVerticalXXXL} ${tokens.spacingVerticalXXXL}`,
                    backgroundColor: tokens.colorNeutralBackground1,
                }}
            >
                <CardHeader
                    image={getLogo()}
                />

                {loaderMessage && (
                    <div style={{ transform: "translateY(20%)" }}>
                        {/*  <Spinner size={"medium"} />*/}

                        <div
                            style={{
                                ...typographyStyles.subtitle1,
                                textAlign: "center",
                                marginTop: tokens.spacingVerticalS,
                            }}
                        >
                            {loaderMessage}
                        </div>
                    </div>
                )}
                
            </Card>
        </div>

    );
}

