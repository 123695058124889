﻿import { Button,  DrawerBody, DrawerHeader, DrawerHeaderTitle, OverlayDrawer, SelectTabData, SelectTabEvent, Tab, Text, TabList, TabValue, makeStyles, shorthands, tokens, InfoLabel, Persona } from "@fluentui/react-components";
import React from "react";
import { useState, useEffect } from "react";
import { AddRegular, CheckmarkRegular, DeleteRegular, Dismiss24Regular, PersonAccountsRegular, DismissRegular, EditRegular, NavigationRegular, QuestionCircleRegular, RenameRegular, SignOutRegular } from "@fluentui/react-icons";
import { ICacheUser, ICacheUserWithGroups } from "../entitiesAPI";
import { getAuthObject } from "../../apiFunctions/authenticateFunctions";
import { GetSearchedLimitedMembersFromPermission, getSearchedLimitedMembersFromGroupId } from "../../apiFunctions/baseFunctions/userFunctions";
import { IPortalConfig, IPortalUserGroups, PortalGroupSlim } from "../ddentities";
import useStore from "../../apiFunctions/store/store";

interface IaccessControl {
    users: string[],
    groups: string[],
    update: any;
    close: any;
}

export const AccessControl: React.FC<IaccessControl> = (props) => {

    const [selectedValue, setSelectedValue] =
        React.useState<TabValue>("users");
    const [addValue, setAddValue] =
        React.useState<string>("");

    const [isDrawerOpen, setDrawerIsOpen] = React.useState(true);
    const [users, setUsers] = React.useState(props.users);
    const [groups, setGroups] = React.useState(props.groups);
    const [usersInAccessGroup, setUsersInAccessGroup] = React.useState<ICacheUserWithGroups[]>([]);
    const [userToAdd, setUsersToAdd] = React.useState<ICacheUserWithGroups[]>([]);
    const [groupToAdd, setGroupToAdd] = React.useState<PortalGroupSlim[]>([]);


    const [userToRemove, setUsersToRemove] = React.useState<ICacheUserWithGroups[]>([]);


    const [tmpUsers, setTmpUsers] = React.useState<ICacheUserWithGroups[]>([]);
    const [tmpGroups, setTmpGroups] = React.useState<PortalGroupSlim[]>([]);
    const [usersWithObj, setUsersWithObj] = React.useState<ICacheUserWithGroups[]>([]);
    const [groupsWithObj, setGroupsWithObj] = React.useState<PortalGroupSlim[]>([]);
    const [usersToBeRemoved, setUsersToBeRemoved] = React.useState<ICacheUserWithGroups[]>([]);
    const [groupsToBeRemoved, setGroupsToBeRemoved] = React.useState<PortalGroupSlim[]>([]);

    const portalConfig = useStore().portalConfig as IPortalConfig;

    const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
        setSelectedValue(data.value);
        setAddValue("");
    };
    const useStyles = makeStyles({
        root: {
            alignItems: "flex-start",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            ...shorthands.padding("50px", "20px"),
            rowGap: "20px",
        },
        panels: {
            ...shorthands.padding(0, "10px"),
            "& th": {
                textAlign: "left",
                ...shorthands.padding(0, "30px", 0, 0),
            },
        },
        propsTable: {
            "& td:first-child": {
                fontWeight: tokens.fontWeightSemibold,
            },
            "& td": {
                ...shorthands.padding(0, "30px", 0, 0),
            },
        },
    });
    const styles = useStyles();

    const handleRemoveUser = (_user:ICacheUserWithGroups) => {
        setUsersWithObj(usersWithObj.filter((user, i) => user.id !== _user.id));
        setUsersToBeRemoved([...usersToBeRemoved, _user]);
    };

    const restoreRemoveUser = (_user: ICacheUserWithGroups) => {
        setUsersWithObj([...usersWithObj,_user]);
        setUsersToBeRemoved(usersToBeRemoved.filter((user,i)=>user.id !== _user.id));
    };

    const restoreRemoveGroup = (_grp: PortalGroupSlim) => {
        setGroupsWithObj([...groupsWithObj, _grp]);
        setGroupsToBeRemoved(groupsToBeRemoved.filter((grp, i)=> grp.id !== _grp.id));
      
    };


    const handleRemoveGroup = (_grp:PortalGroupSlim) => {
        setGroupsWithObj(groupsWithObj.filter((grp, i) => grp.id !== _grp.id));
        setGroupsToBeRemoved([...groupsToBeRemoved, _grp]);
        
    };
    const addUserGroup = (_user: ICacheUserWithGroups) => {
        setUsersToAdd([...userToAdd, _user]);
        setTmpUsers(tmpUsers.filter(user => user.id !== _user.id));
    };
    const RemoveAddUserGroup = (_user: ICacheUserWithGroups) => {
        setUsersToAdd(userToAdd.filter(user => user.id !== _user.id));
        setTmpUsers([...tmpUsers, _user]);
    };

    const addGroupToAccess = (_grp: PortalGroupSlim) => {
        setGroupToAdd([...groupToAdd, _grp]);
        setTmpGroups(tmpGroups.filter(grp => grp.id !== _grp.id));
    }
    const removeAddGroup = (_grp: PortalGroupSlim) => {
        setGroupToAdd(groupToAdd.filter(user => user.id !== _grp.id));
        setTmpGroups([...tmpGroups, _grp]);
    };




    React.useEffect(()=>{
        props.close;

    }, [isDrawerOpen]);

    React.useEffect(() => { }, [tmpUsers]);
    React.useEffect(() => {
        //console.log('usersWithObj changed:', usersWithObj);
        //console.log('groupsWithObj changed:', groupsWithObj);
    }, [groupsWithObj, usersWithObj]);

    //React.useEffect(() => {
      

    //}, [addValue])

    React.useEffect(() => {
        async function init() {
            var authResult = await getAuthObject();
            var _usersInGroup = await GetSearchedLimitedMembersFromPermission(authResult, "xxxxxxx-xxx-data-1111-2222222", 100);
            setUsersInAccessGroup(_usersInGroup);

            var _tmpArray: ICacheUserWithGroups[] = [];
            var _tmpUsers: ICacheUserWithGroups[] = JSON.parse(JSON.stringify(_usersInGroup));

            for (var i = 0; i < users.length; i++) {

                var _user = _usersInGroup.find(x => x.id === users[i]);
                if (_user !== null && _user !== undefined) {
                    _tmpArray.push(_user);
                    _tmpUsers = _tmpUsers.filter(user => user.id !== _user.id);
                }
                else
                {
                    _tmpArray.push({ email:"n/a", id:users[i], name:"n/a", groups:[] });
                }   
            }
            setTmpUsers(_tmpUsers);
            setUsersWithObj(_tmpArray);

            var _tmpGrpArray: PortalGroupSlim[] = [];
            var _tmpGroups: PortalGroupSlim[] = [];

            for (var i = 0; i < portalConfig.Groups.length; i++)
            {
                _tmpGroups.push({ id: portalConfig.Groups[i].id, name: portalConfig.Groups[i].name });
            }
            for (var i = 0; i < groups.length; i++) {

                var _grp = portalConfig.Groups.find(x => x.id == groups[i]);
                if (_grp != null && _grp !== undefined) {
                    _tmpGrpArray.push({ id: _grp.id, name: _grp.name });
                    _tmpGroups = _tmpGroups.filter(grp => grp.id !== _grp.id);
                }
                else {
                    _tmpGrpArray.push({ id: groups[i], name: "n/a" });
                }
                
            }
            setTmpGroups(_tmpGroups);
            setGroupsWithObj(_tmpGrpArray);

        }
        init();
    },[])

    function tablistDisabled() {
        if (userToAdd.length > 0 || groupToAdd.length > 0) return true;
        return false;
    }

    function GetPersona(id: string) {

        var user = usersInAccessGroup.find(x => x.id === id);


        if (user)
            return (
                <Persona
                    name={user.name !== null ? user.name : user.email}
                    secondaryText={user.name !== null?user.email:"" }
                />
        
            )
        else 
            return (
                <Persona
                    name={"n/a"}
                    secondaryText={""}
                />

            )
    }

    function CancelAll() {
        setUsersToAdd([]);  
        setGroupToAdd([]);
        setUsersToBeRemoved([]);
        setGroupsToBeRemoved([]);
        setAddValue("");
    }

    function GetGroup(id: string) {

        var grp = portalConfig.Groups.find(x => x.id === id);

        if (grp)
            return (
                <Persona
                    name={grp.name}
                    secondaryText={grp.system?"System group":"Standard group"}
                />

            )
        else 
            return (
                <Persona
                    name={"Grp deleted"}
                    secondaryText={"Standard group"}
                />

            )
    }

  
        return (
            <>
                <OverlayDrawer
                    open={isDrawerOpen}
                 
                    size={"medium"}
                    position={"end"}

                >
                    <DrawerHeader>
                        <DrawerHeaderTitle
                            action={
                                <Button
                                    appearance="subtle"
                                    aria-label="Close"
                                    icon={<Dismiss24Regular />}
                                    onClick={() => { props.close() }}
                                />
                            }
                        >
                            {addValue === "" ? "Access" : "Add users/groups"}
                        </DrawerHeaderTitle>
                    </DrawerHeader>

                    <DrawerBody>
                        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>

                            <TabList defaultSelectedValue={selectedValue} onTabSelect={onTabSelect} disabled={tablistDisabled()} >
                                <Tab value="users" id="users" >Users {usersWithObj.length > 0 ? "(" + usersWithObj.length + ")" : ""}</Tab>
                                <Tab value="groups" id="groups">Groups {groupsWithObj.length > 0 ? "(" + groupsWithObj.length + ")" : ""}</Tab>

                            </TabList>
                            <div className={styles.panels}>
                                {selectedValue === "users" && addValue=== "" &&
                                    <div>
                                        <div style={{width:"100%",  overflow:"hidden"} }>
                                            <div style={{ marginTop: "20px", height: '46vh', overflowY: 'auto', scrollbarGutter: "stable" }}>
                                            {usersWithObj.length > 0 && usersWithObj.sort((a, b) => {
                                                const nameA = a.name !== null ? a.name : a.email;
                                                const nameB = b.name !== null ? b.name : b.email;
                                                return nameA.localeCompare(nameB);
                                            }).map((user, index) => (
                                                <div key={index} style={{ display: 'flex', width: '100%', marginBottom: '5px', marginTop: '5px' }}>
                                                <div style={{ flex: '1 1 80%' }}>
                                                   {GetPersona(user.id)}
                                                </div>
                                                <div style={{ flex: '1 1 20%', justifyContent: 'flex-end', display: 'flex' }}>
                                                        <Button appearance={ "subtle"} icon={<DismissRegular></DismissRegular>} onClick={() => handleRemoveUser(user)} />
                                                </div>
                                            </div>
                                        ))}
                                        {users.length === 0  && groups.length === 0 && <div style={{ display: 'flex', width: '100%', marginBottom: '30px', marginTop:'20px' }}>
                                        <div style={{ flex: '1 1 80%' }}>
                                                <InfoLabel info={<>Access has not been restricted to any groups or users. As a consequence any user with access to the data manager can see this lookup table.</> } ><Text>Everyone</Text></InfoLabel>
                                        </div>
                                        <div style={{ flex: '1 1 20%', justifyContent: 'flex-end', display: 'flex' }}>
                                            {/*<Button icon={<DismissRegular></DismissRegular>} onClick={() => handleRemoveUser(index)} />*/}
                                        </div>
                                            </div>
                                            }
                                        {users.length === 0 && groups.length > 0 && <div style={{ display: 'flex', width: '100%', marginBottom: '30px', marginTop: '20px' }}>
                                            <div style={{ flex: '1 1 80%' }}>
                                                <InfoLabel info={<>Access has not been restricted to any users, but access restrictions exists on groups.</>} ><Text>No user restrictions, but groups restrictions exist</Text></InfoLabel>
                                            </div>
                                            <div style={{ flex: '1 1 20%', justifyContent: 'flex-end', display: 'flex' }}>
                                                {/*<Button icon={<DismissRegular></DismissRegular>} onClick={() => handleRemoveUser(index)} />*/}
                                            </div>
                                        </div>
                                        }
                                           



                                            </div>
                                        </div>
                                        {usersToBeRemoved.length > 0 &&


                                            <div style={{ width: "100%", overflow: "hidden" }}>
                                                <h4> Users which will be removed</h4>
                                                <div style={{ marginTop: "20px", height: '28vh', overflowY: 'auto', scrollbarGutter: "stable" }}>
                                            {usersToBeRemoved.length > 0 && usersToBeRemoved.sort((a, b) => {
                                                const nameA = a.name !== null ? a.name : a.email;
                                                const nameB = b.name !== null ? b.name : b.email;
                                                return nameA.localeCompare(nameB);
                                            }).map((user, index) => (
                                                <div key={index} style={{ display: 'flex', width: '100%', marginBottom: '5px', marginTop: '5px' }}>
                                                    <div style={{ flex: '1 1 80%' }}>
                                                        {GetPersona(user.id)}

                                                    </div>
                                                    <div style={{ flex: '1 1 20%', justifyContent: 'flex-end', display: 'flex' }}>
                                                        <Button appearance={"subtle"} icon={<DismissRegular></DismissRegular>} onClick={() => restoreRemoveUser(user)} />
                                                    </div>
                                                </div>
                                            ))}
                                            </div>
                                        </div>}

                                        

                                    </div>
                                    }

                                {selectedValue === "groups" && addValue === "" && <div>
                                    <div style={{ width: "100%", overflow: "hidden" }}>
                                        <div style={{ marginTop: "20px", height: '48vh', overflowY: 'auto', scrollbarGutter: "stable" }}>
                                            {groups.length > 0 && groupsWithObj.sort((a, b) => {
                                                const nameA = a.name !== null ? a.name : a.name;
                                                const nameB = b.name !== null ? b.name : b.name;
                                                return nameA.localeCompare(nameB);
                                            }).map((grp, index) => (
                                                <div key={index} style={{ display: 'flex', width: '100%', marginBottom: '5px', marginTop: '5px' }}>
                                                    <div style={{ flex: '1 1 80%' }}>
                                                        {GetGroup(grp.id)}
                                                    </div>
                                                    <div style={{ flex: '1 1 20%', justifyContent: 'flex-end', display: 'flex' }}>
                                                        <Button appearance={"subtle"}  icon={<DismissRegular></DismissRegular>} onClick={() => handleRemoveGroup(grp)} />
                                                    </div>
                                                </div>
                                   
                                            ))
                                    
                                            }
                                            {users.length === 0 && groups.length === 0 && <div style={{ display: 'flex', width: '100%', marginBottom: '30px', marginTop: '20px' }}>
                                                <div style={{ flex: '1 1 80%' }}>
                                                    <InfoLabel info={<>Access has not been restricted to any groups or users. As a consequence any user with access to the data manager can see this lookup table.</>} ><Text>Everyone</Text></InfoLabel>
                                                </div>
                                                <div style={{ flex: '1 1 20%', justifyContent: 'flex-end', display: 'flex' }}>
                                                    {/*<Button icon={<DismissRegular></DismissRegular>} onClick={() => handleRemoveUser(index)} />*/}
                                                </div>
                                            </div>}

                                            {groupsWithObj.length === 0 && users.length > 0 && <div style={{ display: 'flex', width: '100%', marginBottom: '30px', marginTop: '20px' }}>
                                                <div style={{ flex: '1 1 80%' }}>
                                                    <InfoLabel info={<>Access has not been restricted to any groups, but access restrictions exists on users.</>} ><Text>No group restrictions, but user restrictions exist</Text></InfoLabel>
                                                </div>
                                                <div style={{ flex: '1 1 20%', justifyContent: 'flex-end', display: 'flex' }}>
                                                    {/*<Button icon={<DismissRegular></DismissRegular>} onClick={() => handleRemoveUser(index)} />*/}
                                                </div>
                                            </div>
                                            }


                                        </div>
                                    </div>

                                   
                                     {groupsToBeRemoved.length > 0 &&


                                            <div style={{ width: "100%", overflow: "hidden" }}>
                                                <h4> Groups which will be removed</h4>
                                            <div style={{ marginTop: "20px", height: '28vh', overflowY: 'auto', scrollbarGutter: "stable" }}>
                                            {groupsToBeRemoved.length > 0 && groupsToBeRemoved.sort((a, b) => {
                                                const nameA = a.name !== null ? a.name : a.name;
                                                const nameB = b.name !== null ? b.name : b.name;
                                                return nameA.localeCompare(nameB);
                                            }).map((grp, index) => (
                                                <div key={index} style={{ display: 'flex', width: '100%', marginBottom: '5px', marginTop: '5px' }}>
                                                    <div style={{ flex: '1 1 80%' }}>
                                                        {GetGroup(grp.id)}

                                                    </div>
                                                    <div style={{ flex: '1 1 20%', justifyContent: 'flex-end', display: 'flex' }}>
                                                        <Button appearance={"subtle"} icon={<DismissRegular></DismissRegular>} onClick={() => restoreRemoveGroup(grp)} />
                                                    </div>
                                                </div>
                                            ))}
                                            </div>
                                        </div>}


                                 



                                </div>}






                                {addValue === "users" &&
                                    <div>
                                    <div style={{ width: "100%", overflow: "hidden" }}>
                                        <div style={{ marginTop: "20px", height: '48vh', scrollbarGutter:"stable",  overflow: 'auto' }}>
                                            {tmpUsers.sort((a, b) => {
                                                const nameA = a.name !== null ? a.name : a.email;
                                                const nameB = b.name !== null ? b.name : b.email;
                                                return nameA.localeCompare(nameB);
                                            }).map((user, index) => (
                                                <div key={index} style={{ display: 'flex', width: '100%', marginBottom: '5px', marginTop: '5px' }}>
                                                    <div style={{ flex: '1 1 80%' }}>
                                                        {GetPersona(user.id)}
                                                    </div>
                                                    <div style={{ flex: '1 1 20%', justifyContent: 'flex-end', display: 'flex' }}>
                                                        <Button appearance={"subtle"} icon={<AddRegular></AddRegular>} onClick={() => addUserGroup(user)} />
                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                        </div>
                                        <div style={{ width: "100%", overflow: "hidden" }}>
                                            <div style={{ marginTop: "20px", height: '30vh', scrollbarGutter: "stable", overflow: 'auto' }}><h4> Users which will be added</h4>
                                            {userToAdd.length > 0 && userToAdd.sort((a, b) => {
                                                const nameA = a.name !== null ? a.name : a.email;
                                                const nameB = b.name !== null ? b.name : b.email;
                                                return nameA.localeCompare(nameB);
                                            }) .map((user, index) => (
                                                <div key={index} style={{ display: 'flex', width: '100%', marginBottom: '5px', marginTop: '5px' }}>
                                                    <div style={{ flex: '1 1 80%' }}>
                                                        {GetPersona(user.id)}

                                                    </div>
                                                    <div style={{ flex: '1 1 20%', justifyContent: 'flex-end', display: 'flex' }}>
                                                        <Button appearance={"subtle"} icon={<DismissRegular></DismissRegular>} onClick={() => RemoveAddUserGroup(user)} />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        </div>
                                    </div>


                                }

                                {addValue === "groups" &&
                                    <div>
                                        <div style={{ marginTop: "20px", height: '48vh', scrollbarGutter: "stable", overflow: 'auto' }}>
                                            {tmpGroups.sort((a, b) => {
                                                const nameA = a.name !== null ? a.name : a.name;
                                                const nameB = b.name !== null ? b.name : b.name;
                                                return nameA.localeCompare(nameB);
                                            }).map((grp, index) => (
                                                <div key={index} style={{ display: 'flex', width: '100%', marginBottom: '5px', marginTop: '5px' }}>
                                                    <div style={{ flex: '1 1 80%' }}>
                                                        {GetGroup(grp.id)}
                                                    </div>
                                                    <div style={{ flex: '1 1 20%', justifyContent: 'flex-end', display: 'flex' }}>
                                                        <Button appearance={"subtle"} icon={<AddRegular></AddRegular>} onClick={() => addGroupToAccess(grp)} />
                                                    </div>
                                                </div>
                                            ))}

                                        </div>

                                        <div style={{ marginTop: "20px", height: '30vh', scrollbarGutter: "stable", overflow: 'auto' }}><h4> Groups which will be added</h4>
                                            {groupToAdd.length > 0 && groupToAdd.sort((a, b) => {
                                                const nameA = a.name !== null ? a.name : a.name;
                                                const nameB = b.name !== null ? b.name : b.name;
                                                return nameA.localeCompare(nameB);
                                            }).map((grp, index) => (
                                                <div key={index} style={{ display: 'flex', width: '100%', marginBottom: '5px', marginTop: '5px' }}>
                                                    <div style={{ flex: '1 1 80%' }}>
                                                        {GetGroup(grp.id)}

                                                    </div>
                                                    <div style={{ flex: '1 1 20%', justifyContent: 'flex-end', display: 'flex' }}>
                                                        <Button appearance={"subtle"} icon={<DismissRegular></DismissRegular>} onClick={() => removeAddGroup(grp)} />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                    </div>



                                }



                            </div>



                            {addValue === "" && usersToBeRemoved.length===0 && groupsToBeRemoved.length===0 && <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '10px', position: "fixed", bottom: "0", left: "0", width: "95%" }}>
                                <Button icon={<AddRegular></AddRegular>} onClick={() => {
                                    /*setTmpUsers(JSON.parse(JSON.stringify(usersInAccessGroup)));*/

                                    setAddValue(selectedValue as string);
                                }} >Add access</Button>
                            </div>
                            }
                            {addValue !== "" && userToAdd.length === 0 && groupToAdd.length===0 && <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '10px', position: "fixed", bottom: "0", left: "0", width: "95%" }}>
                                <Button icon={<DismissRegular></DismissRegular>} onClick={() => CancelAll()} >Cancel</Button>
                            </div>    
                            }
                            {/* Footer with buttons */}
                            {(userToAdd.length > 0 || usersToBeRemoved.length>0 || groupToAdd.length>0 || groupsToBeRemoved.length>0) && <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '10px', position:"fixed", bottom:"0", left:"0", width:"95%" }}>
                                <Button icon={<><CheckmarkRegular /></>}  onClick={() => props.update(userToAdd, usersToBeRemoved,groupToAdd, groupsToBeRemoved)} appearance="primary">Save</Button>
                                <Button icon={<DismissRegular></DismissRegular>} onClick={() => CancelAll()} style={{ marginLeft: '10px' }}>Cancel</Button>
                            </div>}
                        </div>
                      


                    </DrawerBody>
                </OverlayDrawer>
            </>
        )
    

   
}

