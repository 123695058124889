﻿import { FC, useEffect } from "react";
import useStore from "../apiFunctions/store/store";
import { ScreenSize } from "documentdrafter-components";

let screenSizeOutOfState: number = ScreenSize.fullScreen;

interface IResizer {
    children: JSX.Element;
}
export const Resizer: FC<IResizer> = (props) => {
    function onResize() {
        let newScreenSize: number = ScreenSize.XXL;
        if (window.innerWidth < 1600) newScreenSize = ScreenSize.fullScreen;
        if (window.innerWidth < 1285) newScreenSize = ScreenSize.smallScreen;
        if (window.innerWidth < 992) newScreenSize = ScreenSize.mobile;

        if (newScreenSize !== screenSizeOutOfState) {
            useStore.setState({ screenSize: newScreenSize });
            screenSizeOutOfState = newScreenSize;
        }
    }
    useEffect(() => {
        onResize();
        window.onresize = onResize;
    }, []);

    return props.children;
};
export default Resizer;
