﻿import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    Theme,
    teamsLightTheme,
    teamsDarkTheme,
    teamsHighContrastTheme,
    webLightTheme,
    webDarkTheme,
    themeToTokensObject,
    Button,
    tokens,
    Menu,
    
    MenuTrigger,
    MenuButton,
    MenuPopover,
    MenuList,
    makeStyles,
    TabList,
    Tab,
    shorthands,
    Table,
    TableHeader,
    TableRow,
    TableHeaderCell,
    TableCell,
    TableBody,
    TableCellLayout,
    TableSelectionCell,
    useTableFeatures,
    useTableSelection,
    TableColumnDefinition,
    createTableColumn,
    TableCellActions,
    TableCellLayoutProps,
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogBody,
    DialogTitle,
    DialogContent,
    DialogActions,
    useId,
    Label,
    Input,
    typographyStyles,
    ToolbarDivider,
    Breadcrumb,
    isTruncatableBreadcrumbContent,
    truncateBreadcrumbLongName,
    BreadcrumbItem,
    Tooltip,
    BreadcrumbButton,
    BreadcrumbDivider,
    useOverflowMenu,
    PartitionBreadcrumbItems,
    partitionBreadcrumbItems,
    Overflow,
    OverflowItem,
    OverflowItemProps,
    useIsOverflowItemVisible,
    MenuItemLink,
    MessageBar,
    MessageBarBody,
    MessageBarTitle,
    MessageBarActions,
    Toaster,
    Toast,
    ToastTitle,
} from "@fluentui/react-components";

import { Dropdown, Option, Toolbar, ToolbarButton } from "@fluentui/react-components";
import useStore from "../../apiFunctions/store/store";
import usePersistentStore from "../../apiFunctions/store/persistentStore";
import { Header, CustomMenuButton } from "documentdrafter-components";
import { ISlimWorkspace, ScreenSize, CustomFluentProvider } from "documentdrafter-components";
import { getCustomTheme } from "documentdrafter-components";
import { getEndpoints, setEndpoints, MainPageSignalRWrapper, ISignalRUpdate, SignalRUpdateType, ISlimCachedDirectory } from "documentdrafter-components";
import "@fluentui/react/dist/css/fabric.min.css";
import React from "react";
import { IUserProps } from "../entitiesAPI";
import { AddRegular, DeleteRegular, FolderRegular, ArrowDownloadRegular, ArrowDownloadFilled, DocumentRegular, Dismiss24Regular, DismissRegular, EditRegular, NavigationRegular, QuestionCircleRegular, RenameRegular, SignOutRegular, bundleIcon, EditFilled, DeleteFilled, CheckRegular, PageFit20Regular, Document20Regular, MoreHorizontalFilled, MoreHorizontalRegular } from "@fluentui/react-icons";
import { EntryType } from "../filemanager/filemanager";
import { getAuthObject } from "../../apiFunctions/authenticateFunctions";
import { CreateFolderLinkingBit, DeleteFolderLinkingBit, DeleteLinkingBit, GetFolderLinkingBit, RenameLinkingBit } from "../../apiFunctions/baseFunctions/baseFunctions";
import { IEntryType } from "../ddentities";
import { FileIconType, getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { Icon } from '@fluentui/react/lib/Icon';
import { Console } from "console";
import { flex } from "@griffel/core/src/shorthands";
import { IsLinkingBitConnected } from "../../apiFunctions/questionnairefunctions";

type FolderNavProps = {
    action : string
}

export const LinkingBitFolderNav: React.FC<FolderNavProps> = (props) => {

    const userObj: IUserProps = useStore().userObj;
    const isUserAuthenticated = useStore().isUserAuthenticated;
    //SignalR
    const [documentsSignalRUpdateQueue, setDocumentsSignalRUpdateQueue] = useState<ISignalRUpdate[]>([]);
    const screenSize = useStore().screenSize;
    const portalConfig = useStore().portalConfig;

    const endPoints = useStore().endpoints;
    const isDark = usePersistentStore().darkMode;
    const theme = isDark ? webDarkTheme : webLightTheme;
    const navigate = useNavigate();
    const customTokens = themeToTokensObject(getCustomTheme(!isDark));
    const [items, setItems] = React.useState<item[]>([]);
    const [files, setFiles] = React.useState<item[]>([]);

    const [currentFolderId, setCurrentFolderId] = React.useState<string>("");
    const [openRename, setOpenRename] = React.useState<boolean>(false);
    const [openDelete, setOpenDelete] = React.useState<boolean>(false);
    const [openDownload, setOpenDownload] = React.useState<boolean>(false);
    const inputId = useId("input");
    const [editItem, setEditItem] = React.useState<item>(null);
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    const [forceUpdate, setForceUpdate] = React.useState<number>(0);
    const [folderPath, setFolderPath] = useState<item[]>([]);
    const [newFileName, setNewFileName] = useState<string>("");
    const [openNewFileName, setOpenNewFileName] = useState<boolean>(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = React.useState<string>("");
    

    var previousFolderid = "";

    type item = {
        file: nameCell;
        entryType: IEntryType
    };
    type nameCell = {
        name: string;
        id: string;
        parentId: string;
        path: string;
        xmlPath: string;
    };

    const columns: TableColumnDefinition<item>[] =
        [
            createTableColumn<item>({ 
                columnId: "file"
            })
        ];

    useEffect(() => {

        console.log('isUserAuthenticated:' + isUserAuthenticated)

        setEndpoints(endPoints);
        if (!isUserAuthenticated) {
            const currentpath = window.location.pathname + window.location.search;

            if (!userObj) navigate(`/user?nextUrl=${currentpath}`, { replace: true });
            else {
                navigate(`/user?nextUrl=${currentpath}`, { replace: true });
            }
        } else {

            console.log('we are good')

        }
        var f: item = {
            file: {
                name: "Linking Bits", id: "linkbitWorkspace", parentId: "", path: "", xmlPath:""
            },
            entryType: IEntryType.Workspace
        }
        var ff: item[] = [];
        ff.push(f);
        setFolderPath(ff);


        const params = new URLSearchParams(window.location.search);
        
        if (params.get("currentFileName") != null) {
            setNewFileName(params.get("currentFileName"));
        }
        

    }, []);


    async function refreshDataSets() {

        var authObject = await getAuthObject();
        var prevParentId = "";
        if (folderPath.length > 0) {
       
            prevParentId = folderPath[folderPath.length - 1].file.parentId;
        }


        var folders = await GetFolderLinkingBit(authObject, currentFolderId,prevParentId);

        var tmpItems: item[] = [];
        var parentId: string = "";

        var tmpFiles: item[] = [];
        



        for (var i = 0; i < folders.length; i++) {

                

            var f: item = {
                file: {
                    name: folders[i].name, id: folders[i].id, parentId:folders[i].parentId, path:folders[i].templateFile, xmlPath:folders[i].xmlPath
                },
                entryType: folders[i].entryType
            }


            if (props.action === "selectFolder" && folders[i].entryType === IEntryType.LinkingBitTemplate) {
                tmpFiles.push(f);
                continue;
            }

            tmpItems.push(f);

        }

     
        setFiles(tmpFiles);
        setItems(tmpItems);
        console.log(tmpItems);


    }

    React.useEffect(() => {
              
        refreshDataSets();

       



    }, [currentFolderId, forceUpdate])


        const {
            getRows,
            selection: {
                allRowsSelected,
                someRowsSelected,
                toggleAllRows,
                toggleRow,
                isRowSelected,
            },
        } = useTableFeatures(
            {
                columns,
                items,
            },
            [
                useTableSelection({
                    selectionMode: "multiselect",
                    defaultSelectedItems: new Set([0, 1]),
                }),
            ]
        );


        const rows = getRows((row) => {
            const selected = isRowSelected(row.rowId);
            return {
                ...row,
                onClick: (e: React.MouseEvent) =>
                    !e.defaultPrevented && toggleRow(e, row.rowId),
                onKeyDown: (e: React.KeyboardEvent) => {
                    if (e.key === " " && !e.defaultPrevented) {
                        e.preventDefault();
                        toggleRow(e, row.rowId);
                    }
                },
                selected,
                appearance: selected ? ("brand" as const) : ("none" as const),
            };
        });


        const toggleAllKeydown = React.useCallback(
            (e: React.KeyboardEvent<HTMLDivElement>) => {
                if (e.key === " ") {
                    toggleAllRows(e);
                    e.preventDefault();
                }
            },
            [toggleAllRows]
        );


        const onClickCellActions = (e: React.MouseEvent<HTMLDivElement>) =>
            e.preventDefault();
        const onKeyDownCellActions = (e: React.KeyboardEvent<HTMLDivElement>) =>
            e.key === " " && e.preventDefault();

    const EditIcon = bundleIcon(EditFilled, EditRegular);
    const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);
    const DownloadIcon = bundleIcon(ArrowDownloadFilled,ArrowDownloadRegular);



    function changeDir(item: item) {
        
        if (item.file.name === "..." || folderPath.findIndex(x => x.file.id == item.file.id)>-1) {


            var pos = folderPath.findIndex(x => x.file.id == item.file.id);
            setFolderPath(folderPath.slice(0, pos + 1));

           /* setFolderPath(folderPath.slice(0, -1));*/
        }
            
        else 
            setFolderPath([...folderPath, item]);

        
        setCurrentFolderId(item.file.id);
        
    }

    const useStyles = makeStyles({
        root: {
            // Stack the label above the field
            display: "flex",
            flexDirection: "column",
            // Use 2px gap below the label (per the design system)
            ...shorthands.gap("2px"),
            // Prevent the example from taking the full width of the page (optional)
            maxWidth: "400px",
        },
        grid: {

            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: '16px',
            paddingRight: '16px',



        },
        headerContainer:
        {
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: tokens.spacingHorizontalM,
            marginRight: tokens.spacingHorizontalM,
            /*width:"100dvw"*/
            
        },
        headerContainerTop:
        {
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: tokens.spacingHorizontalM,
            marginRight: tokens.spacingHorizontalM,
         

        },
        headerColumn1: {
            marginRight: 'auto'
        },
        overflowMenuItem: {
            "& .fui-BreadcrumbItem": {
                "& .fui-Button": { width: "100%", textAlign: "left" },
                "& .truncateText": {
                    fontSize: typographyStyles.body1.fontSize,
                    fontWeight: typographyStyles.body1.fontWeight,
                    fontFamily: typographyStyles.body1.fontFamily,
                    lineHeight: typographyStyles.body1.lineHeight,
                    width: "100%",
                },
            },
        },
        resizableArea: {
            width: "calc(100% - 10px)",
            display: "flex",
            flexWrap: "nowrap",
        },





    });

    const styles = useStyles();


    async function onClickOpenDelete(item: item) : Promise<boolean> {
        var authObject = await getAuthObject();

        if (item.entryType === IEntryType.Folder) {
            var deleted = await DeleteFolderLinkingBit(authObject, item.file.id);

            return deleted;

        }
        else {

          
            var deleted = await DeleteLinkingBit(authObject, item.file.id);

            return deleted;
        }
        

    }
  

    async function onClickOpenRename(item:item) {
         
        if (item.file.id === undefined) {
            //create
            var authObject = await getAuthObject();
            var updatedItem = await CreateFolderLinkingBit(authObject, currentFolderId, item.file.name);


            //// After the line: var updatedItem = await CreateFolderLinkingBit(authObject, currentFolderId, item.file.name);

            //// Add the updatedItem to the items array
            //const updatedItems = [...items, {
            //  file: {
            //        name: updatedItem.name,
            //        path: updatedItem.templateFile,
            //        id: updatedItem.id,
            //        parentId: updatedItem.parentId,
            //        xmlPath: updatedItem.xmlPath
               
                    
                    
            //  },
            //  entryType: updatedItem.entryType
            //}];

            // Update the state with the updated items array
            setForceUpdate(prevCount => prevCount + 1);
            setOpenRename(false);
        

        }
        else {
            //
            var authObject = await getAuthObject();
            var updatedItem = await RenameLinkingBit(authObject, item.file.id, item.file.name);
        
            setForceUpdate(prevCount => prevCount + 1);
            setOpenRename(false);
        }
    }

    function createWebViewData(action, folderId, fileId, fileName,xmlFileName,linkBitname) {
        return {
            action: action,
            userId: userObj.userid,
            customerID: userObj.customerid,
            requestToken: userObj.requestToken,
            folderId: folderId,
            fileId: fileId,
            fileName: fileName,
            xmlFileName: xmlFileName,
            linkBitName: linkBitname
        };
    }




    const DeleteDialog = () => {

        if (!openDelete) {

            return <div></div>
        }

        return (
            <Dialog
                open={openDelete}

            >

                <DialogSurface
                    style={{ width: '70%', height: '200px', paddingTop: tokens.spacingVerticalL }}

                >
                    <DialogBody
                        style={{ rowGap: 0 }}


                    >
                        {editItem.entryType === IEntryType.Folder && <DialogTitle style={{ paddingBottom: tokens.spacingVerticalXL }}>Delete folder</DialogTitle>}
                        {editItem.entryType === IEntryType.LinkingBitTemplate && <DialogTitle style={{ paddingBottom: tokens.spacingVerticalXL }}>Delete linking bit</DialogTitle>}
                        {editItem.entryType === IEntryType.Folder && <DialogContent>

                            <div className={styles.root}>
                                <div>
                                The folder cannot be deleted if it contains linking bits which are in use.<br></br>
                                Are you sure you want to delete the selected folder?
                                 </div>
                                {errorMessage && <div
                                    style={{
                                        ...typographyStyles.caption2,
                                        color: tokens.colorPaletteRedForeground1,

                                        marginTop: tokens.spacingVerticalM,
                                        marginBottom: tokens.spacingVerticalM,
                                        lineHeight: "16px"
                                    }}

                                >  {errorMessage}
                                </div>
                                }
                            </div>
                        </DialogContent>
                        }

                        {editItem.entryType === IEntryType.LinkingBitTemplate && <DialogContent>

                            <div className={styles.root}>
                                <span>
                                    The linking bit cannot be deleted if it is in use.<br></br>

                                    Are you sure you want to delete the linking bit named <b>{editItem.file.name}</b>


                                          </span>
                                {errorMessage && <div
                                    style={{
                                        ...typographyStyles.caption2,
                                        color: tokens.colorPaletteRedForeground1,

                                        marginTop: tokens.spacingVerticalM,
                                        marginBottom: tokens.spacingVerticalM,
                                        lineHeight: "16px"
                                    }}

                                >  {errorMessage}
                                </div>
                                }
                            </div>
                        </DialogContent> 
                        }
                        <DialogActions style={{ marginTop: tokens.spacingVerticalL }}>
                            <DialogTrigger disableButtonEnhancement>
                                <Button appearance="secondary" onClick={() => {

                                    setErrorMessage("");
                                    setOpenDelete(false)
                                }
                            }>Cancel</Button>
                            </DialogTrigger>
                            <Button appearance="primary"
                                autoFocus
                               
                                onClick={async () => {
                                    var deleted = await onClickOpenDelete(editItem);
                                    if (deleted) {
                                        //refresh full folder
                                        setForceUpdate(prevCount => prevCount + 1);
                                        setOpenDelete(false);
                                    }
                                    else {
                                        setErrorMessage("The folder could not be deleted. It contains either linking bits which are in use or subfolders.")
                                    }

                                }

                                }>Delete</Button>


                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>


        )


    }


    const AddRenameDialog = () => {


        if (!openRename) {

            return <div></div>
        }

        return (
            <Dialog
                open={openRename}

            >
                
                <DialogSurface
                    style={{ width: '70%', height: '200px', paddingTop: tokens.spacingVerticalL }}
                >
                    <DialogBody

                        style={{ rowGap: 0 }}
                    >
                        {editItem.entryType === IEntryType.Folder &&

                            <DialogTitle style={{ paddingBottom: tokens.spacingVerticalXL }}>{editItem.file.id === undefined ? "Add folder" : "Rename folder"}</DialogTitle>
                        }
                        {editItem.entryType === IEntryType.LinkingBitTemplate &&

                            <DialogTitle style={{ paddingBottom: tokens.spacingVerticalXL }}>Rename linking bit </DialogTitle>
                        }


                        <DialogContent>
                            <div className={styles.root}>
                                
                                <Input id={inputId} defaultValue={editItem.file.name} placeholder="Insert name" onChange={(e, v) => { editItem.file.name = e.currentTarget.value }} />
                            </div>
                        </DialogContent>
                        <DialogActions style={{ marginTop: tokens.spacingVerticalL }}>
                            <DialogTrigger disableButtonEnhancement>
                                <Button appearance="secondary" onClick={()=>setOpenRename(false) }>Cancel</Button>
                            </DialogTrigger>
                            <Button appearance="primary" onClick={() => onClickOpenRename(editItem)} >Ok</Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>


        )


    }

    const NewFileNameDialog = () => {


      

        return (
            <Dialog
                open={openNewFileName}

            >

                <DialogSurface
                    style={{ width: '70%', height: '200px', paddingTop: tokens.spacingVerticalL }}
                >
                    <DialogBody

                        style={{ rowGap: 0 }}
                    >
                        
                        <DialogTitle style={{ paddingBottom: tokens.spacingVerticalXL }}>Linking bit is already existing</DialogTitle>
                        

                        <DialogContent>
                            
                            
                            <div className={styles.root}>
                                A linking bit already exist with that name. Rename the linking bit before uploading

                                <Input id={inputId} defaultValue={newFileName} placeholder="Insert name" onChange={(e, v) => { setNewFileName(e.currentTarget.value) }} />
                            </div>
                        </DialogContent>
                        <DialogActions style={{ marginTop: tokens.spacingVerticalL }}>
                            <DialogTrigger disableButtonEnhancement>
                                <Button appearance="secondary" onClick={() => setOpenNewFileName(false)}>Cancel</Button>
                            </DialogTrigger>
                            <Button appearance="primary" disabled={files.findIndex(x => x.file.name == newFileName) > -1} onClick={() => {

                                var data = createWebViewData("FolderSelect", currentFolderId, "", "", "", newFileName);
                                (window as any).chrome.webview.postMessage(JSON.stringify(data));

                            }} >Ok</Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>


        )


    }

    const DownloadDialog = () => {


        if (!openDownload) {

            return <div></div>
        }

        return (
            <Dialog
                open={openDownload}

            >

                <DialogSurface
                    style={{ width: '70%', height: '200px', paddingTop: tokens.spacingVerticalL }}

                >
                    <DialogBody
                        style={{ rowGap: 0 }}
                    >
                        <DialogTitle style={{ paddingBottom: tokens.spacingVerticalXL }}>Download file to Word</DialogTitle>
                       

                        <DialogContent>
                            <div className={styles.root}>
                                <span>
                                    Please click ok to download the selected linking bit for editing.
                                </span>

                            </div>
                        </DialogContent>
                        <DialogActions
                            style={{ marginTop: tokens.spacingVerticalL }}
                            >
                            <DialogTrigger disableButtonEnhancement>
                                <Button appearance="secondary" onClick={() => setOpenDownload(false)}>Cancel</Button>
                            </DialogTrigger>
                            <Button appearance="primary" onClick={() => {

                                var data = createWebViewData("SelectFile", "", editItem.file.id, editItem.file.path, editItem.file.xmlPath, editItem.file.name );
                                (window as any).chrome.webview.postMessage(JSON.stringify(data));
                            } } >Ok</Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>


        )


    }
  

    const createTableCell  = (item:item)  => {
      
            if (item.entryType === IEntryType.Folder) {
                return (
                    <div>
                        <TableCellLayout
                            onClick={() => {


                                changeDir(item)
                            }}

    
                        >
                       
                        {item.file.name}
                    </TableCellLayout >
                        {item.file.id !== "" && props.action!="selectFile"  &&  <TableCellActions
                            onClick={onClickCellActions}
                            onKeyDown={onKeyDownCellActions}

                        >
                            <Tooltip content="Edit" relationship="label">
                            <Button
                                icon={<EditIcon />}
                                appearance="subtle"
                                aria-label="Edit"
                                onClick={() => {
                                    
                                    setEditItem(item);
                                    setOpenRename(true);


                                } }
                                />
                            </Tooltip>
                            <Tooltip content="Delete" relationship="label">
                            <Button
                                icon={<DeleteIcon />}
                                appearance="subtle"
                                aria-label="Delete"
                                onClick={() => {

                                    setEditItem(item);


                                    setOpenDelete(true);


                                }}
                                />
                            </Tooltip>
                        </TableCellActions>}
                        </div>
                )
            }
            else {
                return (
                    <div>
                    <TableCellLayout
                       
                            onClick={() => {
                                if (props.action === "selectFile") {


                                    var data = createWebViewData("SelectFile", "", item.file.id, item.file.path, item.file.xmlPath, item.file.name);
                                    (window as any).chrome.webview.postMessage(JSON.stringify(data));
                                }


                            }}
                            onDoubleClick={() => {
                                if (props.action === "manager") {

                                    setEditItem(item);
                                    setOpenDownload(true);


                                }

                            } }


                        >
                        {item.file.name}
                        </TableCellLayout >

                        {props.action != "selectFile" && <TableCellActions
                            onClick={onClickCellActions}
                            onKeyDown={onKeyDownCellActions}

                        >
                            <Tooltip content="Rename" relationship="label">
                            <Button
                                icon={<EditIcon />}
                                appearance="subtle"
                                aria-label="Rename"
                                
                                onClick={() => {

                                    setEditItem(item);
                                    setOpenRename(true);


                                }}

                                />
                                </Tooltip>
                                <Tooltip content="Download" relationship="label">
                            <Button
                                icon={<DownloadIcon />}
                                appearance="subtle"
                                aria-label="Download"
                                onClick={() => {

                                    setEditItem(item);
                                    setOpenDownload(true);


                                }}

                            />
                            </Tooltip>
                            <Tooltip content="Delete" relationship="label">
                            <Button
                                icon={<DeleteIcon />}
                                appearance="subtle"
                                aria-label="Delete"

                                onClick={async () => {


                                    var authObject = await getAuthObject();
                                    var isUsed = await IsLinkingBitConnected(authObject, item.file.id);
                                    if (isUsed) {
                                        setDeleteErrorMessage("The linking bit is in use and cannot be deleted.");
                                        return;
                                    }



                                    setEditItem(item);
                                    setOpenDelete(true);


                                }}
                                />
                                </Tooltip>
                        </TableCellActions>
                        }

                    </div>
                )
            }

    }

    function Header() {

        //const { startDisplayedItems, overflowItems, endDisplayedItems }: PartitionBreadcrumbItems<item> = partitionBreadcrumbItems({items: folderPath, maxDisplayedItems:5})

        //const MenuItem: React.FC<{ id: string; item: item }> = (props) => {
        //    const { item, id } = props;
        //    const isVisible = useIsOverflowItemVisible(id);
          
        //    if (isVisible) {
        //        return null;
        //    }

        //    return (
        //        <MenuItemLink {...item.file} href={"" }>
        //            {item.file.name}
        //        </MenuItemLink>
        //    );
        //};

        //const useTooltipStyles = makeStyles({
        //    tooltip: {
        //        whiteSpace: "nowrap",
        //        ...shorthands.overflow("hidden"),
        //        textOverflow: "ellipsis",
        //    },
        //});

        //const getTooltipContent = (breadcrumbItems: readonly item[] | undefined) => {
        //    if (!breadcrumbItems) {
        //        return "";
        //    }
        //    return breadcrumbItems.reduce((acc, initialValue, idx, arr) => {
        //        return (
        //            <>
        //                {acc}
        //                {arr[0].file.id !== initialValue.file.id && " > "}
        //                {initialValue.file.id}
        //            </>
        //        );
        //    }, <React.Fragment />);
        //};

        //const OverflowMenu = (props: PartitionBreadcrumbItems<item>) => {
        //    const { overflowItems, startDisplayedItems, endDisplayedItems } = props;
        //    const MoreHorizontal = bundleIcon(MoreHorizontalFilled, MoreHorizontalRegular);
        //    const { ref, isOverflowing, overflowCount } =
        //        useOverflowMenu<HTMLButtonElement>();

        //    const tooltipStyles = useTooltipStyles();

        //    if (!isOverflowing && overflowItems && overflowItems.length === 0) {
        //        return null;
        //    }

        //    const overflowItemsCount = overflowItems
        //        ? overflowItems.length + overflowCount
        //        : overflowCount;
        //    const tooltipContent =
        //        overflowItemsCount > 3
        //            ? `${overflowItemsCount} items`
        //            : {
        //                children: getTooltipContent(overflowItems),
        //                className: tooltipStyles.tooltip,
        //            };

        //    return (
        //        <BreadcrumbItem>
        //            <Menu hasIcons>
        //                <MenuTrigger disableButtonEnhancement>
        //                    <Tooltip withArrow content={tooltipContent} relationship="label">
        //                        <Button
        //                            id="menu"
        //                            appearance="subtle"
        //                            ref={ref}
        //                            icon={<MoreHorizontal />}
        //                            aria-label={`${overflowItemsCount} more items`}
        //                            role="button"
        //                        />
        //                    </Tooltip>
        //                </MenuTrigger>
        //                <MenuPopover>
        //                    <MenuList>
        //                        {isOverflowing &&
        //                            startDisplayedItems.map((item: item) => (
        //                                <MenuItem id={item.file.id.toString()} item={item}  key={item.file.id} />
        //                            ))}
        //                        {overflowItems &&
        //                            overflowItems.map((item: item) => (
        //                                <MenuItem id={item.file.id.toString()} item={item} key={item.file.id} />
        //                            ))}
        //                        {isOverflowing &&
        //                            endDisplayedItems &&
        //                            endDisplayedItems.map((item: item) => (
        //                                <MenuItem id={item.file.id.toString()} item={item} key={item.file.id} />
        //                            ))}
        //                    </MenuList>
        //                </MenuPopover>
        //            </Menu>
        //        </BreadcrumbItem>
        //    );
        //};

        const OverflowMenuItem: React.FC<Pick<OverflowItemProps, "children">> = (props) => {
            const { children } = props;
            const isVisible = useIsOverflowItemVisible(children.key!);

            if (isVisible) return null;

            return children;
        };


        const OverflowMenu: React.FC<{ breadcrumbItems: JSX.Element[]; usedPath: item[] }> = ({
        breadcrumbItems,
        usedPath,
    }) => {
        const { ref, isOverflowing } = useOverflowMenu<HTMLButtonElement>();

        if (!isOverflowing) return null;

        return (
            <Menu>
                <MenuTrigger disableButtonEnhancement>
                    <MenuButton
                        appearance="subtle"
                        menuIcon={null}
                        size="large"
                        ref={ref}
                        style={{
                            paddingLeft: tokens.spacingHorizontalNone,
                            paddingRight: tokens.spacingHorizontalNone,
                            minWidth: 34,
                            maxWidth: 34,
                            fontSize: 20,
                        }}
                    >
                        <MoreHorizontalRegular />
                    </MenuButton>
                </MenuTrigger>

                <MenuPopover style={{ minWidth: "110px", maxWidth: "110px" }}>
                    <MenuList>
                        {breadcrumbItems.map((breadcrumbItem, index) => {
                            if (breadcrumbItem.key?.startsWith("fragment")) {
                                return (
                                    <Tooltip
                                        content={
                                            index === 0
                                                ? "Linking Bits"
                                                : usedPath[
                                                      usedPath.findIndex((x) => breadcrumbItem.key?.includes(x.file.id))
                                                  ]?.file.name
                                        }
                                        withArrow
                                        positioning={"after"}
                                        relationship="inaccessible"
                                        showDelay={500}
                                    >
                                        <div key={breadcrumbItem.key} className={styles.overflowMenuItem}>
                                            <OverflowMenuItem key={breadcrumbItem.key} children={breadcrumbItem} />
                                        </div>
                                    </Tooltip>
                                );
                            }
                        })}
                    </MenuList>
                </MenuPopover>
            </Menu>
        );
    };

        function getBreadcrumbItems(currentPath: item[]) {
           

            let items: item[] = [...currentPath];
            const breadcrumbItems: JSX.Element[] = [];

            //if (props.screenSize === ScreenSize.mobile) {
            //    items = items.splice(items.length - 1, 1);
            //}

            items.map((item, index) => {
                breadcrumbItems.push(
                    <BreadcrumbItem key={`fragment-${item.file.id}`}>
                        <BreadcrumbButton
                            current={items.length - 1 === index}
                            onClick={() => changeDir(item)}
                            style={typographyStyles.body1}
                            disabledFocusable={false}
                            disabled={false}
                        >
                            <div style={{ fontWeight: items.length - 1 === index ? 600 : 400 }} className="truncateText">
                                {item.file.parentId==="" ? "Linking Bits" : item.file.name}
                            </div>
                        </BreadcrumbButton>
                    </BreadcrumbItem>,
                );

                if (items.length - 1 !== index) {
                    breadcrumbItems.push(
                        <BreadcrumbDivider style={{ marginTop: tokens.spacingVerticalS    }} key={`divider-${item.file.id}`} />,
                    );
                }
            });

            return breadcrumbItems;
        }

        return (
            <div>
                <div className={styles.headerContainerTop} >
                    <DialogTitle style={{ paddingBottom: tokens.spacingVerticalL, marginTop: tokens.spacingVerticalM }}>{props.action === "selectFolder" ? "Upload" : props.action==="manager"? "Manage":"Add"} </DialogTitle>
                    <Toolbar
                        style={{
                            marginBottom: tokens.spacingVerticalL,
                            marginTop: tokens.spacingVerticalM
                        }}
                    >
                        <ToolbarButton
                            icon={<DismissRegular />} onClick={() => {
                                var data = createWebViewData("Cancel", "", "", "", "", "");
                                (window as any).chrome.webview.postMessage(JSON.stringify(data));
                            }} />
                    </Toolbar>
                </div>
            <div className={styles.headerContainer}>
                <Breadcrumb aria-label="breadcrumb for navigation"
                    size="medium"
                    style={{
                        
                        marginBottom: tokens.spacingVerticalNone,
                        maxWidth: "70%",
                        width: "70%",
                        fontSize: "14px"
                        

                    }}
                    className={styles.headerColumn1 }

                >
                        <Overflow overflowDirection="start">    
                            <div className={styles.resizableArea} >
                                <OverflowMenu
                                    breadcrumbItems={getBreadcrumbItems(folderPath)}
                                    usedPath={folderPath}
                                />

                                    {getBreadcrumbItems(folderPath).map((breadCrumpItem, index) => (

                                    <div
                                        //index / 2 because for every item we are adding 2 UI elements into the array
                                        style={{
                                            maxWidth:
                                                index / 2 === folderPath.length - 1
                                                    ? "calc(100% - 150px)"
                                                    : "50%",
                                        }}
                                    >
                                        <OverflowItem key={breadCrumpItem.key} id={breadCrumpItem.key!}>
                                                {breadCrumpItem}
                                        </OverflowItem>
                                    </div>

                                    ))}
                                    
                               
                                </div>
                        </Overflow>

                    </Breadcrumb>
              
                <Toolbar
                    style={{
                       
                        marginBottom: tokens.spacingVerticalNone,


                    }}

                >
                  
                    {props.action != "selectFile" &&
                        <ToolbarButton
                            onClick={() => {
                                var f: item = {
                                    file: {
                                        name: "", id: undefined, parentId: currentFolderId, path: "", xmlPath: ""
                                    },
                                    entryType: IEntryType.Folder
                                }

                                setEditItem(f);
                                setOpenRename(true);

                            }}
                            icon={<AddRegular></AddRegular>} >Add folder</ToolbarButton>
                    }



                   
               
                   
                        </Toolbar>


               
              
            </div>
            </div>
        );
    }


    function Footer() {
        return (
            <div className={styles.grid}>
                <div>   </div>

                <div>
                <Button
                    appearance="secondary"
                    onClick={() => {


                        var data = createWebViewData("Cancel", "", "", "", "", "");
                        (window as any).chrome.webview.postMessage(JSON.stringify(data));


                    }}

                    style={{ marginTop: tokens.spacingVerticalL }}

                >{props.action !== "manager" ? "Cancel" : "Close"}
                </Button>


                {props.action === "selectFolder" && currentFolderId != "" && currentFolderId != "linkbitWorkspace" &&
                    <Button
                        appearance="primary"
                        onClick={() => {

                            //validate folders

                            var fileExist = files.findIndex(x => x.file.name == newFileName)>-1;

                            if (fileExist) {
                                setOpenNewFileName(true);
                                return;
                            }


                          
                            var data = createWebViewData("FolderSelect", currentFolderId, "","","",newFileName);
                            (window as any).chrome.webview.postMessage(JSON.stringify(data));


                        }}

                        style={{ marginTop: tokens.spacingVerticalL, marginLeft: tokens.spacingHorizontalM} }

                    >Upload
                        </Button>}
                </div>

            </div>
        )
    }


    function renderItem(entry: item, isLastItem: boolean) {
        return (
            <React.Fragment key={`item-${entry.file.id}`}>
                {isTruncatableBreadcrumbContent(entry.file.name, 30) ? (
                    <BreadcrumbItem>
                        <Tooltip withArrow content={entry.file.name} relationship="label">
                            <BreadcrumbButton current={isLastItem}>
                                {truncateBreadcrumbLongName(entry.file.name)}
                            </BreadcrumbButton>
                        </Tooltip>
                    </BreadcrumbItem>
                ) : (
                    <BreadcrumbItem>
                            <BreadcrumbButton current={isLastItem} onClick={() => {changeDir(entry) } } >{entry.file.name}</BreadcrumbButton>
                    </BreadcrumbItem>
                )}

                {!isLastItem && <BreadcrumbDivider />}
            </React.Fragment>
        );
    }


    function GlobalErrorMessage() {
        
        return (
            <div>
                {deleteErrorMessage !== "" &&

                    <Dialog modalType="alert" open={true} >
                        
                        <DialogSurface style={{ width: '70%', height: '150px', paddingTop: tokens.spacingVerticalL }}>
                            <DialogBody >
                                <DialogTitle>Information</DialogTitle>
                                <DialogContent>
                                    {deleteErrorMessage} 
                                </DialogContent>
                                <DialogActions>
                                    <DialogTrigger disableButtonEnhancement>
                                        <Button appearance="secondary" onClick={() => {setDeleteErrorMessage("") } }>Close</Button>
                                    </DialogTrigger>
                                    
                                </DialogActions>
                            </DialogBody>
                        </DialogSurface>
                    </Dialog>
           


}
            </div>
        )
    }

    

        return (

            <CustomFluentProvider isDark={isDark} colorConfiguration={portalConfig?.colorConfiguration}>
              
                <div
                    style={{
                        height: "100dvh",
                        width: "100vw",
                        background:
                                `radial-gradient(circle at 64% 46%,${customTokens.gradientBackground1} 0,${customTokens.gradientBackground2} 57%,${customTokens.gradientBackground3} 100%)`
                                
                    }}
                >
                    {Header()}
                    {AddRenameDialog()}
                    {DeleteDialog() }
                    {DownloadDialog() }
                    {NewFileNameDialog()}
                   

                   
                    {GlobalErrorMessage()}

                  


                    <div
                        style={{
                            overflowX: "hidden",

                            backgroundColor: tokens.colorNeutralBackground1,
                            color: tokens.colorNeutralForeground1,
                            borderTopLeftRadius: tokens.borderRadiusXLarge,
                            borderTopRightRadius: tokens.borderRadiusXLarge,
                            borderBottomRightRadius: tokens.borderRadiusXLarge,
                            borderBottomLeftRadius: tokens.borderRadiusXLarge,
                            marginLeft: tokens.spacingHorizontalM,
                            marginRight: tokens.spacingHorizontalM,
                            height: "70dvh"

                            
                        }}>
                      

                    <Table arial-label="Default table"
                        style={{
                            overflow: "auto",
                            paddingBottom: '33px'
                        } }


                    >
                        <TableHeader>
                            <TableRow>
                               
                                    <TableHeaderCell
                                        style={{ width: "50px" }}

                                    ><Document20Regular ></Document20Regular></TableHeaderCell>
                                    <TableHeaderCell style={{ fontWeight:600} }>Name</TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {rows.map(({ item, selected, onClick, onKeyDown, appearance }) => (
                                <TableRow
                                    key={item.file.id}
                                    onClick={onClick}
                                    onKeyDown={onKeyDown}
                                  
                                >
                                    {/*<TableSelectionCell*/}
                                    {/*    checked={selected}*/}
                                    {/*    checkboxIndicator={{ "aria-label": "Select row" }}*/}
                                    {/*/>*/}

                                    <TableCell
                                        onClick={() => {
                                            changeDir(item)
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <TableCellLayout
                                            style= {{ width:"50px"} }

                                        >
                                        {item.entryType === IEntryType.Folder && <Icon
                                          
                                            {...getFileTypeIconProps({
                                                type: FileIconType.folder,
                                                size: 24,
                                                imageFileType: "svg",
                                            })}
                                        />}
                                        {item.entryType === IEntryType.LinkingBitTemplate && <Icon

                                            {...getFileTypeIconProps({
                                                extension :"docx",
                                                size: 24,
                                                imageFileType: "svg",
                                            })}
                                        />}
                                        </TableCellLayout>
                                    
                                    </TableCell>

                                    <TableCell
                                       

                                        style={{ cursor: 'pointer' }} >
                                        
                                        {createTableCell(item)}
                                         
                                      
                                    </TableCell>


                                </TableRow>
                            ))}
                        </TableBody>
                        </Table>

                    </div>

                   
              



                    {Footer()}

              </div>
                
            </CustomFluentProvider>

        )
    }
    

