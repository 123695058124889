﻿import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    Theme,
    teamsLightTheme,
    teamsDarkTheme,
    teamsHighContrastTheme,
    webLightTheme,
    webDarkTheme,
    themeToTokensObject,
    Button,
    DataGrid,
    tokens,
    Menu,
    MenuItem,
    MenuTrigger,
    MenuButton,
    MenuPopover,
    MenuList,
    makeStyles,
    TabList,
    Tab,
    shorthands,
    TableColumnDefinition,
    createTableColumn,
    TableCellLayout,
    DataGridHeader,
    DataGridRow,
    DataGridHeaderCell,
    DataGridBody,
    DataGridCell,
    TableHeaderCell,
    TableRowId,
    DataGridProps,
    OverlayDrawer,
    DrawerHeader,
    DrawerHeaderTitle,
    DrawerBody,
    Drawer,
    Field,
    Input,
    Label,
    DialogContent,
    Dialog,
    DialogSurface,
    DialogBody,
    DialogTitle,
    DialogActions,
    Title2
} from "@fluentui/react-components";
import { AddRegular, DeleteRegular, DismissRegular, EditRegular,  RenameRegular, } from "@fluentui/react-icons";
import {Toolbar, ToolbarButton } from "@fluentui/react-components";
import useStore from "../../apiFunctions/store/store";
import usePersistentStore from "../../apiFunctions/store/persistentStore";
import { getCustomTheme } from "documentdrafter-components";
import { ISignalRUpdate } from "documentdrafter-components";
import "@fluentui/react/dist/css/fabric.min.css";
import React from "react";
import { IUserProps, IUserShare } from "../entitiesAPI";
import { IValidation,ITestValidation } from "../ddentities";
import { _copyAndSort } from "../basefunctions";
import { getAuthObject } from "../../apiFunctions/authenticateFunctions";
import { AddValidation, DeleteValidation, GetAllValidations, TestValidation, UpdateValidation, getShareUsers } from "../../apiFunctions/baseFunctions/baseFunctions";
import AceEditor from 'react-ace';
import ace from 'ace-builds/src-noconflict/ace';
import 'brace/mode/javascript';
import 'brace/theme/tomorrow';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-error_marker';
import 'ace-builds/src-noconflict/ext-inline_autocomplete';



var _allUsers: IUserShare[] = [];


export const Validations: React.FC = () => {

    const userObj: IUserProps = useStore().userObj;
    const isUserAuthenticated = useStore().isUserAuthenticated;
    //SignalR
    const [documentsSignalRUpdateQueue, setDocumentsSignalRUpdateQueue] = useState<ISignalRUpdate[]>([]);
    const screenSize = useStore().screenSize;
    const portalConfig = useStore().portalConfig;


    const endPoints = useStore().endpoints;
    const isDark = usePersistentStore().darkMode;
    const theme = isDark ? webDarkTheme : webLightTheme;
    const navigate = useNavigate();


    const [phraseLibraries, setPhraseLibraries] = React.useState<IValidation[]>([]);
    const [currentValidation, SetCurrentValidation] = React.useState<IValidation>(null);
    const [hideDialog, setHideDialog] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isOpenDelete, setIsOpenDelete] = React.useState(false);
    const [isOpenRename, setIsOpenRename] = React.useState(false);
    const [isOpenEdit, setIsOpenEdit] = React.useState(false);
    const [isPopupVisible, setIsPopUpVisible] = React.useState(false);
    const [showMessageBar, setShowMessageBar] = React.useState<boolean>(false);
    const [NewName, setNewName] = React.useState<string>("");
    const [newEditor, setNewEditor] = React.useState<boolean>(false);
    const containerRef = React.useRef(null);
    const [items, setItems] = React.useState<Item[]>([]);
    const [open, setOpen] = React.useState(false);
    const [dataSetid, setDataSetid] = React.useState<string>("");
    const customTokens = themeToTokensObject(getCustomTheme(!isDark));
    const [localScriptToEdit, setLocalScriptToEdit] = React.useState<string>("");


    type NameCell = {
        label: string;
        id: string;
    };

    type LastUpdatedCell = {
        label: string;
        timestamp: number;

    }
    type LastUpdateBy = {
        label: string;
    }

    type Item = {
        name: NameCell;
        lastUpdated: LastUpdatedCell;
        lastUpdateBy: LastUpdateBy;
        enabled: boolean;

    };


    const columns: TableColumnDefinition<Item>[] = [
        createTableColumn<Item>({
            columnId: "name",

            compare: (a, b) => {
                return a.name.label.localeCompare(b.name.label);
            },
            renderHeaderCell: () => {
                return "Name";



            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        <div style={{ width: "40vvw", overflow: "clip" }}>{item.name.label}</div>

                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<Item>({
            columnId: "lastUpdated",
            compare: (a, b) => {
                return a.lastUpdated.timestamp - b.lastUpdated.timestamp;
            },
            renderHeaderCell: () => {
                return "Last updated";
            },

            renderCell: (item) => {
                return (
                    <TableCellLayout style={{ width: "20vvw" }} >
                        {item.lastUpdated.label}
                    </TableCellLayout>
                )
            },
        }),
        createTableColumn<Item>({
            columnId: "lastUpdateBy",
            compare: (a, b) => {
                return a.lastUpdateBy.label.localeCompare(b.lastUpdateBy.label);
            },
            renderHeaderCell: () => {
                return "Modified by";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout >
                        {item.lastUpdateBy.label}
                    </TableCellLayout>
                );
            },
        }),
        //createTableColumn<Item>({
        //    columnId: "enabled",

        //    renderHeaderCell: () => {
        //        return "Enabled";
        //    },
        //    renderCell: (item) => {
        //        return (
        //            <TableCellLayout >
        //                {item.enabled ? "Yes" : "No"}
        //            </TableCellLayout>
        //        );
        //    },
        //})

    ]





    function RefreshValidations(force: boolean) {

        if (isOpenEdit && !force)
            return;

        getAuthObject().then((authResult) => {
            GetAllValidations(authResult).then((validations) => {

                var tmpitems: Item[] = [];
               
                for (var i = 0; i < validations.length; i++) {

                    var userEmail = "";
                    var formattedDate = "";

                    if (validations[i].updated != undefined) {
                        let user = _allUsers.find(x => x.id === validations[i].updatedBy)
                        userEmail = (user === undefined ? "n/a" : user.email);
                    }

                    try {
                        formattedDate = new Date(validations[i].updated).toLocaleString('en-GB', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit'

                        });

                    } catch (e) {
                        formattedDate = e.toString();

                    }

                    var tmp: Item = {
                        name: { label: validations[i].name, id: validations[i].id },
                        lastUpdateBy: { label: userEmail },
                        lastUpdated: { label: formattedDate, timestamp: 0 },
                        enabled: validations[i].enabled
                    }

                    tmpitems.push(tmp);
                }
                setItems(tmpitems);
                setPhraseLibraries(validations);
            });

        });
    }
    const refreshusers = () => {

        return new Promise(resolve => {

            if (window.MyshareUsers != undefined) {

                var portalusers = window.MyshareUsers;
                var currentUser = JSON.parse(sessionStorage.getItem("user"));

                if (currentUser !== null && currentUser !== undefined) {
                    var u: IUserShare = {
                        email: currentUser.portalUser.Description.email,
                        id: currentUser.portalUser.id,
                        name: currentUser.portalUser.Description.name
                    };
                    portalusers.push(u);
                }
                _allUsers = portalusers;
                resolve("Ok");

            }
            else {

                getAuthObject().then((authResult) => {
                    getShareUsers(authResult).then((usersResult) => {

                        let pl: IUserShare[] = [];
                        pl = usersResult;
                        //_allUsers = pl;
                        window.MyshareUsers = pl;

                        var portalusers = pl;
                        var currentUser = JSON.parse(sessionStorage.getItem("user"));

                        if (currentUser !== null && currentUser !== undefined) {
                            var u: IUserShare = {
                                email: currentUser.portalUser.Description.email,
                                id: currentUser.portalUser.id,
                                name: currentUser.portalUser.Description.name
                            };
                            portalusers.push(u);
                        }
                        _allUsers = portalusers;
                        resolve("Ok");
                    });

                });
            }
        })
    }

    React.useEffect(() => {
        refreshusers().then(() => {
            RefreshValidations(false);

        });
       ace.require('ace/ext/language_tools');
        
    }, []);

    const DeleteSelectedValidation = () => {
      
        return getAuthObject().then((authResult) => {
      
            var IdArray = Array.from(selectedRows);
            var deletePromises = IdArray.map(validationId => DeleteValidation(authResult, validationId.toString()));
            return Promise.all(deletePromises);
        });
    }

    const AddNew = (name: string) => {
        getAuthObject().then((authResult) => {
            AddValidation(authResult, name).then((validation) => {
                RefreshValidations(false);
                SetCurrentValidation(validation);

                setIsOpen(false);
                setIsPopUpVisible(true);
                //SetAddColumnOpen(true);
            });


        });
    }


    const editValidation = (validationObj: IValidation) => {
        getAuthObject().then((authResult) => {
            UpdateValidation(authResult, validationObj).then((datasetResult) => {

                RefreshValidations(false);
                setIsOpenRename(false);
            });

        });


    }

    const SaveValidation = (validationObj: IValidation) => {
        getAuthObject().then((authResult) => {
            UpdateValidation(authResult, validationObj).then((datasetResult) => {

                RefreshValidations(false);
                setIsOpenEdit(false);
            });

        });


    }
    //const OpenSingleDataSet = (datasetid) => {
    //    getAuthObject().then((authResult) => {
    //        OpenDataSet(authResult, datasetid).then((dataset) => {
    //            SetCurrentDataSet(dataset);
    //        });
    //    });
    //}




    const [selectedRows, setSelectedRows] = React.useState(
        new Set<TableRowId>([])
    );
    const onSelectionChange: DataGridProps["onSelectionChange"] = (e, data) => {
        setSelectedRows(data.selectedItems);
    };

    function closeGrid(closeit: boolean) {
        if (closeit)
            setIsOpenEdit(false);
    }

    function forceRefresh() {
        RefreshValidations(true);
    }
    return (
        <div>
            <div
                style={{
                    marginTop: tokens.spacingVerticalNone,
                    marginBottom: tokens.spacingVerticalXL,
                    marginLeft: "20px",
                    maxWidth: "100%"

                }}
            >
                <Title2>Input validations</Title2>
            </div>


            {isOpenEdit && <div>

                <Dialog open={isOpenEdit}>
                    <DialogSurface style={{ width:"50vw"} } >
                        <DialogBody>
                            <DialogTitle
                                action={
                                    <Button
                                        appearance="subtle"
                                        aria-label="Close"
                                        icon={<DismissRegular />}
                                        onClick={() => {
                                            setIsOpenEdit(false);
                                        }}
                                    />
                                }
                            >
                                Edit Code & Test
                            </DialogTitle>
                            <DialogContent>
                                <div style={{ height: "50vh", width: "98%", border: "1px solid black" }}>
                                    <AceEditor
                                        style={{ height: "97%", width: "100%", margin: "8px 0px 0px 0px" }}
                                        highlightActiveLine={true}
                                        mode="javascript"
                                        theme="tomorrow"
                                        value={localScriptToEdit}
                                        onChange={(v, e) => {

                                            setLocalScriptToEdit(v);
                                        }}
                                        setOptions={{
                                            enableBasicAutocompletion: true,
                                            enableLiveAutocompletion: true,
                                            enableSnippets: false,
                                            showLineNumbers: true,
                                            tabSize: 2,
                                        }}
                                    />
                                </div>



                                <div style={{ display: "flex", flexDirection: "column", width:"99%", marginBottom:"8px" }}>
                                    
                                    <Label >
                                        Validate the code
                                    </Label>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "8px" }}>
                                        <Input defaultValue={NewName} onChange={(e, v) => { setNewName(e.currentTarget.value); }} style={{ width:"80%" }} />

                                    <Button
                                        onClick={() => {

                                            var args = [];
                                            args.push(NewName)  
                                           
                                            var testObj: ITestValidation = { Script: localScriptToEdit, FunctionName: "test", Args: args };


                                           

                                            try {

                                                getAuthObject().then((authResult) => {
                                                    TestValidation(authResult, testObj).then((result) => {
                                                        alert("Result: " + result);
                                                  
                                                    });

                                            });

                                            } catch (e) {


                                                alert(e.toString());

                                            }


                                        
                                          


                                        }}

                                        >Test</Button>
                                    </div>
                                </div>

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => {
                                    setIsOpenEdit(false);

                                }} >Close</Button>


                                <Button appearance="primary" disabled={NewName.length === 0}
                                    onClick={() => {

                                        currentValidation.code = localScriptToEdit;
                                        SaveValidation(currentValidation);

                                    }}
                                >
                                    Ok
                                </Button>
                            </DialogActions>


                        </DialogBody>
                    </DialogSurface>
                </Dialog>

            </div>}


           

            {!open &&
                <div
                    style={{
                        overflowX: "hidden",

                        backgroundColor: tokens.colorNeutralBackground1,
                        color: tokens.colorNeutralForeground1,
                        borderTopLeftRadius: tokens.borderRadiusXLarge,
                        borderTopRightRadius: tokens.borderRadiusXLarge,
                        marginLeft: tokens.spacingHorizontalM,
                        marginRight: tokens.spacingHorizontalM,


                        width: "80dvw"


                    }}>


                    <Toolbar aria-label="Vertical Button" >
                        <ToolbarButton icon={<AddRegular></AddRegular>} onClick={() => {
                            setNewName("");
                            setIsOpen(true);

                        }}>New

                        </ToolbarButton>
                        <ToolbarButton icon={<EditRegular></EditRegular>} disabled={selectedRows.size !== 1} onClick={() => {

                            var IdArray = Array.from(selectedRows);
                            var dataSetId = IdArray[0];
                            var validation = phraseLibraries.find(x => x.id === dataSetId.toString());
                            SetCurrentValidation(validation);

                            if (validation.code === undefined|| validation.code==="") {
                                validation.code = "function test(s) { return true}";
                            }

                            setLocalScriptToEdit(validation.code);   
                            setIsOpenEdit(true);


                        }}>Edit
                        </ToolbarButton>
                        <ToolbarButton icon={<RenameRegular></RenameRegular>} disabled={selectedRows.size !== 1} onClick={() => {

                            var IdArray = Array.from(selectedRows);
                            var phraseLibId = IdArray[0];
                            var _library = phraseLibraries.find(x => x.id === phraseLibId.toString());
                            SetCurrentValidation(_library);
                            setNewName(_library.name);
                            setIsOpenRename(true);

                        }} >Rename
                        </ToolbarButton>


                        <ToolbarButton icon={<DeleteRegular></DeleteRegular>} disabled={selectedRows.size === 0} onClick={() => {
                            setIsOpenDelete(true);

                        }}>
                            Delete
                        </ToolbarButton>
                    </Toolbar></div>}
            {!open &&
                <div
                    style={{
                        overflowX: "hidden",

                        backgroundColor: tokens.colorNeutralBackground1,
                        color: tokens.colorNeutralForeground1,

                        marginLeft: tokens.spacingHorizontalM,
                        marginRight: tokens.spacingHorizontalM,

                        height: "69dvh",
                        width: "80dvw"


                    }}>

                    <DataGrid
                        items={items}
                        columns={columns}
                        sortable
                        selectionMode="multiselect"

                        getRowId={(item) => item.name.id}
                        focusMode="composite"
                        size="medium"
                        selectedItems={selectedRows}
                        onSelectionChange={onSelectionChange}
                        style={{ overflow: "auto" }}

                    >
                        <DataGridHeader>
                            <DataGridRow
                                selectionCell={{
                                    checkboxIndicator: { "aria-label": "Select all rows" },
                                }}
                            >
                                {({ renderHeaderCell }) => (
                                    <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                                )}




                            </DataGridRow>

                        </DataGridHeader>
                        <DataGridBody<Item>>
                            {({ item, rowId }) => (
                                <DataGridRow<Item>
                                    key={rowId}
                                    selectionCell={{
                                        checkboxIndicator: { "aria-label": "Select row" },
                                    }}
                                >
                                    {({ renderCell }) => (
                                        <DataGridCell>{renderCell(item)}</DataGridCell>
                                    )}
                                </DataGridRow>
                            )}
                        </DataGridBody>
                    </DataGrid></div>
            }


            <Dialog open={isOpen}>
                <DialogSurface  >
                    <DialogBody>
                        <DialogTitle
                            action={
                                <Button
                                    appearance="subtle"
                                    aria-label="Close"
                                    icon={<DismissRegular />}
                                    onClick={() => {
                                        setIsOpen(false);
                                    }}
                                />
                            }
                        >
                            Add new validation
                        </DialogTitle>
                        <DialogContent>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <Label >
                                    Name
                                </Label>
                                <Input autoFocus={isOpen} defaultValue={NewName} onChange={(e, v) => { setNewName(e.currentTarget.value); }} />
                            </div>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                setIsOpen(false);

                            }} >Close</Button>


                            <Button appearance="primary" disabled={NewName.length === 0}
                                onClick={() => {
                                    AddNew(NewName);
                                    setNewName("");
                                    setIsOpen(false);
                                }}
                            >
                                Ok
                            </Button>
                        </DialogActions>


                    </DialogBody>
                </DialogSurface>
            </Dialog>
            <Dialog open={isOpenRename}>
                <DialogSurface  >
                    <DialogBody>
                        <DialogTitle
                            action={
                                <Button
                                    appearance="subtle"
                                    aria-label="Close"
                                    icon={<DismissRegular />}
                                    onClick={() => {
                                        setIsOpenRename(false);
                                    }}
                                />
                            }
                        >
                            Rename validation
                        </DialogTitle>
                        <DialogContent>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <Label >
                                    Name
                                </Label>
                                <Input defaultValue={NewName} autoFocus={isOpenRename} onChange={(e, v) => { setNewName(e.currentTarget.value); }} />
                            </div>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                setIsOpenRename(false);

                            }} >Close</Button>



                            <Button appearance="primary" disabled={NewName.length === 0}
                                onClick={() => {
                                    currentValidation.name = NewName;
                                    editValidation(currentValidation);
                                    RefreshValidations(true);

                                }}
                            >
                                Ok
                            </Button>
                        </DialogActions>


                    </DialogBody>
                </DialogSurface>
            </Dialog>
            <Dialog open={isOpenDelete}>
                <DialogSurface  >
                    <DialogBody>
                        <DialogTitle
                            action={
                                <Button
                                    appearance="subtle"
                                    aria-label="Close"
                                    icon={<DismissRegular />}
                                    onClick={() => {
                                        setIsOpenDelete(false);
                                    }}
                                />
                            }
                        >
                            Delete?
                        </DialogTitle>
                        <DialogContent>
                            <div style={{ display: "flex", flexDirection: "column" }}>

                                Please confirm to delete {selectedRows.size} {selectedRows.size === 1 ? "validation" : "validations"}, when deleted the {selectedRows.size === 1 ? "validation" : "validations"} can not be restored.
                            </div>

                        </DialogContent>
                        <DialogActions>


                            <Button onClick={() => {
                                setIsOpenDelete(false);

                            }} >Close</Button>
                            <Button
                                appearance="primary"
                                onClick={() => {
                                    DeleteSelectedValidation().then(() => {
                                     
                                        setIsOpenDelete(false);
                                        setSelectedRows(new Set<TableRowId>([]));

                                        //remove row from grid find in Items and remove
                                        var tmpitems = items;
                                        var IdArray = Array.from(selectedRows);
                                        IdArray.forEach((id) => {
                                            var index = tmpitems.findIndex(x => x.name.id === id);
                                            tmpitems.splice(index, 1);
                                        });
                                        setItems(tmpitems);
                                      

                                       
                                    })

                                }}
                            >
                                Delete {selectedRows.size} {selectedRows.size === 1 ? "validation" : "validations"}
                            </Button>
                        </DialogActions>


                    </DialogBody>
                </DialogSurface>
            </Dialog>









            <div
                style={{
                    height: "33px",
                    width: "80dvw",
                    backgroundColor: tokens.colorNeutralBackground1,
                    borderBottomRightRadius: tokens.borderRadiusXLarge,
                    borderBottomLeftRadius: tokens.borderRadiusXLarge,
                    marginLeft: tokens.spacingHorizontalM,
                }}
            />

        </div>


        //<div style={{ backgroundColor: "#ffffff", width: "100vw", height: "100vh" }} ref={containerRef}>

        //    <div style={showMessageBar ? {} : { display: "hidden" }} className={classNamesMessageBar.MessageBarContainer}>
        //        {showMessageBar &&
        //            <MessageBar isMultiline={false} messageBarType={MessageBarType.success} dismissButtonAriaLabel="Close" onDismiss={() => { removeMsgBar() }}  >Record is created
        //            </MessageBar>
        //        }
        //    </div>


        //<ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} styles={{ root: { marginTop: "50px", marginLeft: "244px" } }}>
        //    {!isPopupVisible && <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
        //        <div>
        //            <CommandBar

        //                items={commandItems}
        //                ariaLabel="Use left and right arrow keys to navigate between commands"
        //            />

        //        </div>
        //    </Sticky>}

        //    <div className={[classNames.columnPadding, classNames.defaultBackGround].join(' ')}>
        //        <div style={{ width: "1100px" }} >
        //            <MarqueeSelection selection={_selectCon}>
        //                <DetailsList
        //                    items={dataSets}
        //                    compact={false}

        //                    columns={_columns}
        //                    selectionMode={SelectionMode.single}
        //                    setKey="key"
        //                    selection={_selectCon}
        //                    selectionPreservedOnEmptyClick={true}
        //                    enterModalSelectionOnTouch={true}
        //                    ariaLabelForSelectionColumn="Toggle selection"
        //                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
        //                    checkButtonAriaLabel="Row checkbox"

        //                />
        //            </MarqueeSelection>
        //        </div>
        //    </div>
        //    <div style={{ padding: '5px', width: '200px' }}>
        //    </div>
        //</ScrollablePane>
        //</div>
    )


}


