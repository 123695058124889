import { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
//import { tokens } from "@fluentui/react-components";

import { TemplatesView } from "documentdrafter-components";
import {
    ICachePath,
    IEntryType,
    ISlimCacheSearchResult,
    ISlimCachedDirEntry,
    ScreenSize,
} from "documentdrafter-components";
import {
    CheckMirrorOrigin,
    GetWorkSpaceById,
    SearchDirectory,
    downloadStaticFile,
    GetQuestionnaireDirectDownload
} from "documentdrafter-components";

import { base64ToBlob } from "documentdrafter-components";

import useStore from "../../apiFunctions/store/store";
import usePersistentStore from "../../apiFunctions/store/persistentStore";
import React from "react";
import { IMainState } from "../ddentities";

let searchTimer: number;

interface ITemplatesView {
    searchValue: string;
    selectedWorkspace: string;
    setSelectedWorkspace: (id: string) => void;
    setSearchValue: (newValue: string) => void;
    isFetching: boolean;
    setIsFetching: (value: boolean) => void;
    screenSize: number;
    setShowSearchbar: (show: boolean) => void;
    forceGoToRoot: boolean;
    helpPortalLink?: string;
}

export const TemplatesTab: FC<ITemplatesView> = (props) => {
    const [activeFolder, setActiveFolder] = useState<string>("");
    const [searchView, setSearchView] = useState<ISlimCacheSearchResult[]>([]);
    const [hasSearched, setHasSearched] = useState<boolean>(false);

    const navigate = useNavigate();
    const userObj = useStore().userObj;
    const directory = useStore().workspaceDirectory;
    const workspaces = useStore().availableWorkspaces;
    const templateColumnsResizeValues = usePersistentStore().templateColumnsResizeValues;
    
    useEffect(() => {

        const previousMainState: IMainState = JSON.parse(sessionStorage.getItem("previousMainState"));
        if (previousMainState && previousMainState.activeTab === "templates" && previousMainState.openItem.length) {
            if (previousMainState.openItem.startsWith("search:")) {
                const search = previousMainState.openItem.substring(7)
                props.setIsFetching(true)
                props.setSearchValue(search)
            }
            else { 
                setActiveFolder(previousMainState.openItem);
                props.setShowSearchbar(false);
            }
            //timeout because this might be hit a few times upon initialization
            setTimeout(() => { sessionStorage.removeItem("previousMainState"); }, 1000);

        }
        else if (props.selectedWorkspace) {
            setActiveFolder(props.selectedWorkspace);
            props.setShowSearchbar(true);
        }
    }, [props.selectedWorkspace, props.forceGoToRoot]);

    useEffect(() => {
        clearTimeout(searchTimer);
        
        if (props.searchValue.length) {
            searchTimer = setTimeout(() => {
                props.setIsFetching(true);

                SearchDirectory(userObj!, props.searchValue, true, false).then((searchResult) => {
                    setHasSearched(true);
                    setSearchView(searchResult);

                    props.setIsFetching(false);
                });
            }, 250);
        } else {
            setHasSearched(false);
            setSearchView([]);
        }
    }, [props.searchValue]);

    function staticFileClicked(itemId: string) {
        downloadStaticFile(userObj!, itemId, "", -1).then((downloadResult) => {
            const blob = base64ToBlob(downloadResult.fileData, downloadResult.mimetype, 512);

            if (blob) {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = downloadResult.fileName;
                document.body.appendChild(a);
                a.click();
                a.remove();
            }

            // TODO: should we implement error handling?
        });
    }

    function directDownloadFileClicked(itemId: string) {
        GetQuestionnaireDirectDownload(userObj!, itemId).then((downloadResult) => {
            const blob = base64ToBlob(downloadResult[0].fileData, downloadResult[0].mimetype, 512);

            if (blob) {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = downloadResult[0].fileName;
                document.body.appendChild(a);
                a.click();
                a.remove();
            }

            // TODO: should we implement error handling?
        });
    }



    function setTemplateColumnsResizeValues(columnId: string, width: number) {
        const resizeData = { ...templateColumnsResizeValues };
        const columnData = resizeData[columnId];

        if (columnData) {
            if (columnData.minWidth && width < columnData.minWidth) {
                width = columnData.minWidth;
            }

            columnData.defaultWidth = width;
            columnData.idealWidth = width;
        }
        usePersistentStore.setState({ documentColumnsResizeValues: resizeData });
    }

    function onDirEntryClick(item: ISlimCachedDirEntry) {
        if (item.directDownload) {
            directDownloadFileClicked(item.id);
            return;
        }
        if (item.entrytype === 0) {
            setActiveFolder(item.id);
            props.setShowSearchbar(item.id === props.selectedWorkspace);
        } else if (item.entrytype === 2) {
            CheckMirrorOrigin(userObj!, item.id).then((result) => {
                if (result.length) {
                    staticFileClicked(result);
                } else {
                    const mainState: IMainState = { activeTab: "templates", openItem: item.parentid }
                    //if search is active, we dont navigate to item
                    if (props.searchValue.length)
                        mainState.openItem = "search:" + props.searchValue;
                    sessionStorage.setItem("previousMainState", JSON.stringify(mainState));

                    navigate(`/document/questionnaire/${item.id}`);
                }
            });
        } else if (item.entrytype === 3) {
            staticFileClicked(item.id);
        } else {
            const mainState: IMainState = { activeTab: "templates", openItem: item.parentid }
            //if search is active, we dont navigate to item
            if (props.searchValue.length)
                mainState.openItem = "search:" + props.searchValue;
            sessionStorage.setItem("previousMainState", JSON.stringify(mainState));

            navigate(`/document/questionnaire/${item.id}`);
        }
    }

    function onSearchBreadcrumbClick(pathItem: ICachePath, workspace: ICachePath) {
        const dirEntry = directory.templates.find((x) => x.id === pathItem.id);

        //dirEntry is inside current workspace
        if (dirEntry) {
            setActiveFolder(dirEntry.id);
        }
        //navigating to current workspace
        else if (pathItem.id === props.selectedWorkspace) {
            setActiveFolder(props.selectedWorkspace);
            props.setShowSearchbar(true);
        }
        //navigating to another workspace
        else {
            GetWorkSpaceById(userObj!, workspace.id).then((workspaceResult) => {
                useStore.setState({ workspaceDirectory: workspaceResult });
                props.setSelectedWorkspace(workspace.id);

                setTimeout(() => {
                    setActiveFolder(pathItem.id);
                    props.setShowSearchbar(pathItem.id === workspace.id);
                }, 250);
            });
        }
    }

    function getDirectoryView() {
        let directoryEntries: ISlimCachedDirEntry[] = [];

        if (props.searchValue && props.searchValue.length && searchView.length) {
            directoryEntries = searchView;
        } else if (props.searchValue && props.searchValue.length && !searchView.length) {
            directoryEntries = [];
        } else {
            directoryEntries = directory.templates.filter((x) => x.parentid === activeFolder);
        }

        return directoryEntries;
    }

    const breadcrumbItems: ISlimCachedDirEntry[] = [];

    let parentId = activeFolder;

    while (parentId) {
        const dir = directory.templates.find((x) => x.id === parentId);

        if (!dir) break;

        breadcrumbItems.push(dir);

        parentId = dir!.parentid;
    }

    const workspace = workspaces.find((x) => x.id === props.selectedWorkspace);
    if (workspace) {
        breadcrumbItems.push({
            id: workspace.id,
            name: workspace.name,
            parentid: "",
            clauseid: "",
            docid: "",
            entrytype: IEntryType.Folder,
            imageUrl: "",
            bodyText:""
        });
    }

    breadcrumbItems.reverse();

    if (directory)
        return (
            <TemplatesView
                selectedWorkspace={workspaces.find((x) => x.id === props.selectedWorkspace)!}
                directories={getDirectoryView()}
                stylingConfig={directory.styleConfig}
                currentPath={breadcrumbItems}
                onDirEntryClick={onDirEntryClick}
                onSearchBreadcrumbClick={onSearchBreadcrumbClick}
                setSearchValue={props.setSearchValue}
                templateColumnsResizeValues={templateColumnsResizeValues}
                setTemplateColumnsResizeValues={setTemplateColumnsResizeValues}
                isFetching={props.isFetching}
                screenSize={props.screenSize}
                searchValue={props.searchValue}
                hasSearched={hasSearched}
                helpPortalLink={props.helpPortalLink}
            />
        );
};
