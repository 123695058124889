import { FC, useState, useEffect } from "react";


import useStore from "../../apiFunctions/store/store";
import usePersistentStore from "../../apiFunctions/store/persistentStore";
import { WorkspaceSelector } from "documentdrafter-components";
import { ISlimWorkspace } from "documentdrafter-components";
import React from "react";
interface IWorkspacesTab {
    searchValue: string;
    selectedWorkspace: string;
    setSelectedWorkspace: (workspaceId: string) => void;
    setSelectedTab: (tabId: string) => void;
    screenSize: number;
}

export const WorkspacesTab: FC<IWorkspacesTab> = (props) => {
    const [recentWorkspaces, setRecentWorkspaces] = useState<ISlimWorkspace[]>([]);
    const [otherWorkspaces, setOtherWorkspaces] = useState<ISlimWorkspace[]>([]);

    const workspaces = useStore().availableWorkspaces;
    const recentWorkspaceIds = usePersistentStore().recentWorkspaces;

    useEffect(() => {
        const recentIds = [...recentWorkspaceIds];
        const recent: ISlimWorkspace[] = [];
        const other: ISlimWorkspace[] = [...workspaces];

        for (let i = 0; i < recentIds.length; i++) {
            const dirIndex = other.findIndex((x) => x.id === recentIds[i]);

            if (dirIndex > -1) recent.push(other.splice(dirIndex, 1)[0]);
            else {
                recentIds.splice(i, 1);
                i--;
            }
        }

        if (recentIds.length !== recentWorkspaceIds.length)
            usePersistentStore.setState({ recentWorkspaces: recentIds });
        setRecentWorkspaces(recent);
        setOtherWorkspaces(other.sort());
    }, [recentWorkspaceIds, workspaces]);

    return (
        <WorkspaceSelector
            recentWorkspaces={recentWorkspaces}
            otherWorkspaces={otherWorkspaces}
            selectedWorkspace={props.selectedWorkspace}
            setSelectedWorkspace={(workspace: string) => {
                props.setSelectedWorkspace(workspace);
                //addRecentWorkspace(workspace);
                //props.setSelectedTab("templates");
            }}
            searchValue={props.searchValue}
            screenSize={props.screenSize}
        />
    );
};