﻿import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    Theme,
    teamsLightTheme,
    teamsDarkTheme,
    teamsHighContrastTheme,
    webLightTheme,
    webDarkTheme,
    themeToTokensObject,
    Button,
    tokens,
    Menu,
    MenuItem,
    MenuTrigger,
    MenuButton,
    MenuPopover,
    MenuList,
    makeStyles,
    TabList,
    Tab,
    shorthands
} from "@fluentui/react-components";
import { DrawTextRegular, NavigationRegular, QuestionCircleRegular, SignOutRegular, JavascriptRegular, CloudDatabaseRegular } from "@fluentui/react-icons";
import { Dropdown, Option, Toolbar, ToolbarButton } from "@fluentui/react-components";
import useStore from "../../apiFunctions/store/store";
import usePersistentStore from "../../apiFunctions/store/persistentStore";
import { Header, CustomMenuButton } from "documentdrafter-components";
import { ISlimWorkspace, ScreenSize, CustomFluentProvider } from "documentdrafter-components";
import { getCustomTheme } from "documentdrafter-components";
import { getEndpoints, setEndpoints, MainPageSignalRWrapper, ISignalRUpdate, SignalRUpdateType, ISlimCachedDirectory } from "documentdrafter-components";
import "@fluentui/react/dist/css/fabric.min.css";
import React from "react";
import { IUserProps } from "../entitiesAPI";
import { getHelpPortalUrl } from "../../apiFunctions/baseFunctions/baseFunctions";
import {
    DocumentTableRegular,
    ListRegular,HomeDatabaseRegular
    

} from "@fluentui/react-icons";
import { LookUpTables } from "./lookupTables";
import { SharePointTables } from "./sharePointLists";
import { PhraseLibrary } from "./phraseLibrary";
import { SqlTables } from "./sqlTables";
import { Validations } from "./Validations";
import { isLocalOrDevHost } from "../../apiFunctions/baseFunctions/adminFunctions";
import { ApiHookTables } from "./apiHookTables";
import { IuserSession } from "../ddentities";



let signalRGetWorkspacesTimer: NodeJS.Timeout | undefined = undefined;


export const DataManagerPage: React.FC = () => {

    const userObj: IUserProps = useStore().userObj;
    const isUserAuthenticated = useStore().isUserAuthenticated;
    //SignalR
    const [documentsSignalRUpdateQueue, setDocumentsSignalRUpdateQueue] = useState<ISignalRUpdate[]>([]);
    const screenSize = useStore().screenSize;
    const portalConfig = useStore().portalConfig;

    const endPoints = useStore().endpoints;
    const isDark = usePersistentStore().darkMode;
    const theme = isDark ? webDarkTheme : webLightTheme;
    const navigate = useNavigate();
    const customTokens = themeToTokensObject(getCustomTheme(!isDark));
    const [selectedTab, setSelectedTab] = useState<string>("lookup");


    function getBurgerItems() {
        const items: string[] = [];
        if (!userObj)
            return items;

        items.push("Home");

        var legacyUserObj: IuserSession = JSON.parse(sessionStorage.getItem("user"));

        if (userObj.permissions.indexOf("Files management") > -1) {
            items.push("File manager");
        }

        if (userObj.permissions.indexOf("Flow access") > -1 && legacyUserObj.FeatureFlags.indexOf("$WorkFlow") > -1) {
            items.push("Flow manager");
        }
        if (userObj.permissions.indexOf("Users") > -1) {
            items.push("Users");
        }
     

        if (userObj.permissions.indexOf("Word Plugin") > -1) {
            items.push("Administration");
        }
        else if (userObj.permissions.indexOf("Download drafter addin") > -1) {
            items.push("Administration");
        }
        else if (userObj.permissions.indexOf("Portal Config") > -1) {
            items.push("Administration");
        }
        else if (userObj.permissions.indexOf("System configuration") > -1) {
            items.push("Administration");
        }
        else if (userObj.permissions.indexOf("Security Config") > -1) {
            items.push("Administration");
        }
        else if (userObj.permissions.indexOf("Reports") > -1) {
            items.push("Administration");
        }
        else if (userObj.permissions.indexOf("Style Config") > -1) {
            items.push("Administration");
        }
        else if (userObj.permissions.indexOf("Style configuration") > -1) {
            items.push("Administration");
        }


        //if (userObj.permissions.indexOf("System configuration") > -1) {
        //    items.push("Data manager");
        //}



        return items;
    }
    function doSignalRUpdate(update: ISignalRUpdate) {

        //if (update.type === SignalRUpdateType.RefreshDirEntry) {
        //    clearTimeout(signalRGetWorkspacesTimer);
        //    signalRGetWorkspacesTimer = setTimeout(() => {
        //        GetWorkspaces(userObj!).then((workspacesResult) => {
        //            let currentWorkspaceRemoved: boolean = false;
        //            if (workspacesResult.findIndex((x) => x.id === selectedWorkspaceOutOfState) === -1) {
        //                for (let i = 0; i < recentWorkspaces.length; i++) {
        //                    if (workspacesResult.findIndex((x) => x.id === recentWorkspaces[i]) > -1) {
        //                        changeWorkspace(recentWorkspaces[i]);
        //                        currentWorkspaceRemoved = true;
        //                        break;
        //                    }
        //                }
        //            }
        //            useStore.setState({ availableWorkspaces: workspacesResult });

        //            if (!currentWorkspaceRemoved) {
        //                for (let i = 0; i < update.entryIds.length; i++) {
        //                    const modified = update.entryIds[i];
        //                    //if the modified entry is in the current workspace | modified === currentworkspace, refresh the workspace
        //                    //If a file is added to the current workspace we dont know the file-id. So we refresh the entire workspace everytime
        //                    //TODO: find a better sollution
        //                    if (
        //                        modified === "ALL" ||
        //                        workspaceDirectoryOutOfState.templates.findIndex(
        //                            (x) => x.id === modified || x.parentid === modified,
        //                        ) > -1 ||
        //                        selectedWorkspaceOutOfState === modified
        //                    ) {
        //                        GetWorkSpaceById(userObj!, recentWorkspaces[0]).then((workspaceContent) => {
        //                            useStore.setState({ workspaceDirectory: workspaceContent });
        //                            //setIsFetching(false);
        //                        });
        //                        break;
        //                    }
        //                }
        //            }
        //        });
        //    }, 20000);
        //} else if (update.type === SignalRUpdateType.RefreshMyDocs) {

        //    if (selectedTabOutOfState === "mydocuments") {
        //        setDocumentsSignalRUpdateQueue((prevQueue) => [...prevQueue, update]);
        //    }
        //}
    }
    useEffect(() => {

        setEndpoints(endPoints);
        if (!isUserAuthenticated) {
            const currentpath = window.location.pathname + window.location.search;

            if (!userObj) navigate(`/user?nextUrl=${currentpath}`, { replace: true });
            else {
                navigate(`/user?nextUrl=${currentpath}`, { replace: true });
            }
        } else
        {
            if (userObj.permissions.indexOf("Access to data manager") === -1)
            {
                navigate(`/`, { replace: true });
            }
            
        }

    

    }, []);
    function getHeaderLogo(): JSX.Element[] {
        const elements: JSX.Element[] = [];

        if (!isDark) {
            if (portalConfig?.headerLogoPath)
                elements.push(
                    <img
                        key="logo"
                        style={{ height: "100%", cursor:"pointer"}}
                        src={portalConfig.headerLogoPath}
                        alt=""
                        onClick={() => {
                            document.location.href = "/";
                        }}
                    />
                );
        } else {
            if (portalConfig?.headerLogoPathDark)
                elements.push(
                    <img
                        key="logo"
                        style={{ height: "100%", cursor: "pointer" }}
                        src={portalConfig.headerLogoPathDark}
                        alt=""
                        onClick={() => {
                            document.location.href = "/";
                        }}
                    />
                );
        }

        return elements;
    }

    useEffect(() => {
        console.log("documentsSignalRUpdateQueue: ", documentsSignalRUpdateQueue);
    }, [documentsSignalRUpdateQueue]);

    const useStyles = makeStyles({
        workspaceTabItem: {
            paddingTop: "0px",
            paddingBottom: "0px",
            ...shorthands.borderRadius(tokens.borderRadiusXLarge),
            width: "100%",
            textAlign: "start",
            gridTemplateColumns: "100%",
            ":hover": {
                "& .wsInnerContainer": {
                    backgroundColor: tokens.colorNeutralBackground1Selected,
                },
            },
        },
        workspaceTabInnerContainer: {
            ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalS),
            ...shorthands.borderRadius(tokens.borderRadiusXLarge),
            display: "flex",
        },
        navItemLabel: {
            width: "calc(100% - 30px)",
            whiteSpace: "nowrap",
            ...shorthands.overflow("hidden"),
            textOverflow: "ellipsis",
        },
    });

    const styles = useStyles();

    let headerHeight = "48px";
    if (portalConfig?.headerHeight?.length) {
        headerHeight = portalConfig?.headerHeight;
        if (portalConfig?.headerHeight.indexOf("px") < 0) headerHeight += "px";
    }
    let headerBottomPadding = "0px";
    if (portalConfig?.headerBottomPadding?.length) {
        headerBottomPadding = portalConfig.headerBottomPadding;
        if (portalConfig.headerBottomPadding.indexOf("px") < 0) headerBottomPadding += "px";
    }

    return (
        <MainPageSignalRWrapper userObj={userObj} doUpdate={doSignalRUpdate}>
            <CustomFluentProvider isDark={isDark} colorConfiguration={portalConfig?.colorConfiguration}>
                <div
                    style={{
                        height: "100dvh",
                        width: "100vw",
                        background:
                            screenSize !== ScreenSize.mobile
                                ? `radial-gradient(circle at 64% 46%,${customTokens.gradientBackground1} 0,${customTokens.gradientBackground2} 57%,${customTokens.gradientBackground3} 100%)`
                                : tokens.colorNeutralBackground1,
                    }}
                >
                    <Header
                        searchValue={""}

                        leftSideItems={getHeaderLogo()}
                        rightSideItems={[

                            <>
                                {getBurgerItems().length > 0 &&
                                    <CustomMenuButton
                                        icon={<NavigationRegular />}
                                        key="burger"
                                        items={getBurgerItems()}
                                        onSelectMenuItem={(item: string) => {
                                            if (item === "Home") document.location.href = "/";
                                            if (item === "Users") document.location.href = "/usermanager";
                                            if (item === "File manager") document.location.href = "/filemanager";
                                            if (item === "Administration") document.location.href = "/admin";
                                            if (item === "Flow manager") document.location.href = "/admin?path=Flows";
                                        }}
                                    />
                                }
                            </>,
                            <ToolbarButton
                                style={{
                                    height: "48px",
                                    width: "48px",
                                    maxWidth: "48px",
                                }}
                                key="help"
                                icon={<QuestionCircleRegular />}
                                onClick={() => {
                                    getHelpPortalUrl().then((linkResult) => {
                                        let a = document.createElement("a");
                                        a.href = linkResult;
                                        a.target = "_blank";
                                        a.click();

                                        document.removeChild(a);
                                    });
                                    
                                }}
                            />,
                            <ToolbarButton
                                style={{
                                    height: "48px",
                                    width: "48px",
                                    maxWidth: "48px",
                                }}
                                key="logout"
                                onClick={() => {
                                    //on logout we nuke everything except theme and recent workspaces
                                    useStore.setState({
                                        isUserAuthenticated: false,
                                        endpoints: { authenticationAPI: "", baseAPI: "", questionnaireAPI: "" },
                                        availableWorkspaces: [],
                                        workspaceDirectory: { templates: [], clauses: [], styleConfig:null },
                                        screenSize: ScreenSize.fullScreen,
                                        portalConfig: undefined,
                                        signalRUpdate: { userDocumentUpdated: "", dirEntriesUpdated: [] },
                                    });
                                    //useStore.setState({ userObj: undefined });
                                    console.log('logging out')
                                    navigate("/logout", { replace: true });
                                }}
                                icon={<SignOutRegular />}
                            />

                        ]}
                        screenSize={screenSize}
                        showSearch={false}
                        isDark={isDark}
                        setIsDark={(dark) => {
                            usePersistentStore.setState({ darkMode: dark });
                        }}
                        portalConfig={portalConfig}
                    />

                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            paddingTop: "50px",
                            height:
                                screenSize === ScreenSize.mobile
                                    ? `calc(100% - 64px)`
                                    : `calc(100% - 50px - ${headerHeight} - ${headerBottomPadding})`,
                        }}
                    >
                        {screenSize !== ScreenSize.mobile && (
                            <div
                                style={{ width: "300px" }}

                            >

                                <TabList
                                    size={"medium"}
                                    style={{
                                        textAlign: "center",
                                        alignItems: "",
                                        width: "300px"
                                    }}
                                    selectedValue={selectedTab}
                                    onTabSelect={(_e, v) => {
                                        setSelectedTab(v.value as string);
                                    }}
                                    vertical={true}

                                > 
                                    <Tab className={styles.workspaceTabItem} value="apihooks">
                                        <div className={`${styles.workspaceTabInnerContainer} wsInnerContainer`}>

                                            <CloudDatabaseRegular
                                                style={{
                                                    fontSize: "20px",
                                                    marginRight: "6px",
                                                    color:
                                                        selectedTab === "apihooks"
                                                            ? tokens.colorNeutralForeground2BrandPressed
                                                            : "",
                                                    minWidth: "20px",
                                                }}
                                            />

                                            <div className={styles.navItemLabel}>API Hooks</div>
                                        </div>
                                    </Tab>
                                    
                                    <Tab className={styles.workspaceTabItem} value="sqltables">
                                        <div className={`${styles.workspaceTabInnerContainer} wsInnerContainer`}>
                                            <HomeDatabaseRegular
                                                style={{
                                                    fontSize: "20px",
                                                    marginRight: "6px",
                                                    color:
                                                        selectedTab === "sharepoint"
                                                            ? tokens.colorNeutralForeground2BrandPressed
                                                            : "",
                                                    minWidth: "20px",
                                                }}
                                            />

                                            <div className={styles.navItemLabel}>Azure SQL Tables</div>
                                        </div>
                                    </Tab>

                                    <Tab className={styles.workspaceTabItem} value="validations">
                                        <div className={`${styles.workspaceTabInnerContainer} wsInnerContainer`}>

                                            <JavascriptRegular
                                                style={{
                                                    fontSize: "20px",
                                                    marginRight: "6px",
                                                    color:
                                                        selectedTab === "validations"
                                                            ? tokens.colorNeutralForeground2BrandPressed
                                                            : "",
                                                    minWidth: "20px",
                                                }}
                                            />

                                            <div className={styles.navItemLabel}>Input validations</div>
                                        </div>
                                    </Tab>

                                    <Tab className={styles.workspaceTabItem} value="lookup">
                                        <div className={`${styles.workspaceTabInnerContainer} wsInnerContainer`}>
                                            <DocumentTableRegular
                                                style={{
                                                    fontSize: "20px",
                                                    marginRight: "6px",
                                                    color:
                                                        selectedTab === "lookup"
                                                            ? tokens.colorNeutralForeground2BrandPressed
                                                            : "",
                                                    minWidth: "20px",
                                                }}
                                            />

                                            <div className={styles.navItemLabel}>Lookup tables</div>
                                        </div>
                                    </Tab>
                                    <Tab className={styles.workspaceTabItem} value="phrases">
                                        <div className={`${styles.workspaceTabInnerContainer} wsInnerContainer`}>
                                            <DrawTextRegular
                                                style={{
                                                    fontSize: "20px",
                                                    marginRight: "6px",
                                                    color:
                                                        selectedTab === "phrases"
                                                            ? tokens.colorNeutralForeground2BrandPressed
                                                            : "",
                                                    minWidth: "20px",
                                                }}
                                            />

                                            <div className={styles.navItemLabel}>Phrase library</div>
                                        </div>
                                    </Tab>
                                    {isLocalOrDevHost() &&
                                        <Tab className={styles.workspaceTabItem} value="sharepoint">
                                            <div className={`${styles.workspaceTabInnerContainer} wsInnerContainer`}>
                                                <ListRegular
                                                    style={{
                                                        fontSize: "20px",
                                                        marginRight: "6px",
                                                        color:
                                                            selectedTab === "sharepoint"
                                                                ? tokens.colorNeutralForeground2BrandPressed
                                                                : "",
                                                        minWidth: "20px",
                                                    }}
                                                />

                                                <div className={styles.navItemLabel}>SharePoint Lists</div>
                                            </div>
                                        </Tab>
                                    }
                                       
                                    
                                   
                                       

                                  
                                 

                                </TabList>
                            </div>

                        )}

                        <div

                        >
                            {selectedTab === "lookup" && <LookUpTables></LookUpTables>}
                            {selectedTab === "sharepoint" && <SharePointTables></SharePointTables>}
                            {selectedTab === "phrases" && <PhraseLibrary />}
                            {selectedTab === "sqltables" && <SqlTables />}
                            {selectedTab === "validations" && <Validations />}
                            {selectedTab === "apihooks" && <ApiHookTables/>} 
                        </div>
                    </div>


                    {/*{(selectedTab === "templates" || selectedTab === "clauses") && (*/}
                    {/*    <div*/}
                    {/*        className="scroll animatePadding"*/}
                    {/*        style={{*/}
                    {/*            width: "100%",*/}
                    {/*            //paddingRight:*/}
                    {/*            //    screenSize !== ScreenSize.fullScreen ? "0px" : isCollapsed ? "68px" : "260px",*/}
                    {/*            paddingLeft: "16px",*/}
                    {/*            paddingRight: "40px",*/}
                    {/*            marginRight: "1px",*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        <div*/}
                    {/*            style={{*/}
                    {/*                //margin: "0px auto",*/}
                    {/*                //padding:*/}
                    {/*                //    screenSize !== ScreenSize.mobile*/}
                    {/*                //        ? `${tokens.spacingVerticalXXXL} 50px 0px 50px`*/}
                    {/*                //        : "30px 10px 0px 4px",*/}
                    {/*                //maxWidth: screenSize !== ScreenSize.mobile ? "1120px" : "",*/}
                    {/*                minWidth: screenSize !== ScreenSize.mobile ? "500px" : "",*/}
                    {/*                marginBottom:*/}
                    {/*                    screenSize !== ScreenSize.mobile*/}
                    {/*                        ? tokens.spacingVerticalXXXL*/}
                    {/*                        : tokens.spacingVerticalNone,*/}
                    {/*            }}*/}
                    {/*        >*/}
                    {/*            {selectedTab === "templates" && selectedWorkspace && (*/}
                    {/*                <TemplatesTab*/}
                    {/*                    selectedWorkspace={selectedWorkspace}*/}
                    {/*                    setSelectedWorkspace={(id: string) => {*/}
                    {/*                        setSelectedWorkspace(id);*/}
                    {/*                        addRecentWorkspace(id);*/}
                    {/*                    }}*/}
                    {/*                    searchValue={searchValue}*/}
                    {/*                    setSearchValue={setSearchValue}*/}
                    {/*                    isFetching={isFetching}*/}
                    {/*                    setIsFetching={setIsFetching}*/}
                    {/*                    screenSize={screenSize}*/}
                    {/*                    setShowSearchbar={setShowSearchbar}*/}
                    {/*                    forceGoToRoot={forceGoToRoot}*/}
                    {/*                />*/}
                    {/*            )}*/}

                    {/*            {selectedTab === "clauses" && (*/}
                    {/*                <ClausesTab*/}
                    {/*                    searchValue={searchValue}*/}
                    {/*                    setSearchValue={setSearchValue}*/}
                    {/*                    selectedWorkspace={selectedWorkspace}*/}
                    {/*                    setSelectedWorkspace={(id: string) => {*/}
                    {/*                        setSelectedWorkspace(id);*/}
                    {/*                        addRecentWorkspace(id);*/}
                    {/*                    }}*/}
                    {/*                    isFetching={isFetching}*/}
                    {/*                    setIsFetching={setIsFetching}*/}
                    {/*                    screenSize={screenSize}*/}
                    {/*                    setShowSearchbar={setShowSearchbar}*/}
                    {/*                    forceGoToRoot={forceGoToRoot}*/}
                    {/*                />*/}
                    {/*            )}*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*)}*/}

                    {/*{selectedTab === "mydocuments" && (*/}
                    {/*    <DocumentsTab*/}
                    {/*        searchValue={searchValue}*/}
                    {/*        setSearchValue={setSearchValue}*/}
                    {/*        isLeftPaneCollapsed={isCollapsed}*/}
                    {/*        setShowSearchbar={setShowSearchbar}*/}
                    {/*        signalRUpdateQueue={documentsSignalRUpdateQueue}*/}
                    {/*        consumeSignalRUpdate={() => {*/}
                    {/*            // Remove the first entry from the queue*/}
                    {/*            setDocumentsSignalRUpdateQueue((prevQueue) => prevQueue.slice(1));*/}
                    {/*        }}*/}
                    {/*        forceGoToRoot={forceGoToRoot}*/}
                    {/*    />*/}
                    {/*)}*/}

                    {/*{selectedTab === "workspaces" && (*/}
                    {/*    <WorkspacesTab*/}
                    {/*        selectedWorkspace={selectedWorkspace}*/}
                    {/*        setSelectedTab={ChangeActiveTab}*/}
                    {/*        setSelectedWorkspace={changeWorkspace}*/}
                    {/*        searchValue={searchValue}*/}
                    {/*    />*/}
                    {/*)}*/}
                </div>



            </CustomFluentProvider>
        </MainPageSignalRWrapper>
    );
};

