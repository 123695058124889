﻿import { GetAntiForgeryToken, getPortalConfigLogin, LoginAAD, LoginNative, VerifyLoginMethod, LoginViaEmail, LoginCheckViaEmail, AcceptLoginViaEmail, deleteCookieAuth } from '../apiFunctions/authenticateFunctions';
import { IConfigLogin, ILoginstatus } from './ddentities';
import { TextField, PrimaryButton, Stack, Link, mergeStyleSets, loadTheme, IPartialTheme, IPalette, ITextField } from '@fluentui/react';
import { AuthenticationResult, PublicClientApplication } from '@azure/msal-browser';
import useStore from "../apiFunctions/store/store";
import usePersistentStore from "../apiFunctions/store/persistentStore";
import { getEndpoints, setEndpoints, GetWorkspaces, ScreenSize, usedTexts, getTranslation } from "documentdrafter-components";
import { IUserProps } from './entitiesAPI';
import validator from "validator";
import { FC, useEffect, useState, createRef } from 'react';
import { getCustomTheme } from "documentdrafter-components";
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Divider,
    Field,
    Input,
    Spinner,
    themeToTokensObject,
    tokens,
    typographyStyles,
} from "@fluentui/react-components";
import React from 'react';
import { sendMessage } from '@microsoft/signalr/dist/esm/Utils';

interface loginProps {
    ConfigLogin: IConfigLogin;
    SendMessage: boolean;
    timeOut: boolean;
    logMeOut: any;
    windowId: string;

}
export const LogoutScreen: React.FC<loginProps> = (props) => {
    /* const [companyInfo, setCompanyInfo] = useState<IConfigLogin>(null);*/
    const theme = usePersistentStore().currentTheme;
    const isDark = usePersistentStore().darkMode;
    const portalConfig = useStore().portalConfig;
    const customTokens = themeToTokensObject(getCustomTheme(!isDark));
    const [loaderMessage, setLoaderMessage] = useState("");

    const usedConfig = props.ConfigLogin ? props.ConfigLogin.config : portalConfig;

    useEffect(() => {


        if (!props.timeOut)
            setLoaderMessage(getTranslation(usedTexts.login.youLoggedOut));

        if (props.timeOut)
            setLoaderMessage(getTranslation(usedTexts.login.sessionTimedOut));

        useStore.setState({
            isUserAuthenticated: false,
            endpoints: { authenticationAPI: "", baseAPI: "", questionnaireAPI: "" },
            availableWorkspaces: [],
            workspaceDirectory: { templates: [], clauses: [],styleConfig: null },
            screenSize: ScreenSize.fullScreen,
            //portalConfig: undefined,
            signalRUpdate: { userDocumentUpdated: "", dirEntriesUpdated: [] },
        });
        //useStore.setState({ userObj: undefined });
        deleteCookieAuth("dd.sec.session");



        if (props.SendMessage) {
            const channel = new BroadcastChannel('logout_channel');
            channel.postMessage({ action: "logout", senderId: props.windowId });
        }

        props.logMeOut();

    }, []);








    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#f3f2f1', // Replace with your theme color
        },
        box: {
            padding: '20px',
            backgroundColor: 'white', // Replace with your theme color
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
            borderRadius: '5px',
            maxWidth: '400px',
            width: '100%',
        },
        header: {
            marginBottom: '20px',
            textAlign: 'center',
        },
        logo: {
            width: '100px',
            height: '100px',
            marginBottom: '20px',
            // Add styles for your logo here
        },
    });

    let logoHeight = "40px";
    if (usedConfig?.loginLogoHeight?.length) {
        logoHeight = usedConfig.loginLogoHeight;
        if (logoHeight.indexOf("px") < 0)
            logoHeight += "px";
    }

    let logoMarginTop = "0px";
    if (usedConfig?.loginLogoTopMargin?.length) {
        logoMarginTop = usedConfig.loginLogoTopMargin;
        if (logoMarginTop.indexOf("px") < 0)
            logoMarginTop += "px";
    }

    function getLogo() {
        if (isDark) {
            if (usedConfig?.loginLogoPathDark?.length)
                return (
                    <img
                        key="logo"
                        style={{ height: logoHeight, marginTop: logoMarginTop }}
                        src={usedConfig?.loginLogoPathDark}
                        alt="slim logo" />
                )

        }
        else {
            if (usedConfig?.loginLogoPath?.length)
                return (
                    <img
                        key="logo"
                        style={{ height: logoHeight, marginTop: logoMarginTop }}
                        src={usedConfig?.loginLogoPath}
                        alt="slim logo"
                    />
                )
        }

        return null
    }

    if (!loaderMessage || !usedConfig)
    return <div></div>

    return (

        <div
            style={{
                height: "100vh",
                width: "100vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: `radial-gradient(circle at 64% 46%,${customTokens.gradientBackground1} 0,${customTokens.gradientBackground2} 57%,${customTokens.gradientBackground3} 100%)`,
            }}
        >
            <Card
                style={{
                    width: 380,
                    minHeight: 263,
                    padding: `${tokens.spacingVerticalXXL} ${tokens.spacingVerticalXXXL} ${tokens.spacingVerticalXXXL}`,
                    backgroundColor: tokens.colorNeutralBackground1,
                }}
            >
                <CardHeader
                    image={getLogo()}
                />

                {loaderMessage && (
                    <div style={{ transform: "translateY(20%)" }}>
                        {/*  <Spinner size={"medium"} />*/}

                        <div
                            style={{
                                ...typographyStyles.subtitle1,
                                textAlign: "center",
                                marginTop: tokens.spacingVerticalS,
                                marginBottom: tokens.spacingVerticalXL
                            }}
                        >
                            {loaderMessage}
                        </div>
                    </div>
                )}
                <CardFooter>
                    <div style={{ width: "100%" }}>
                        <Button
                            appearance="primary"
                            style={{ width: "100%" }}
                            onClick={() => {
                                document.location.href = "/";
                            }}
                        >
                            Login
                        </Button>
                    </div>
                </CardFooter>
            </Card>
        </div>

    );
}

