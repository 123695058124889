﻿import React from "react";
import { useState, useEffect } from "react";
import { IUserProps } from "../entitiesAPI";
import useStore from "../../apiFunctions/store/store";
import usePersistentStore from "../../apiFunctions/store/persistentStore";
import { useNavigate } from "react-router-dom";
import { getEndpoints, setEndpoints, MainPageSignalRWrapper, ISignalRUpdate, SignalRUpdateType, ISlimCachedDirectory } from "documentdrafter-components";
import { Input, Label, Link, PresenceBadge, Spinner, Title2,useId, Toast, ToastTitle, Toaster, tokens, useToastController, webDarkTheme, webLightTheme, Toolbar, ToolbarButton, TableRowId, DataGrid, DataGridHeader, DataGridRow, DataGridCell, DataGridBody, DataGridHeaderCell, createTableColumn, TableCellLayout, TableColumnDefinition, DialogContent, Dialog, DialogSurface, DialogBody, DialogActions, Button, DialogTitle, DataGridProps, PopoverSurface, Popover, PopoverTrigger,Option, Table, TableHeader, TableCell, TableRow, TableHeaderCell, TableBody, Checkbox } from "@fluentui/react-components";
import { getAuthObject } from "../../apiFunctions/authenticateFunctions";
import { GetColumnsForSharePointLists, SearchAllSharePointLists, getSharePointTenant } from "../../apiFunctions/baseFunctions/baseFunctions";
import { AddRegular, DeleteRegular, DismissRegular, EditRegular, RenameRegular, SearchRegular } from "@fluentui/react-icons";
import { IDataSet, IDataSetColumn, SharePointListsResourceMap } from "../ddentities";
import { SearchBox } from "@fluentui/react-search-preview";
import { result, set } from "lodash";
import { object } from "prop-types";
import { resolve } from "dns";

export const SharePointTables: React.FC = () => {
    const userObj: IUserProps = useStore().userObj;
    const isUserAuthenticated = useStore().isUserAuthenticated;
    //SignalR
    const [documentsSignalRUpdateQueue, setDocumentsSignalRUpdateQueue] = useState<ISignalRUpdate[]>([]);
    const screenSize = useStore().screenSize;
    const portalConfig = useStore().portalConfig;
    const [consentUrl, setConsentUrl] = React.useState<string>(null);
    const [tenantId, setTenantId] = React.useState<string>(null);
    const [stateGuid, setStateGuid] = React.useState<string>("");
    const [hasConsent, setHasConsent] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [loadingApiData, setLoadingApiData] = React.useState<boolean>(true);
    const endPoints = useStore().endpoints;
    const isDark = usePersistentStore().darkMode;
    const theme = isDark ? webDarkTheme : webLightTheme;
    const navigate = useNavigate();
    const toasterId = useId("toaster");
    const [currentdataSet, SetCurrentDataSet] = React.useState<IDataSet>(null);
    const [hideDialog, setHideDialog] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isOpenDelete, setIsOpenDelete] = React.useState(false);
    const [isOpenRename, setIsOpenRename] = React.useState(false);
    const [isOpenEdit, setIsOpenEdit] = React.useState(false);
    const [dataSets, setDataSets] = React.useState<IDataSet[]>([]);
    const [NewName, setNewName] = React.useState<string>("");
    const [items, setItems] = React.useState<Item[]>([]);
    const searchBoxRef = React.useRef<HTMLInputElement>(null);
    const [searchFieldValue, setSearchFieldValue] = useState<string>("");
    const [searchResults, setSearchResults] = useState<SharePointListsResourceMap[]>([]);
    const [showPopover, setShowPopover] = useState<boolean>(false);
    const [hasSearched, setHasSearched] = useState<boolean>(false);
    const [selectedSharePoint, setSelectedSharePoint] = useState<SharePointListsResourceMap>(null);
    const [availableColumns, setAvailableColumns] = useState<IDataSetColumn[]>([]);


    const { dispatchToast } = useToastController(toasterId);
    type NameCell = {
        label: string;
        id: string;
    };

    type LastUpdatedCell = {
        label: string;
        timestamp: number;

    }
    type LastUpdateBy = {
        label: string;
    }
    type EnabledData = {
        label: string;
        enabled: boolean;
    }
    type Item = {
        name: NameCell;
        lastUpdated: LastUpdatedCell;
        lastUpdateBy: LastUpdateBy;
        enabledDataSet: EnabledData;
    };


    async function searchSharePointLists(searchString: string): Promise<SharePointListsResourceMap[]>
    {

        var authResult = await getAuthObject();
        var results = await SearchAllSharePointLists(authResult,tenantId, searchString);

        return results;

    } 
   

    function checkSharePoint() {
        getAuthObject().then((authResult) => {
            getSharePointTenant(authResult).then((result) => {

                if (result.startsWith("https")) {

                    var currentUser = JSON.parse(sessionStorage.getItem("user"));
                    setStateGuid(currentUser.portalUser.id);
                    setTenantId(null);
                    setConsentUrl(result);
                    setHasConsent(false);
                    setLoading(false);
                }
                else {
                    setTenantId(result);
                    setConsentUrl(null);
                    setHasConsent(true);
                    setLoading(false);
                    notify("Connected to tenantid "+result);
                }

            });

        });
    }

    React.useEffect(() => {
        checkSharePoint();

    }, [])

    React.useEffect(() => {
        const fetch = async() => {
            var authObject = await getAuthObject();
            var columns = await GetColumnsForSharePointLists(authObject, tenantId, selectedSharePoint.parentReference, selectedSharePoint.id);
            setAvailableColumns(columns);

        }

        fetch();
    },[selectedSharePoint])

    const [selectedRows, setSelectedRows] = React.useState(
        new Set<TableRowId>([])
    );

    const notify = (text:string) =>
        dispatchToast(
            <Toast>
                <ToastTitle>{text}</ToastTitle>
            </Toast>,
            {  position: "top-end", intent: "success" }
        );


    const columns: TableColumnDefinition<Item>[] = [
        createTableColumn<Item>({
            columnId: "name",

            compare: (a, b) => {
                return a.name.label.localeCompare(b.name.label);
            },
            renderHeaderCell: () => {
                return "Name";



            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        <div style={{ width: "500px" }}>{item.name.label}</div>

                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<Item>({
            columnId: "lastUpdated",
            compare: (a, b) => {
                return a.lastUpdated.timestamp - b.lastUpdated.timestamp;
            },
            renderHeaderCell: () => {
                return "Last updated";
            },

            renderCell: (item) => {
                return (
                    <TableCellLayout style={{ width: "200px" }} >
                        {item.lastUpdated.label}
                    </TableCellLayout>
                )
            },
        }),
        createTableColumn<Item>({
            columnId: "lastUpdateBy",
            compare: (a, b) => {
                return a.lastUpdateBy.label.localeCompare(b.lastUpdateBy.label);
            },
            renderHeaderCell: () => {
                return "Modified by";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout >
                        {item.lastUpdateBy.label}
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<Item>({
            columnId: "enabledDataSet",
            //compare: (a, b) => {
            //    return a.enabledDataSet.enabled. localeCompare(b.enabledDataSet.enabled);
            //},
            renderHeaderCell: () => {
                return "Dataset enabled";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout >
                        {item.enabledDataSet.enabled ? "Yes" : "No"}
                    </TableCellLayout>
                );
            },
        }),

    ]




    const onSelectionChange: DataGridProps["onSelectionChange"] = (e, data) => {
        setSelectedRows(data.selectedItems);
    };
    function getPopoverWidth() {
        if (searchBoxRef.current) {
            const searchBoxWidth = searchBoxRef.current.parentElement!.offsetWidth;
            //searchBoxWidth -= 24; //current padding left & right is 12px
            return searchBoxWidth;
        }
        return "200px";
    }

    React.useEffect(() => {
        if (searchFieldValue.length > 3) {
            setLoadingApiData(true);
            searchSharePointLists(searchFieldValue).then((result) => {

                setSearchResults(result);
                setLoadingApiData(false);
                setHasSearched(true);
                if (result!= null) {
                    setShowPopover(true);
                };
                
            })
        };
        setLoadingApiData(false);


    }, [searchFieldValue]);

    function AddDialog() {
        return (

            <Dialog open={isOpen}>
                <DialogSurface  >
                    <DialogBody>
                        <DialogTitle
                            action={
                                <Button
                                    appearance="subtle"
                                    aria-label="Close"
                                    icon={<DismissRegular />}
                                    onClick={() => {
                                        setIsOpen(false);
                                    }}
                                />
                            }
                        >
                            Add new dataset
                        </DialogTitle>
                        <DialogContent>
                            <div style={{ height:"50dvh", display: "flex", flexDirection: "column" }}>
                                <Popover
                                    unstable_disableAutoFocus={true}
                                    open={hasSearched && showPopover}
                                    size={"small"}
                                    positioning={{ position: "below" }}
                                    closeOnScroll={true}
                                    onOpenChange={(_e, data) => {
                                        setShowPopover(data.open);
                                    }}
                                >
                                    <PopoverTrigger>
                                        <div tabIndex={-1}>

                                <SearchBox
                                    style={{ width: "100%", height: "32px" }}
                                    ref={searchBoxRef}
                                    value={searchFieldValue}
                                    onChange={(_e, d) => {
                                        setSearchFieldValue(d.value);
                                    }}
                                    dismiss={
                                        loadingApiData ? (
                                            <Spinner size={"tiny"} />
                                        ) : (
                                            <Button
                                                icon={<DismissRegular />}
                                                appearance="transparent"
                                                onClick={() => {
                                                    setSearchFieldValue("");
                                                }}
                                            />
                                        )
                                    }

                                            ></SearchBox>
                                            </div>
                                </PopoverTrigger>
                                <PopoverSurface style={{ width: getPopoverWidth(), paddingLeft: "0px", paddingRight: "0px" }}>
                                    {searchResults != null && <div

                                        id={`apiResultsContainer`}
                                        className="scroll"
                                        style={{ maxHeight: "275px" }}
                                    >
                                            {searchResults.map((item, index) =>
                                        (
                                            <Option
                                                    key={item.id}
                                                    tabIndex={1}
                                                    className="option"

                                                    onClick={() => {
                                                        setSelectedSharePoint(item);
                                                        setSearchResults([]);

                                                    } }
                                            >
                                                {item.displayName}
                                            </Option>


                                            


                                        )


                                        )
                                        }



                                    </div>}
                                </PopoverSurface>
                                </Popover>

                                {selectedSharePoint && <div>{selectedSharePoint.displayName} </div>}
                                <Table
                                 
                                >
                                    <TableHeader>
                                        <TableRow >
                                            <TableHeaderCell>
                                                Name
                                            </TableHeaderCell>
                                            <TableHeaderCell>
                                                Searchable
                                            </TableHeaderCell>
                                            <TableHeaderCell>
                                                In Dropdown
                                            </TableHeaderCell>

                                        </TableRow>
                                        
                                    </TableHeader>
                                    <TableBody>
                                        {availableColumns.map((item,index) => (
                                            <TableRow key={item.id}>
                                                <TableCell>
                                                    {item.name }
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox defaultChecked={item.searchable} onChange={(e, v) => { availableColumns[index].searchable = (v.checked?true:false) } } ></Checkbox>  
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox defaultChecked={item.inDropDown} onChange={(e, v) => { availableColumns[index].inDropDown = (v.checked ? true : false) }} ></Checkbox>

                                                    
                                                </TableCell>

                                            </TableRow>
                                        )) }    

                                        
                                    </TableBody>



                                </Table>

                            </div>

                          


                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                setIsOpen(false);

                            }} >Close</Button>


                            <Button appearance="primary" disabled={NewName.length === 0}
                                onClick={() => {
                                    //AddNewDataSet(NewName);
                                    setNewName("");
                                    setIsOpen(false);
                                }}
                            >
                                Ok
                            </Button>
                        </DialogActions>


                    </DialogBody>
                </DialogSurface>
            </Dialog>

        )
    }


    return (<div>


        <div
            style={{
                marginTop: tokens.spacingVerticalNone,
                marginBottom: tokens.spacingVerticalXL,
                marginLeft: "20px",
                maxWidth: "100%"

            }}
        >
            <Title2>SharePoint Lists</Title2>
        </div>

        {loading && <div>   <Spinner appearance="primary" label="Checking connection to Entra ID for the service...." /> </div>}

      
        {!loading && !hasConsent && <div>
            <h2>Consent needed</h2>
            <Label>
                Consent needed to access SharePoint Lists - please enter your Entra Tenant ID in the box below and click then link to obtain access - please be aware you need to be a Global administrator in your tenant to allow this.
            </Label>

            <div style={{ paddingTop: "14px", display: "flex", flexDirection: "column", width: "250px" }}>
                <div>Tenant Id</div>
                <Input  placeholder="Entra Tenant Id" defaultValue={""} onChange={(e, v) => {
                    setTenantId(e.currentTarget.value);
                }}
                />


            </div>

            {consentUrl!==null &&
            <Link href={consentUrl.replace("$tenantid", tenantId).replace("$guid", stateGuid)} appearance="default" >
                Click here to give consent


            </Link>}
        </div >
        }
      {/*  {hasConsent && <div>  <PresenceBadge size="medium" />  Connected to tenantid {tenantId}</div>}*/}

        {AddDialog()}

        {hasConsent &&<div> <div
            style={{
                overflowX: "hidden",

                backgroundColor: tokens.colorNeutralBackground1,
                color: tokens.colorNeutralForeground1,
                borderTopLeftRadius: tokens.borderRadiusXLarge,
                borderTopRightRadius: tokens.borderRadiusXLarge,
                marginLeft: tokens.spacingHorizontalM,
                marginRight: tokens.spacingHorizontalM,
                height: "70dvh",
                width: "80dvw"

            }}

        >
            <Toolbar aria-label="Vertical Button" >
                <ToolbarButton icon={<AddRegular></AddRegular>} onClick={() => {

                    setIsOpen(true);

                }}>New

                </ToolbarButton>
                <ToolbarButton icon={<EditRegular></EditRegular>} disabled={selectedRows.size !== 1} onClick={() => {

                    var dataSetIdArray = Array.from(selectedRows);
                    var dataSetId = dataSetIdArray[0];
                    var dataset = dataSets.find(x => x.id === dataSetId.toString());
                    SetCurrentDataSet(dataset);
                    setIsOpenEdit(true);


                }}>Edit
                </ToolbarButton>
                <ToolbarButton icon={<RenameRegular></RenameRegular>} disabled={selectedRows.size !== 1} onClick={() => {

                    var dataSetIdArray = Array.from(selectedRows);
                    var dataSetId = dataSetIdArray[0];
                    var dataset = dataSets.find(x => x.id === dataSetId.toString());
                    SetCurrentDataSet(dataset);
                    setNewName(dataset.name);
                    setIsOpenRename(true);

                }} >Rename
                </ToolbarButton>


                <ToolbarButton icon={<DeleteRegular></DeleteRegular>} disabled={selectedRows.size === 0} onClick={() => {
                    setIsOpenDelete(true);

                }}>
                    Delete
                </ToolbarButton>
            </Toolbar>
            <DataGrid
                items={items}
                columns={columns}
                sortable
                selectionMode="multiselect"

                getRowId={(item) => item.name.id}
                focusMode="composite"
                size="medium"
                selectedItems={selectedRows}
                onSelectionChange={onSelectionChange}
                style={{ overflow: "auto" }}

            >
                <DataGridHeader>
                    <DataGridRow
                        selectionCell={{
                            checkboxIndicator: { "aria-label": "Select all rows" },
                        }}
                    >
                        {({ renderHeaderCell }) => (
                            <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                        )}




                    </DataGridRow>

                </DataGridHeader>
                <DataGridBody<Item>>
                    {({ item, rowId }) => (
                        <DataGridRow<Item>
                            key={rowId}
                            selectionCell={{
                                checkboxIndicator: { "aria-label": "Select row" },
                            }}
                        >
                            {({ renderCell }) => (
                                <DataGridCell>{renderCell(item)}</DataGridCell>
                            )}
                        </DataGridRow>
                    )}
                </DataGridBody>
            </DataGrid>

        </div>
          <div
                style={{
                    height: "33px",
                    width: "80dvw",
                    backgroundColor: tokens.colorNeutralBackground1,
                    borderBottomRightRadius: tokens.borderRadiusXLarge,
                    borderBottomLeftRadius: tokens.borderRadiusXLarge,
                    marginLeft: tokens.spacingHorizontalM,
                }}
            />


        </div>
        }
        
        <Toaster toasterId={toasterId} />

    </div>
    );
}