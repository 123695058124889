﻿import { useState, useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    Theme,
    teamsLightTheme,
    teamsDarkTheme,
    teamsHighContrastTheme,
    webLightTheme,
    webDarkTheme,
    themeToTokensObject,
    Button,
    DataGrid,
    tokens,
    Menu,
    MenuItem,
    MenuTrigger,
    MenuButton,
    MenuPopover,
    MenuList,
    makeStyles,
    TabList,
    Tab,
    shorthands,
    TableColumnDefinition,
    createTableColumn,
    TableCellLayout,
    DataGridHeader,
    DataGridRow,
    DataGridHeaderCell,
    DataGridBody,
    DataGridCell,
    TableHeaderCell,
    TableRowId,
    DataGridProps,
    OverlayDrawer,
    DrawerHeader,
    DrawerHeaderTitle,
    DrawerBody,
    Drawer,
    Field,
    Input,
    Label,
    DialogContent,
    Dialog,
    DialogSurface,
    DialogBody,
    DialogTitle,
    DialogActions,
    ToolbarToggleButton,
    MessageBar,
    MessageBarBody,
    MessageBarTitle,
    MessageBarActions,
    MessageBarIntent,
    Table,
    TableHeader,
    TableRow,
    Textarea,
    TableSelectionCell,
    renderCombobox_unstable,
    TableBody,
    TableCell,
    useTableFeatures,
    useTableSelection,
    Spinner,
    DialogTrigger,
    ToolbarGroup
} from "@fluentui/react-components";
import { AddRegular, ArrowExportRegular, ArrowImportRegular, BookSearchRegular, CheckmarkRegular, DeleteRegular, Dismiss24Regular, DismissRegular, EditRegular, LockClosedRegular, LockOpenRegular, NavigationRegular, QuestionCircleRegular, RenameRegular, SignOutRegular, TableRegular } from "@fluentui/react-icons";
import { Dropdown, Option, Toolbar, ToolbarButton } from "@fluentui/react-components";
import useStore from "../../apiFunctions/store/store";
import usePersistentStore from "../../apiFunctions/store/persistentStore";
import { Header, CustomMenuButton } from "documentdrafter-components";
import { ISlimWorkspace, ScreenSize, CustomFluentProvider } from "documentdrafter-components";
import { getCustomTheme } from "documentdrafter-components";
import { getEndpoints, setEndpoints, MainPageSignalRWrapper, ISignalRUpdate, SignalRUpdateType, ISlimCachedDirectory } from "documentdrafter-components";
import "@fluentui/react/dist/css/fabric.min.css";
import React from "react";
import { IUserProps, IUserShare } from "../entitiesAPI";
import { Selection, SelectionMode } from '@fluentui/react/lib/Selection';
import { v4 as uuidv4 } from 'uuid';


import {
    DocumentTableRegular,
    ListRegular

} from "@fluentui/react-icons";
import { IDataSet, IDataSetColumn, IDataSetColumnType, apiendpointtype } from "../ddentities";
import { _copyAndSort, classNames, classNamesMessageBar, formatDate, newGuid } from "../basefunctions";
import { getAuthObject } from "../../apiFunctions/authenticateFunctions";
import { AddColumnToDataSet, AddDataSet, ChangeDataSetEnabled, ChangeEndPointTypeDataSet, DeleteColumnDataSet, DeleteDataSet, DeleteDataSetRow, EditColumnDataSet, ExportDataSet, GetAllDataSet, ImportPreviewDataSet, LoadPreviewDataSet, OpenDataSet, SearchDataSetServer, UpdateDataSet, UpdateDataSetRow, getShareUsers } from "../../apiFunctions/baseFunctions/baseFunctions";
import { Sticky, StickyPositionType } from '@fluentui/react/lib/Sticky';
import { forEach } from "lodash";
import { set } from "idb-keyval";
import { FixedSizeList as List } from 'react-window';
import { DetailsList, DetailsListLayoutMode } from "@fluentui/react/lib/DetailsList";
import InfiniteScroll from "react-infinite-scroll-component";
import { SearchBox } from "@fluentui/react-search-preview";
import { DatePicker } from "@fluentui/react-datepicker-compat";


interface IdataSetGrid {

    dataSet: IDataSet;
    refresh: any;   
    closeGrid: any;
   

}

export const LookUpTableGrid: React.FC<IdataSetGrid> = (props) => {


    const [items, setItems] = useState([]);
    const [activeDataSet, setActiveDataset] = React.useState<IDataSet>(props.dataSet);
    const [continuationToken, setContinuationToken] = React.useState(null);
    const [totalCount, setTotalCount] = React.useState<number>(0);
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(50);
    const [hasMoreData, setHasMoreData] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);

    const containerRef = React.useRef(null);
    const tableRef = React.useRef(null);


    const [activeColumn, setActiveColumn] = React.useState<IDataSetColumn>(undefined);
    const [showDeleteVerification, setShowDeleteVerification] = React.useState<boolean>(false);
    const [addColumnOpen, setAddColumnOpen] = React.useState<boolean>(false);
    const [selectedItems, setSelectedItems] = React.useState([]);
    const [showMessageBar, setShowMessageBar] = React.useState<boolean>(false);
    const [messageBarText, setMessageBarText] = React.useState<string>("");
    const [messageBarType, setMessageBarType] = React.useState<MessageBarIntent>("info");

    const [previewOpen, setPreviewOpen] = React.useState<boolean>(false);
    const [previewDataSet, setPreviewDataSet] = React.useState([]);
    const [previewDataError, setPreviewDataError] = React.useState("");
    const [previewDataSetFile, setPreviewDataSetFile] = React.useState<string>("");
    const [previewDataSetFileCount, setPreviewDataSetFileCount] = React.useState<number>(0);
    const [isImporting, setIsImporting] = React.useState(false);
    const [isExporting, setIsExporting] = React.useState(false);
    const [valueChange, setValueChange] = React.useState<IItem>(null);
    const [currentIndex, setCurrentIndex] = React.useState<number>(0);
    const [indexer, setIndexer] = React.useState<string>("");
    const [columns, setColumns] = React.useState<TableColumnDefinition<DataSetColumnsType>[]>([]);
    const [noRefreshData, setNoRefreshData] = React.useState<boolean>(false);
    const [activeInput, setactiveInput] = React.useState<HTMLInputElement>(null);
    const [multiLineEditItem, setMultiLineEditItem] = React.useState<DataSetColumnsType>(null);
    const [multiLineEditCol, setMultiLineEditCol] = React.useState<string>(null);
    const [multiLineEditValue, setMultiLineEditValue] = React.useState<string>(null);
    const [multiLineEditParent, setMultiLineEditParent] = React.useState<string>(null);
    const [updatePostData, setUpdatePostData] = React.useState<string>(null);
    const isDark = usePersistentStore().darkMode;
    const portalConfig = useStore().portalConfig;
    const theme = isDark ? webDarkTheme : webLightTheme;
    const customTokens = themeToTokensObject(getCustomTheme(!isDark));
    const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState("");


    const inputColumnRef = React.useRef<HTMLInputElement>(null);

    var dirtyRow = false;
    const [selectedRows, setSelectedRows] = React.useState(
        new Set<TableRowId>([])
    );


    function isDevelopment () :boolean {
        if (window.location.hostname.indexOf('localhost') > -1) return true;
        if (window.location.hostname.indexOf('v2020') > -1) return true;
        return false;
    }   


    interface IItem {
        id: string;
        name: string;
        value: string;
    }

   

    const itemsRef = React.useRef(items);
    const columnsRef = React.useRef(columns);

    // Update the ref whenever items changes
    React.useEffect(() => {
        itemsRef.current = items;
    }, [items]);

    React.useEffect(() => {
        columnsRef.current = columns;
    }, [columns]);


    const fetchData = async (searchTerm, page, pageSize, continuationToken, reset: boolean) => {
        setIsLoading(true);

        const queryParams = new URLSearchParams({
            searchTerm: searchTerm,
            continuationToken: continuationToken || '',
            pageSize: pageSize
        }).toString();

        console.info('fetchdata:' + page);

        try {
           

            const authResult = await getAuthObject();
            const data = await SearchDataSetServer(authResult, activeDataSet.id, queryParams);


            let tmpItems = items.filter(x => x != undefined);



            if (reset) {
                tmpItems = [];
                setItems(tmpItems);
            }
         
            setItems([...tmpItems, ...data.result]);


            //setItems(prevData => [...prevData, ...data.result

            setContinuationToken(data.continuationToken); // Adjust according to the actual response structure
            setTotalCount(data.count);

            if (data.result.length + tmpItems.length < data.count) {
                setHasMoreData(true);
            }
            else {
                setHasMoreData(false);
            }





        } catch (error) {
            console.error('Error fetching data:', error);
            // Handle any errors here
        }
    };


    type DataSetColumnsType = {
        [K in keyof typeof activeDataSet.columns]: string;
    }



    const updateCellDataMultiLine = (rowid, colid, value) => {

        setItems(prevItems => {
            var tmp = [...prevItems];
            var tmpRow = tmp.find(x => x.id == rowid);
            if (tmpRow[colid] != value) {
                //update data
                tmpRow[colid] = value;
                UpdateDataRow(activeDataSet.id, tmpRow, false).then(()=>{
                    return tmp;
                });
            }
            return tmp;
        });

    }


    const updateCellData = (element: HTMLInputElement) => {
        var rowid = element.getAttribute("data-id");
        var colid = element.getAttribute("data-col-id");
        var value = element.value;

        var tmp = items;

        setItems(prevItems => {
            var tmp = [...prevItems];
            var tmpRow = tmp.find(x => x.id == rowid);
            if (tmpRow[colid] != value) {
                //update data
                tmpRow[colid] = value;
                UpdateDataRow(activeDataSet.id, tmpRow, false).then(() => {
                    return tmp;
                });
            }
            return tmp;
        });




    }

    const updateCellDataTextArea = (element: HTMLTextAreaElement) => {
        var rowid = element.getAttribute("data-id");
        var colid = element.getAttribute("data-col-id");
        var value = element.value;

        var tmp = items;

        setItems(prevItems => {
            var tmp = [...prevItems];
            var tmpRow = tmp.find(x => x.id == rowid);
            if (tmpRow[colid] != value) {
                //update data
                tmpRow[colid] = value;
                UpdateDataRow(activeDataSet.id, tmpRow, false).then(() => {
                    return tmp;
                });
            }
            return tmp;
        });




    }

    function setDefaultValue(item: DataSetColumnsType, colId: string) {

        try {
            return item[colId].toString()
        } catch (e) {

            return "";
        }
    }

    const AfterContent = (currentItem: DataSetColumnsType, currentColId: string, currentElement: string) => {

        var colinfo = activeDataSet.columns.find(x => x.id === currentColId);
        if (colinfo === undefined) { return; }

        if (colinfo.columnType === IDataSetColumnType.SingleLineText) {
            return <span>   </span>
        }

        return (

            <div>
                <EditRegular onClick={() => {

                    setMultiLineEditParent(currentElement);
                    setMultiLineEditCol(currentColId);
                    setMultiLineEditValue(setDefaultValue(currentItem, currentColId));
                    setMultiLineEditItem(currentItem);



                }} />

            </div>
        )

    }


    const [focusedCell, setFocusedCell] = useState(null);

    <input
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        style={{ display: 'none' }}
        id="fileselect"
        onChange={onChangeFileInput} />


    function onChangeFileInput(e) {

        if (e.target.files.length) {

            var fileToLoad = e.target.files[0];

            let data = new FormData();
            data.append("file", fileToLoad);
            e.target.value = "";

            setPreviewOpen(true);
            setPreviewDataError("");
            getAuthObject().then((authResult) => {
                LoadPreviewDataSet(authResult, activeDataSet.id, data).then((dataset) => {

                    if (dataset.error != "") {


                        setPreviewDataError(dataset.error);
                    }
                    else {
                        //setFileBlob(JSON.parse(dataset.jsonData));
                        setPreviewDataSet(JSON.parse(dataset.jsonData));
                        setPreviewDataSetFile(dataset.fileName);
                        setPreviewDataSetFileCount(dataset.totalLines);

                    }
                });
            });
        }
    };



    const ImportFile = (owerWrite: boolean) => {
        getAuthObject().then((authResult) => {
            ImportPreviewDataSet(authResult, activeDataSet.id, previewDataSetFile, owerWrite).then((result) => {
                setPreviewDataSet([]);
                setPreviewDataSetFile("");
                setIsImporting(true);
                setPreviewOpen(false);
                
                
              
            });
        });
    }


    
    function ImportDialog() {

        return (

            <Dialog open={previewOpen}
            

            >
              

                <DialogSurface
                 style={{
                    maxWidth : "60vw",
                    minWidth:"60vw"
                    }}
                >
                   
                    <DialogBody>
                        <DialogTitle
                            action={
                                <Button
                                    appearance="subtle"
                                    aria-label="Close"
                                    icon={<DismissRegular />}
                                    onClick={() => {
                                        setPreviewOpen(false); setPreviewDataSet([])
                                    }}
                                />
                            }
                        >
                            {previewDataSetFileCount == undefined || previewDataSetFileCount == 0 ? "Preview import from Excel" : "Preview import from Excel (first 20 records out of " + previewDataSetFileCount + ")"}
                        </DialogTitle>
                        <DialogContent>
                            {previewDataError === "" && previewDataSet.length < 1 && <>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    Please Wait loading preview of data import.
                                    <Spinner size={"large"} />
                                </div>
                            </>}
                            {previewDataError !== "" && <div style={{ display: "flex", justifyContent: "center" }}>

                                <MessageBar intent="error">
                                    <MessageBarBody>
                                        <MessageBarTitle>Error</MessageBarTitle>
                                        {previewDataError}
                                        
                                    </MessageBarBody>

                                </MessageBar>    



                            </div>}

                            <DetailsList
                                key={"import"}
                                styles={{ root: { height: "40vh", width: "100%" } }}
                                items={previewDataSet}
                                selectionMode={SelectionMode.none}
                                layoutMode={DetailsListLayoutMode.fixedColumns}

                            >

                            </DetailsList>


                         
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                setPreviewOpen(false); setPreviewDataSet([])

                            } } >Close</Button>

                          {/*  {items.length > 0 && <Button appearance="primary"  onClick={() => { ImportFile(true); }}>Replace existing dataset</Button>}*/}
                            {previewDataError === "" && <Button appearance="primary" onClick={() => { setIsImporting(true); ImportFile(false); }}>Import</Button>}


                           
                        </DialogActions>


                    </DialogBody>
                </DialogSurface>
            </Dialog>

        )
    }



    const deletingDialog = () => {
        return (
            <Dialog
                open={showDeleteDialog}
                modalType="alert">

                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>Deleting rows - please wait</DialogTitle>
                        <DialogContent style={{ height:"50px"} } >
                           
                            <Spinner size="small" />

                        </DialogContent>
                      
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        );

    }




    const ImportingDialog = () => {
        return (
            <Dialog
                open={isImporting}
                modalType="alert">
               
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>Importing in progress</DialogTitle>
                        <DialogContent>
                            The system is currently importing the data. Please check back later.
                        </DialogContent>
                        <DialogActions>
                           
                            <Button appearance="primary"
                                onClick={() => {
                                    props.refresh()
                                    props.closeGrid(true);

                                }}

                            >Ok</Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        );
    };
    const ExportingDialog = () => {
        return (
            <Dialog
                open={isExporting}
                modalType="alert">

                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>Export in progress</DialogTitle>
                        <DialogContent>
                            The system is currently exporting the data. You will receive a email with the exported dataset.
                        </DialogContent>
                        <DialogActions>

                            <Button appearance="primary"
                                onClick={() => {
                                    props.refresh()
                                    props.closeGrid(true);

                                }}

                            >Ok</Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        );
    };

    function getColumns(dataset:IDataSet=undefined): TableColumnDefinition<DataSetColumnsType>[] {
        var outlist: TableColumnDefinition<DataSetColumnsType>[] = [];

        var _activeDataSet = activeDataSet;   

        if (dataset !== undefined) {
            _activeDataSet = dataset;
        }

        for (var ii = 0; ii < _activeDataSet.columns.length; ii++) {
            var colinfo = _activeDataSet.columns[ii];
            const colname = colinfo.name;
            const colid = colinfo.id;

            var col = createTableColumn<DataSetColumnsType>({
                columnId: colid,

                renderCell: (item) => {

                    let coltype = _activeDataSet.columns.find(x => x.id == colid).columnType;  
                    const cellId = `${item["id"].toString()}${colinfo.id.toString()}`;

                    return (

                      
                        
                        


                                <TableCellLayout style={{ width: '100%' }} >

                            {coltype === IDataSetColumnType.MultiLineText  &&
                                
                                    <Textarea
                                        defaultValue={setDefaultValue(item, colid)}
                                        appearance="outline"
                                        data-id={item["id"].toString()}
                                        data-col-id={colid}
                                        onBlur={(e) => {
                                            updateCellDataTextArea(e.currentTarget);
                                            setFocusedCell(null);

                                        }}
                                        style={{ width: `calc(${window.innerWidth - 155}px / ${activeDataSet.columns.length})` }}
                                        resize="vertical"
                                        maxLength={3000}
                                        size="small"




                                    ></Textarea>
                            }

                            {coltype === IDataSetColumnType.GuidanceNote &&

                                <Textarea
                                    defaultValue={setDefaultValue(item, colid)}
                                    appearance="outline"
                                    data-id={item["id"].toString()}
                                    data-col-id={colid}
                                    onBlur={(e) => {
                                        updateCellDataTextArea(e.currentTarget);
                                        setFocusedCell(null);

                                    }}
                                    style={{ width: `calc(${window.innerWidth - 170}px / ${activeDataSet.columns.length})` }}
                                    resize="vertical"
                                    maxLength={3000}
                                    size="small"




                                ></Textarea>
                            }
                            {coltype === IDataSetColumnType.Date &&

                                //<DatePicker
                                //    value={new Date(setDefaultValue(item, colid))}
                                //    onSelect={(e) => {
                                //        updateCellData(e.currentTarget);
                                //        setFocusedCell(null);
                                //    }}
                                //     data-id={item["id"].toString()}
                                //    data-col-id={colid}
                                //    style={{ width: `calc(${window.innerWidth - 155}px / ${activeDataSet.columns.length})` }}
                                //    resize="vertical"
                                //    size="small"
                                //>
                                
                                
                                //</DatePicker>

                                <Input
                                    defaultValue={setDefaultValue(item, colid)}
                                    appearance="outline"
                                    data-id={item["id"].toString()}
                                    data-col-id={colid}
                                    onBlur={(e) => {
                                        updateCellData(e.currentTarget);
                                        setFocusedCell(null);

                                    }}
                                    style={{ width: `calc(${window.innerWidth - 155}px / ${activeDataSet.columns.length})` }}
                                   
                                    type="date"
                                  
                                    size="small"


                                ></Input>
                            }

                            {coltype === IDataSetColumnType.Number &&

                                <Input
                                    defaultValue={setDefaultValue(item, colid)}
                                    appearance="outline"
                                    data-id={item["id"].toString()}
                                    data-col-id={colid}
                                    onBlur={(e) => {
                                        updateCellData(e.currentTarget);
                                        setFocusedCell(null);

                                    }}
                                    style={{ width: `calc(${window.innerWidth - 155}px / ${activeDataSet.columns.length})` }}
                                 
                                    size="small"
                                    type="number"



                                ></Input>
                            }


                            {coltype == IDataSetColumnType.SingleLineText && 
                            
                                        <Input
                                            defaultValue={setDefaultValue(item, colid)}
                                        style={{
                                            width: `calc(${window.innerWidth - 155}px / ${activeDataSet.columns.length})`,
                                           
                                        }} data-id={item["id"].toString()} data-col-id={colid} onBlur={(e) => { updateCellData(e.currentTarget) }}

                                            onFocus={() => setFocusedCell(cellId)}
                                            maxLength={3000}
                                    size="small"
                                     
                                            onKeyDown={(e) => {

                                                if (e.key === "Enter") {

                                                    var colIdFromData = e.currentTarget.getAttribute("data-col-id");

                                                    const { nextItem, nextColumn } = e.shiftKey
                                                        ? computePreviousFocus(item, colIdFromData)
                                                        : computeNextFocus(item, colIdFromData);

                                                    var element = document.querySelector(`[data-id='${nextItem["id"]}'][data-col-id='${nextColumn.columnId}']`);

                                                    if (element) {

                                                        element?.focus();
                                                        e.preventDefault();
                                                    }



                                                }

                                            }}



                                        ></Input>


                                
                                }








                          



                        </TableCellLayout>
                    );
                },
                renderHeaderCell: () => {
                    return colname;
                },

            })

            outlist.push(col);

        }

        //calc widt

        return outlist;
    }

    React.useEffect(() => {
        var tmpcol = getColumns();

        setColumns(tmpcol);

        if (!noRefreshData) {
            setItems([]);

            fetchData(searchValue, page, pageSize, continuationToken, true).then(() => {
                setIsLoading(false);

            });
        }
        else {
            setNoRefreshData(false);
        }

    }, [activeDataSet])

    React.useEffect(() => {


        if (searchValue.length === 0 || searchValue.length > 2) {
            fetchData(searchValue, page, pageSize, continuationToken, true).then(() => {
                setIsLoading(false);

            });
        }
        

    }, [searchValue]);


    const computeNextFocus = (currentItem, currentColumn) => {
        // Get current item index and column index
        const currentItemIndex = itemsRef.current.findIndex(item => item.id === currentItem["id"].toString());
        const currentColumnIndex = columnsRef.current.findIndex(col => col.columnId === currentColumn);

        let nextItem, nextColumn;

        // Check if it's the last column in the current item
        if (currentColumnIndex === columnsRef.current.length - 1) {
            // If it's the last item, loop back to the first item, else go to the next item
            nextItem = itemsRef.current[(currentItemIndex + 1) % itemsRef.current.length];
            nextColumn = columnsRef.current[0];
        } else {
            // Not the last column, so just move to the next column
            nextItem = currentItem;
            nextColumn = columnsRef.current[currentColumnIndex + 1];
        }

        return { nextItem, nextColumn };
    };
    const computePreviousFocus = (currentItem, currentColumn) => {
        // Get current item index and column index
        const currentItemIndex = itemsRef.current.findIndex(item => item.id === currentItem["id"]);
        const currentColumnIndex = columnsRef.current.findIndex(col => col.columnId === currentColumn);

        let prevItem, prevColumn;

        // Check if it's the first column in the current item
        if (currentColumnIndex === 0) {
            // If it's the first item, loop back to the last item, else go to the previous item
            prevItem = currentItemIndex === 0 ? itemsRef.current[items.length - 1] : itemsRef.current[currentItemIndex - 1];
            prevColumn = columnsRef.current[columnsRef.current.length - 1];
        } else {
            // Not the first column, so just move to the previous column
            prevItem = currentItem;
            prevColumn = columnsRef.current[currentColumnIndex - 1];
        }

        return { nextItem: prevItem, nextColumn: prevColumn };
    };



    const ExportCurrentDataSet = async (dataset: IDataSet) => {

        var authObj = await getAuthObject();
        var result = await ExportDataSet(authObj, dataset.id);

        setShowMessageBar(true);
        setMessageBarType("success");
        setMessageBarText("Export started - you will receive the data in a Excel file on your email");



       
    }


    const UpdateEnabledOnDataSet = (dataSet: IDataSet) => {
        getAuthObject().then((authResult) => {
            ChangeDataSetEnabled(authResult, dataSet.id, dataSet.enabled).then((newDataSet) => {
                setShowMessageBar(true);
                setMessageBarType("success");
                setMessageBarText(newDataSet.enabled ? "Table now enabled" : "Table now disabled");
                setActiveDataset(newDataSet);

            })
                .catch(error => {
                    setShowMessageBar(true);
                    setMessageBarType("error");
                    setMessageBarText("Data table could not be enabled : " + error);
                    var tmpset = dataSet;
                    tmpset.enabled = false;
                    setActiveDataset(tmpset);

                });
        });
    }

    const UpdateEndPointTypeDataSet = (dataSet: IDataSet) => {
        getAuthObject().then((authResult) => {
            ChangeEndPointTypeDataSet(authResult, dataSet.id, dataSet.endPointType).then((newDataSet) => {
                setShowMessageBar(true);
                setMessageBarType("success");
                setMessageBarText(newDataSet.endPointType === apiendpointtype.list ? "Drop down now enabled" : "Search now enabled");
                setActiveDataset(newDataSet);

            })
                .catch(error => {
                    setShowMessageBar(true);
                    setMessageBarType("error");
                    setMessageBarText("Data table could not be enabled : " + error);
                    var tmpset = dataSet;
                    tmpset.enabled = false;
                    setActiveDataset(tmpset);

                });
        });
    }

    const UpdateDataRow = (dataSetId: string, data: any, isNew: boolean): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            getAuthObject().then((authResult) => {
                UpdateDataSetRow(authResult, dataSetId, data, isNew).then((Ok) => {
                    dirtyRow = false;
                    resolve(Ok);
                }).catch((error) => {
                    reject(error);
                });
            }).catch((error) => {
                reject(error);
            });
        });
    }

    const deleteDataRow = async (authResult: IUserProps, dataSetId: string, rowId: string) => {

        await DeleteDataSetRow(authResult, dataSetId, rowId);
        


    }
    const DeleteSelectedRows = () => {
        return getAuthObject().then(async (authResult) => {
            var dataSetIdArray = Array.from(rows.filter(x => x.selected));

            var tmp = items;

            // Use map to create an array of promises for each deletion
            const deletePromises = dataSetIdArray.map(async (rowid) => {
                await deleteDataRow(authResult, activeDataSet.id, rowid.item.id);
                return rowid.item.id; // Return the id of the deleted item
            });

            // Wait for all delete operations to complete
            const deletedIds = await Promise.all(deletePromises);

            if (dataSetIdArray.length > 20) {
                setShowDeleteDialog(false);
                
                fetchData("", page, pageSize, continuationToken, true).then(() => {
                    setIsLoading(false);
                    clearRows(({} as unknown) as React.SyntheticEvent);
                    setSearchValue("");
                    
                });
            }
            else {
                // Filter out the deleted items
                tmp = tmp.filter(item => !deletedIds.includes(item.id));
                clearRows(({} as unknown) as React.SyntheticEvent);
                // Update the state after all deletions have been processed
                setItems(tmp);
            }
            
            
        });
    };

    function minimalGuid(): string {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }

    function getColumnPopupButtonDisabled() {

        if (activeColumn === undefined) {
            return true;
        }
        if (activeColumn.name.length < 1) {
            return true;
        }
        if (activeDataSet.columns.findIndex(x => x.name === activeColumn.name && x.id !== activeColumn.id) > -1) {

            return true;
        }
        if (activeColumn.id !== undefined) {

            let currentColumn = activeDataSet.columns.find(x => x.id === activeColumn.id);
            if (currentColumn === undefined) { return true; }
            if (currentColumn.name !== activeColumn.name && currentColumn.columnType !== activeColumn.columnType) { return false }


        }

        return false;
    }

    const AddColumn = () => {

        let newColumn: IDataSetColumn = JSON.parse(JSON.stringify(activeColumn));
        let newColumnIndex = newColumn.index;
        if (newColumnIndex > -1)
            newColumn.index = -1;

        setAddColumnOpen(false);
        getAuthObject().then((authResult) => {
            AddColumnToDataSet(authResult, activeDataSet.id, newColumn).then((dataset) => {
                props.refresh();
                setActiveDataset({ ...activeDataSet, ...dataset });
                var tmpcol = getColumns();
                setColumns(tmpcol);
                setSearchValue("")
                fetchData('', page, pageSize, continuationToken, true).then(() => {
                    setIsLoading(false);
                });


            });
            //gridInstance.refreshColumns();
        });
    }
    const EditColumn = () => {
        getAuthObject().then((authResult) => {
            EditColumnDataSet(authResult, activeDataSet.id, activeColumn).then((dataset) => {
                props.refresh();
                setActiveDataset({ ...activeDataSet, ...dataset });
                var tmpcol = getColumns();
                setColumns(tmpcol);

            });

        });
    }

    const bottomBoundaryRef = React.useRef(null);
    const dataGridRef = React.useRef(null);
    
    //Column operations 
    const DeleteColumn = (activeCol) => {
        getAuthObject().then((authResult) => {
            DeleteColumnDataSet(authResult, activeDataSet.id, activeCol).then((dataset) => {
                props.refresh();
                setActiveDataset({ ...activeDataSet, ...dataset });
                var tmpcol = getColumns(dataset);
                setColumns(tmpcol);

            });
        });
    }

    const EditColumnDirect = (currentColumn: IDataSetColumn) => {
        getAuthObject().then((authResult) => {
            EditColumnDataSet(authResult, activeDataSet.id, currentColumn).then((dataset) => {
                props.refresh();
                setNoRefreshData(true);
                setActiveDataset(dataset);

            });

        });
    }

   
    const useStyles = makeStyles({
        headerContainer:
        {
            display: 'flex',
            justifyContent: 'space-between',

        },
        headerColumn1: {
            marginRight: 'auto'
        },
        tableHeader:
            {            
                position: "sticky",
                top: 0,
            zIndex: 1,
            backgroundColor: tokens.colorNeutralBackground1
        }
        ,
        toolbar: {
            justifyContent: "space-between",
        },


    })
    const styles = useStyles();

    function Header() {
        return (
            <div className={styles.headerContainer}
                style={{
                    marginLeft: tokens.spacingHorizontalXL,
                    marginRight: tokens.spacingHorizontalXL,
                    marginTop: tokens.spacingVerticalXXXL,
                    marginBottom: tokens.spacingVerticalXL,
                }}

            >
                <div
                    
                    className={styles.headerColumn1}>


                    <h3>Editing {props.dataSet.name}</h3>
                </div>


                <Toolbar
                    

                >
                    <ToolbarButton

                        icon={<DismissRegular />} onClick={() => {

                            props.closeGrid(true);


                        }} />
                </Toolbar>
            </div>

        )
    }

    function MultiLineEditItem() {
        return (

            <Dialog open={multiLineEditItem != null}>
                <DialogSurface  >
                    <DialogBody>
                        <DialogTitle
                            action={
                                <Button
                                    appearance="subtle"
                                    aria-label="Close"
                                    icon={<DismissRegular />}
                                    onClick={() => {
                                        setMultiLineEditItem(null);
                                    }}
                                />
                            }
                        >
                            Edit multi line text
                        </DialogTitle>
                        <DialogContent>
                            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                                <textarea style={{ height: '200px' }} defaultValue={multiLineEditValue}
                                    onChange={(e) => {
                                        setMultiLineEditValue(e.currentTarget.value);

                                    }}

                                >



                                </textarea>



                            </div>

                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={async () => {
                                    setMultiLineEditItem(null);

                                    
                                    await updateCellDataMultiLine(multiLineEditItem["id"], multiLineEditCol, multiLineEditValue);
                                    setUpdatePostData(`${multiLineEditItem["id"]}:${multiLineEditCol}:${multiLineEditValue}`);


                                }}
                            >
                                Ok
                            </Button>
                        </DialogActions>


                    </DialogBody>
                </DialogSurface>
            </Dialog>

        )
    }

    function DeleteDialog() {

        return (

            <Dialog open={showDeleteVerification}>
                <DialogSurface  >
                    <DialogBody>
                        <DialogTitle
                            action={
                                <Button
                                    appearance="subtle"
                                    aria-label="Close"
                                    icon={<DismissRegular />}
                                    onClick={() => {
                                        setShowDeleteVerification(false);
                                    }}
                                />
                            }
                        >
                            Delete column
                        </DialogTitle>
                        <DialogContent>
                            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                                <div>{"Column" + " " + activeColumn?.name}</div>
                                <div>{"Deleting this column will also delete all data in it."}</div>
                            </div>

                        </DialogContent>
                        <DialogActions>
                            <Button disabled={getColumnPopupButtonDisabled()}
                                onClick={() => {
                                    DeleteColumn(activeColumn); setShowDeleteVerification(false);

                                }}
                            >
                                Delete
                            </Button>
                        </DialogActions>


                    </DialogBody>
                </DialogSurface>
            </Dialog>



        )
    }

    function getColumnType(colType: IDataSetColumnType) {
        if (colType === IDataSetColumnType.SingleLineText) 
            return "Single line text";   
        if (colType === IDataSetColumnType.MultiLineText) 
            return "Multi line text";
        if (colType === IDataSetColumnType.Date)
            return "Date";
        if (colType === IDataSetColumnType.Number)
            return "Number";
        if (colType === IDataSetColumnType.GuidanceNote)
            return "Guidance note";
    }

    function stringToColumnType(colType: string) {
        if (colType === "Single line text") return IDataSetColumnType.SingleLineText;
        if (colType === "Multi line text") return IDataSetColumnType.MultiLineText;
        if (colType === "Date") return IDataSetColumnType.Date;
        if (colType === "Number") return IDataSetColumnType.Number;
        if (colType === "Guidance note") return IDataSetColumnType.GuidanceNote;
    }
    function AddColumnDialog() {

        return (

            <Dialog open={addColumnOpen}>
                <DialogSurface  >
                    <DialogBody>
                        <DialogTitle
                            action={
                                <Button
                                    appearance="subtle"
                                    aria-label="Close"
                                    icon={<DismissRegular />}
                                    onClick={() => {
                                        setAddColumnOpen(false);
                                    }}
                                />
                            }
                        >
                            {(activeColumn == undefined ? '' : activeColumn.id !== undefined ? 'Edit column' : activeDataSet.columns.length > 0 ? 'Add column' : 'Add first column')}
                        </DialogTitle>
                        <DialogContent>
                            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                                <Input

                                    placeholder="Column name"
                                    defaultValue={(activeColumn == undefined ? '' : activeColumn.name)}
                                    ref={inputColumnRef}
                                    onChange={(e, v) => {
                                        let localColumn = JSON.parse(JSON.stringify(activeColumn));
                                        localColumn.name = v.value;
                                        setActiveColumn(localColumn);

                                    }}

                                />
                                <Dropdown
                                    placeholder='Type of data field'

                                    defaultValue={activeColumn == undefined ? "Single line text" : getColumnType(activeColumn.columnType)}
                                    defaultSelectedOptions={activeColumn == undefined ? ["0"] : [activeColumn.columnType.toString()]}
                                    onOptionSelect={(item, v) => {
                                        activeColumn.columnType = stringToColumnType(v.optionValue);
                                        let localColumn = JSON.parse(JSON.stringify(activeColumn));
                                        localColumn.columnType = v.optionValue;
                                        setActiveColumn(localColumn);

                                    }}
                                    disabled={activeColumn !== undefined && (activeColumn.columnType === IDataSetColumnType.Date || activeColumn.columnType === IDataSetColumnType.Number)  }


                                >
                                    <Option text="Single line text" value={IDataSetColumnType.SingleLineText.toString()}>Single line text</Option>
                                    <Option text="Multi line text" value={IDataSetColumnType.MultiLineText.toString()} >Multi line text</Option>
                                    
                                    {activeColumn !== undefined && activeColumn.id === undefined && <Option text="Date" value={IDataSetColumnType.Date.toString()} >Date</Option>}
                                    {activeColumn !== undefined && activeColumn.id === undefined && <Option text="Number" value={IDataSetColumnType.Number.toString()} >Number</Option>}

                                    {activeDataSet != undefined && activeDataSet.columns.findIndex(x => x.columnType === IDataSetColumnType.GuidanceNote) === -1 && activeColumn!== undefined &&  activeColumn.id === undefined && < Option text="Guidance note" value={IDataSetColumnType.GuidanceNote.toString()} >Guidance note</Option>} 
                                    

                                </Dropdown>
                            </div>

                        </DialogContent>
                        <DialogActions>
                            <Button disabled={getColumnPopupButtonDisabled()}
                                onClick={() => {
                                    if (activeColumn != undefined) {
                                        if (activeColumn.id === undefined) { AddColumn(); } else { EditColumn(); }

                                    }

                                    setAddColumnOpen(false); /*setActiveColumn(activeColumn);*/
                                }}
                            >
                                Ok
                            </Button>
                        </DialogActions>


                    </DialogBody>
                </DialogSurface>
            </Dialog>


        )
    }

    function ShowMessageDialog() {

        return (


            <MessageBar key={messageBarType} intent={messageBarType}   >
                <MessageBarBody>
                    <MessageBarTitle>{messageBarText}</MessageBarTitle>


                </MessageBarBody>
                <MessageBarActions
                    containerAction={
                        <Button
                            aria-label="dismiss"
                            appearance="transparent"
                            icon={<DismissRegular />}
                            onClick={() => setShowMessageBar(false)}
                        />
                    }
                >
                </MessageBarActions>
            </MessageBar>


        )
    }

    const {
        getRows,
        selection: {
            allRowsSelected,
            someRowsSelected,
            toggleAllRows,
            toggleRow,
            isRowSelected,
            clearRows
        },
    } = useTableFeatures(
        {
            columns,
            items,
           
        },
        [
            useTableSelection({
                selectionMode: "multiselect",
                defaultSelectedItems: []
                
            }),
        ]
    );

    const rows = getRows((row) => {
        
        const selected = isRowSelected(row.rowId);
        


        return {
            ...row,
            onClick: (e: React.MouseEvent) => toggleRow(e, row.rowId),
            onKeyDown: (e: React.KeyboardEvent) => {
                if (e.key === " ") {
                    //e.preventDefault();
                    //toggleRow(e, row.item.id);
                }
            },
            selected,
            appearance: selected ? ("brand" as const) : ("none" as const),
        };
    });

    const toggleAllKeydown = React.useCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.key === " ") {
                //toggleAllRows(e);
                //e.preventDefault();
            }
        },
        [toggleAllRows]
    );

    const [listHeight, setListHeight] = useState(window.innerHeight * 0.7);
    useEffect(() => {
        const handleResize = () => {
            setListHeight(window.innerHeight * 0.7); // Update the height to be 70% of the viewport height
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    React.useEffect(() => {
        if (addColumnOpen && inputColumnRef.current) {
            inputColumnRef.current.focus();
        }
    }, [addColumnOpen]);


    React.useEffect(() => {


    }, [showDeleteDialog])

    function simpleFetch()
    {
        console.log('simple fetch')

        fetchData(searchValue, page, pageSize, continuationToken, false).then(() => {

            console.log('data');

        })


    }


    function disableForeignKey(columnId) {

        var column = activeDataSet.columns.find(x => x.id === columnId);    

        if (column.columnType === IDataSetColumnType.GuidanceNote|| column.columnType === IDataSetColumnType.Date|| column.columnType === IDataSetColumnType.MultiLineText) {
            return true;
        }

        //check if another column is a foreign key
        if (activeDataSet.columns.filter(x => x.isForeignKey && x.id !== column.id).length > 0) {
            return true;
        }


        return false;

    }


    return (

        <CustomFluentProvider isDark={isDark} colorConfiguration={portalConfig?.colorConfiguration}>
            <div
                style={{
                    height: "100vh",
                    width: "100vw",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex:9999,
                    background:
                        `radial-gradient(circle at 64% 46%,${customTokens.gradientBackground1} 0,${customTokens.gradientBackground2} 57%,${customTokens.gradientBackground3} 100%)`

                }}
            >
                {showMessageBar && ShowMessageDialog()}
                {Header()}
                {MultiLineEditItem()}
                {DeleteDialog()}
                {deletingDialog()}
                {AddColumnDialog()}
                {ImportingDialog()}
                {ImportDialog()}
                {ExportingDialog()}
                <input
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    style={{ display: 'none' }}
                    id="fileselect"
                    onChange={onChangeFileInput} />

                <div
                    style={{
                        overflowX: "hidden",

                        backgroundColor: tokens.colorNeutralBackground1,
                        color: tokens.colorNeutralForeground1,
                        borderTopLeftRadius: tokens.borderRadiusXLarge,
                        borderTopRightRadius: tokens.borderRadiusXLarge,
                        marginLeft: tokens.spacingHorizontalL,
                        marginRight: tokens.spacingHorizontalL,
                        height: "80dvh",
                        width: "97vw"


                    }}>

                    <Toolbar aria-label="Vertical Button" className={useStyles().toolbar }  >
                    <ToolbarGroup>
                        <ToolbarButton icon={<AddRegular></AddRegular>} onClick={() => {

                            var tmpid: string = minimalGuid();

                            var newRow = {
                                id: tmpid,
                                DataSetId: activeDataSet.id,
                                Type: 'DataSetRow'
                            };

                            for (var i = 0; i < activeDataSet.columns.length; i++) {
                                newRow[activeDataSet.columns[i].id] = '';
                            }

                            UpdateDataRow(activeDataSet.id, newRow, true);

                            setItems(prevData => [newRow, ...prevData]);


                        }}>Add Row
                        </ToolbarButton>
                        <ToolbarButton icon={<AddRegular></AddRegular>} onClick={() => {

                            var activeCol: IDataSetColumn = {
                                id: undefined, columnType: 0, index: -1, name: "", edit: false, searchable: false, inDropDown: false, autoFill: false
                            }
                            setActiveColumn(activeCol);
                            setAddColumnOpen(true)


                        }}>Add Column
                        </ToolbarButton>
                        <ToolbarButton disabled={rows.filter(x => x.selected).length==0} icon={<DeleteRegular></DeleteRegular>} onClick={async () => {

                            var dataSetIdArray = Array.from(rows.filter(x => x.selected));

                            if (dataSetIdArray.length > 20)
                            {


                                if (!window.confirm("Are you sure you want to delete " + dataSetIdArray.length + " rows?"))
                                {


                                    return;
                                }
                                setShowDeleteDialog(true);
                            }
                            await DeleteSelectedRows();


                        }}>Delete Row
                        </ToolbarButton>





                        <ToolbarButton icon={<ArrowExportRegular></ArrowExportRegular>} onClick={async () => {
                            
                            await ExportCurrentDataSet(activeDataSet);

                        }}>Export Excel
                        </ToolbarButton>
                        <ToolbarButton icon={<ArrowImportRegular />} onClick={() => {
                            document.getElementById("fileselect").click();


                        }}>Import Excel
                        </ToolbarButton>
                        <ToolbarButton icon={activeDataSet.enabled ? <LockOpenRegular /> : <LockClosedRegular />} onClick={() => {
                            var dataSet = activeDataSet;
                            dataSet.enabled = !dataSet.enabled;
                            UpdateEnabledOnDataSet(dataSet);


                        }}>{activeDataSet.enabled ? "Disable" : "Enable"}
                        </ToolbarButton>

                        <ToolbarButton disabled={activeDataSet.enabled} icon={activeDataSet.endPointType === apiendpointtype.list ? <TableRegular /> : <BookSearchRegular />} onClick={() => {
                            var dataSet = activeDataSet;
                            if (dataSet.endPointType == apiendpointtype.list) {
                                dataSet.endPointType = apiendpointtype.selector;
                            }
                            else {
                                dataSet.endPointType = apiendpointtype.list;
                            }

                            UpdateEndPointTypeDataSet(dataSet);

                        }}>Display model
                        </ToolbarButton>
                        </ToolbarGroup >

                        {/*<ToolbarToggleButton*/}
                        {/*    aria-label="Underline"*/}
                        {/*    icon={<DocumentTableRegular />}*/}
                        {/*    name="textOptions"*/}
                        {/*    value="underline"*/}

                        {/*/>Drop down in questionnaire*/}
                        <ToolbarGroup>
                     
                            <SearchBox
                                    
                                    appearance="outline"
                                    style={{ width: "20vw", marginRight:"10px" } }
                                    placeholder={"Filter rows min. 3 characters"}
                                    value={searchValue}
                                    dismiss={
                                        <Button
                                            icon={<DismissRegular />}
                                            appearance="transparent"
                                            onClick={() => {
                                               setSearchValue("");
                                            }}
                                        />
                                    }
                                onChange={(e) => {
                                   
                                        setSearchValue(e.target.value)
                            }}

                            />
                     
                        </ToolbarGroup>

                    </Toolbar>
                   {/* {items.length} {hasMoreData?"HasMoreData":"NoMoreData"}*/}
                    <InfiniteScroll
                        height="74dvh"
                        dataLength={items.length}
                        next={() => fetchData(searchValue, page, pageSize, continuationToken, false)}

                        hasMore={hasMoreData}

                        loader={searchValue.length>2?"": <h5>Loading ... </h5>}
                        endMessage={items.length > 50 ?
                            <p style={{ textAlign: 'center' }}>
                                <b>Yay! You have seen it all</b>
                            </p> : ""}

                    >
                     
                    <Table
                        noNativeElements
                       
                    >
                        <TableHeader
                            className={styles.tableHeader}
                        >
                            <TableRow>
                                <TableSelectionCell
                                    checked={
                                        allRowsSelected ? true : someRowsSelected ? "mixed" : false
                                    }
                                    onClick={toggleAllRows}
                                    onKeyDown={toggleAllKeydown}
                                    checkboxIndicator={{ "aria-label": "Select all rows " }}
                                />


                                {columns.map((column) => (
                                <Menu openOnContext key={column.columnId}>
                                    <MenuTrigger>
                                        <TableHeaderCell
                                            key={column.columnId}
                                        >
                                            {column.renderHeaderCell()}


                                        </TableHeaderCell>
                                    </MenuTrigger>
                                
                                    <MenuPopover>
                                        <MenuList>
                                            <MenuItem icon={<EditRegular />} onClick={() => {

                                                setActiveColumn(activeDataSet.columns.find(x => x.id == column.columnId));

                                                setAddColumnOpen(true);
                                            }

                                            } >
                                                Edit Column
                                            </MenuItem>
                                            <MenuItem icon={<DeleteRegular />} onClick={() => {
                                                var activeCol: IDataSetColumn = activeDataSet.columns.find(x => x.id === column.columnId);

                                                if (activeCol !== undefined) {

                                                    setActiveColumn(activeCol);
                                                    setShowDeleteVerification(true);

                                                }

                                            }}>
                                                Delete Column
                                            </MenuItem>
                                                <MenuItem disabled={activeDataSet.endPointType === apiendpointtype.list || activeDataSet.columns.find(x => x.id == column.columnId).columnType == IDataSetColumnType.GuidanceNote || activeDataSet.columns.find(x => x.id == column.columnId).columnType == IDataSetColumnType.Date || activeDataSet.columns.find(x => x.id == column.columnId).columnType == IDataSetColumnType.Number}
                                                    icon={activeDataSet.columns.find(x => x.id == column.columnId).searchable ? <CheckmarkRegular /> : ""}
                                                onClick={() => {
                                                    var activeCol: IDataSetColumn = activeDataSet.columns.find(x => x.id === column.columnId);

                                                    activeCol.searchable = !activeCol.searchable;
                                                    EditColumnDirect(activeCol);

                                                }}

                                            >
                                                Searchable in search field
                                                </MenuItem>

                                                <MenuItem
                                                    disabled={activeDataSet.columns.find(x => x.id == column.columnId).columnType == IDataSetColumnType.GuidanceNote || activeDataSet.columns.find(x => x.id == column.columnId).columnType == IDataSetColumnType.Date || activeDataSet.columns.find(x => x.id == column.columnId).columnType == IDataSetColumnType.Number}

                                                    icon={activeDataSet.columns.find(x => x.id == column.columnId).inDropDown ? <CheckmarkRegular /> : ""}

                                                onClick={() => {
                                                    var activeCol: IDataSetColumn = activeDataSet.columns.find(x => x.id === column.columnId);

                                                    activeCol.inDropDown = !activeCol.inDropDown;
                                                    EditColumnDirect(activeCol);

                                                }}

                                            >
                                            Show in Search Field drop-down



                                            </MenuItem>
                                                <MenuItem

                                                    disabled={activeDataSet.columns.find(x => x.id == column.columnId).columnType == IDataSetColumnType.GuidanceNote || activeDataSet.columns.find(x => x.id == column.columnId).columnType == IDataSetColumnType.Date || activeDataSet.columns.find(x => x.id == column.columnId).columnType == IDataSetColumnType.Number }

                                                    icon={activeDataSet.columns.find(x => x.id == column.columnId).autoFill ? <CheckmarkRegular /> : ""}

                                                onClick={() => {
                                                    var activeCol: IDataSetColumn = activeDataSet.columns.find(x => x.id === column.columnId);

                                                    activeCol.autoFill = !activeCol.autoFill;
                                                    EditColumnDirect(activeCol);

                                                }}

                                            >
                                                    Only predefined (autofill)
                                                </MenuItem>

                                                <MenuItem

                                                    disabled={disableForeignKey(column.columnId)}

                                                    icon={activeDataSet.columns.find(x => x.id == column.columnId).isForeignKey ? <CheckmarkRegular /> : ""}

                                                    onClick={() => {
                                                        var activeCol: IDataSetColumn = activeDataSet.columns.find(x => x.id === column.columnId);

                                                        activeCol.isForeignKey = !activeCol.isForeignKey;
                                                        EditColumnDirect(activeCol);

                                                    }}

                                                >
                                                    Foreign key (API update)
                                                </MenuItem>

                                        </MenuList>
                                    </MenuPopover>
                                    </Menu>
                                    
                               
                            ))}
                        </TableRow>
                        </TableHeader>

                     

                        <TableBody
                          

                        >
                          
                           

                            {rows.map(({ item, selected, onClick, onKeyDown, appearance }) => (
                                <TableRow
                                    key={item.id}
                                    aria-selected={selected}
                                    appearance={appearance}
                                    data-itemID={item.id}
                                     //onClick={onClick}
                                    //onKeyDown={onKeyDown}
                                    style={{ height: (activeDataSet.columns.findIndex(x=>x.columnType== IDataSetColumnType.MultiLineText)>-1)?"50px":"40px" } }
                                >
                                    <TableSelectionCell
                                        checked={selected}
                                        onClick={onClick}
                                        checkboxIndicator={{ "aria-label": "Select row" }}
                                    />


                                    {columns.map((column) => (


                                        <TableCell key={column.columnId}>



                                            {column.renderCell(item)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                          
                        </TableBody>
                     
                       
                        </Table>
                    </InfiniteScroll>      
                  
                    
                  
                </div>
                <div
                    style={{
                        height: "33px",
                        width: "97vw",
                        backgroundColor: tokens.colorNeutralBackground1,
                        borderBottomRightRadius: tokens.borderRadiusXLarge,
                        borderBottomLeftRadius: tokens.borderRadiusXLarge,
                        marginLeft: tokens.spacingHorizontalL,
                        marginRight: tokens.spacingHorizontalL,
                    }}
                />

            </div>

        </CustomFluentProvider>

    )
}