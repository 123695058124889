﻿import { useState, useEffect, useLayoutEffect } from "react";

import {
    Theme,
    teamsLightTheme,
    teamsDarkTheme,
    teamsHighContrastTheme,
    webLightTheme,
    webDarkTheme,
    themeToTokensObject,
    Button,
    DataGrid,
    tokens,
    Menu,
    MenuItem,
    MenuTrigger,
    MenuButton,
    MenuPopover,
    MenuList,
    makeStyles,
    TabList,
    Tab,
    shorthands,
    TableColumnDefinition,
    createTableColumn,
    TableCellLayout,
    DataGridHeader,
    DataGridRow,
    DataGridHeaderCell,
    DataGridBody,
    DataGridCell,
    TableHeaderCell,
    TableRowId,
    DataGridProps,
    OverlayDrawer,
    DrawerHeader,
    DrawerHeaderTitle,
    DrawerBody,
    Drawer,
    Field,
    Input,
    Label,
    DialogContent,
    Dialog,
    DialogSurface,
    DialogBody,
    DialogTitle,
    DialogActions,
    ToolbarToggleButton,
    MessageBar,
    MessageBarBody,
    MessageBarTitle,
    MessageBarActions,
    MessageBarIntent,
    Table,
    TableHeader,
    TableRow,
    Textarea,
    TableSelectionCell,
    renderCombobox_unstable,
    TableBody,
    TableCell,
    useTableFeatures,
    useTableSelection,
    Spinner,
    DialogTrigger
} from "@fluentui/react-components";
import { AddRegular, ArrowExportRegular, ArrowImportRegular, BookSearchRegular, CheckmarkRegular, DeleteRegular, Dismiss24Regular, DismissRegular, EditRegular, LockClosedRegular, LockOpenRegular, NavigationRegular, QuestionCircleRegular, RenameRegular, SignOutRegular, TableRegular } from "@fluentui/react-icons";
import { Dropdown, Option, Toolbar, ToolbarButton } from "@fluentui/react-components";
import useStore from "../../apiFunctions/store/store";
import usePersistentStore from "../../apiFunctions/store/persistentStore";

import { ISlimWorkspace, ScreenSize, CustomFluentProvider } from "documentdrafter-components";
import { getCustomTheme } from "documentdrafter-components";
import "@fluentui/react/dist/css/fabric.min.css";
import React from "react";
import { IUserProps, IUserShare } from "../entitiesAPI";


import {
    DocumentTableRegular,
    ListRegular

} from "@fluentui/react-icons";
import { IDataSet, IDataSetColumn, IDataSetColumnType, IPhraseLibrary, apiendpointtype } from "../ddentities";
import { _copyAndSort, classNames, classNamesMessageBar, formatDate, newGuid } from "../basefunctions";
import { getAuthObject } from "../../apiFunctions/authenticateFunctions";
import { AddColumnToDataSet, AddDataSet, AddPhraseLibRow, ChangePhraseLibraryEnabled, ChangeEndPointTypeDataSet, DeleteColumnDataSet, DeleteDataSet, DeleteDataSetRow, DeletePhraseLibRow, EditColumnDataSet, ExportDataSet, GetAllDataSet, ImportPreviewDataSet, ImportPreviewPhraseLib, LoadPreviewDataSet, LoadPreviewPhraseLib, OpenDataSet, SearchDataSetServer, SearchPhraseLibraries, UpdateDataSet, UpdateDataSetRow, UpdatePhraseLibRow, getShareUsers, ExportPhraseLib, UpdatePhraseLibRowNote } from "../../apiFunctions/baseFunctions/baseFunctions";
import { DetailsList, DetailsListLayoutMode } from "@fluentui/react/lib/DetailsList";
import InfiniteScroll from "react-infinite-scroll-component";



interface IPhraseLibraryGrid
{

    phraseLibrary: IPhraseLibrary;
    refresh: any;
    closeGrid: any;


}

export const PhraseLibraryGrid: React.FC<IPhraseLibraryGrid> = (props) => {


    const [items, setItems] = useState([]);
    const [activePhraseLibrary, setActivePhraseLibrary] = React.useState<IPhraseLibrary>(props.phraseLibrary);
    const [continuationToken, setContinuationToken] = React.useState(null);
    const [totalCount, setTotalCount] = React.useState<number>(0);
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(50);
    const [hasMoreData, setHasMoreData] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);

    const containerRef = React.useRef(null);
    const tableRef = React.useRef(null);


    const [activeColumn, setActiveColumn] = React.useState<IDataSetColumn>(undefined);
    const [showDeleteVerification, setShowDeleteVerification] = React.useState<boolean>(false);
    const [showMessageBar, setShowMessageBar] = React.useState<boolean>(false);
    const [messageBarText, setMessageBarText] = React.useState<string>("");
    const [messageBarType, setMessageBarType] = React.useState<MessageBarIntent>("info");

    const [previewOpen, setPreviewOpen] = React.useState<boolean>(false);
    const [previewDataSet, setPreviewDataSet] = React.useState([]);
    const [previewDataSetFile, setPreviewDataSetFile] = React.useState<string>("");
    const [previewDataSetFileCount, setPreviewDataSetFileCount] = React.useState<number>(0);
    const [isImporting, setIsImporting] = React.useState(false);
    const [isExporting, setIsExporting] = React.useState(false);

    const [columns, setColumns] = React.useState<TableColumnDefinition<DataSetColumnsType>[]>([]);
    const [noRefreshData, setNoRefreshData] = React.useState<boolean>(false);
    const [activeInput, setactiveInput] = React.useState<HTMLInputElement>(null);
    //const [multiLineEditItem, setMultiLineEditItem] = React.useState<DataSetColumnsType>(null);
    //const [multiLineEditCol, setMultiLineEditCol] = React.useState<string>(null);
    //const [multiLineEditValue, setMultiLineEditValue] = React.useState<string>(null);
    const isDark = usePersistentStore().darkMode;
    const portalConfig = useStore().portalConfig;
    const theme = isDark ? webDarkTheme : webLightTheme;
    const customTokens = themeToTokensObject(getCustomTheme(!isDark));



    var dirtyRow = false;
    const [selectedRows, setSelectedRows] = React.useState(
        new Set<TableRowId>([])
    );




    //function useMaintainScrollPosition(ref) {
    //    const scrollPosition = React.useRef(0);

    //    useLayoutEffect(() => {
    //        if (ref.current) {
    //            console.log('ref.current')
    //            ref.current.scrollTop = scrollPosition.current;
    //        }
    //    });

    //    useLayoutEffect(() => {
    //        console.log('useLayoutEffect', ref.current);

    //        const handler = () => {
    //            console.log('scroll', ref.current.scrollTop);
    //            scrollPosition.current = ref.current.scrollTop;
    //        };

    //        if (ref.current) {
    //            console.log('add event');
    //            ref.current.addEventListener('scroll', handler);
    //            return () => {
    //                if (ref.current) {

    //                    console.log('remove event');
    //                    ref.current.removeEventListener('scroll', handler);
    //                }
    //            };
    //        }
    //    }, [ref, ref.current]);
    //}

    
  
    
    const fetchData = async (searchTerm, page, pageSize, continuationToken, reset: boolean) => {
        setIsLoading(true);

        const queryParams = new URLSearchParams({
            searchTerm: searchTerm,
            continuationToken: continuationToken || '',
            pageSize: pageSize
        }).toString();

        console.info('fetchdata:' + page);

        try {

            const authResult = await getAuthObject();
            const data = await SearchPhraseLibraries(authResult, activePhraseLibrary.id, queryParams);


            let tmpItems = items.filter(x => x != undefined);



            if (reset) {
                tmpItems = [];
                setItems(tmpItems);
            }

            setItems([...tmpItems, ...data.result]);


            //setItems(prevData => [...prevData, ...data.result

            setContinuationToken(data.continuationToken); // Adjust according to the actual response structure
            setTotalCount(data.count);

            if (data.result.length + tmpItems.length < data.count) {
                setHasMoreData(true);
            }
            else {
                setHasMoreData(false);
            }





        } catch (error) {
            console.error('Error fetching data:', error);
            // Handle any errors here
        }
    };






    //const updateCellData = (element: HTMLInputElement) => {
    //    var rowid = element.getAttribute("data-id");
    //    var colid = element.getAttribute("data-col-id");
    //    var value = element.value;

    //    var tmp = items;

    //    setItems(prevItems => {
    //        var tmp = [...prevItems];
    //        var tmpRow = tmp.find(x => x.id == rowid);
    //        if (tmpRow[colid] != value) {
    //            //update data
    //            tmpRow[colid] = value;
    //            UpdateDataRow(activePhraseLibrary.id, tmpRow, false).then(() => {
    //                return tmp;
    //            });
    //        }
    //        return tmp;
    //    });




    //}

    const updateCellDataTextArea = (element: HTMLTextAreaElement,row_id:string) => {
        var rowid = element.getAttribute("data-id");
        var value = element.value;

        if (rowid != row_id)
        {
            rowid = row_id;
        }
        var tmp = items;
       
        setItems(prevItems => {
           
            var tmp = [...prevItems];
            var tmpRow = tmp.find(x => x.id == rowid);

            if (tmpRow == undefined)
                return tmp; 

            if (tmpRow.Phrase != value) {
                //update data
                tmpRow.Phrase = value;
                UpdateDataRow(activePhraseLibrary.id, tmpRow).then(() => {
                    return tmp;
                });
            }
            return tmp;
        });




    }

    const updateCellDataInput = (element: HTMLInputElement) => {
        var rowid = element.getAttribute("data-id");
        var value = element.value;

        var tmp = items;

        setItems(prevItems => {

            var tmp = [...prevItems];
            var tmpRow = tmp.find(x => x.id == rowid);
            if (tmpRow.Note != value) {
                //update data
                tmpRow.Note = value;
                UpdateDataRowNote(activePhraseLibrary.id, tmpRow).then(() => {
                    return tmp;
                });
            }
            return tmp;
        });




    }

   
    //const AfterContent = (currentItem: DataSetColumnsType, currentColId: string, currentElement: string) => {

    //    var colinfo = activePhraseLibrary.columns.find(x => x.id === currentColId);
    //    if (colinfo === undefined) { return; }

    //    if (colinfo.columnType === IDataSetColumnType.SingleLineText) {
    //        return <span>   </span>
    //    }

    //    return (

    //        <div>
    //            <EditRegular onClick={() => {

    //                setMultiLineEditParent(currentElement);
    //                setMultiLineEditCol(currentColId);
    //                setMultiLineEditValue(setDefaultValue(currentItem, currentColId));
    //                setMultiLineEditItem(currentItem);



    //            }} />

    //        </div>
    //    )

    //}
    type DataSetColumnsType = {
        Phrase: string;
        id: string;
    }

    const [focusedCell, setFocusedCell] = useState(null);

    <input
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        style={{ display: 'none' }}
        id="fileselect"
        onChange={onChangeFileInput} />


    function onChangeFileInput(e) {

        if (e.target.files.length) {

            var fileToLoad = e.target.files[0];

            let data = new FormData();
            data.append("file", fileToLoad);
            e.target.value = "";

            setPreviewOpen(true);
            getAuthObject().then((authResult) => {
                LoadPreviewPhraseLib(authResult, activePhraseLibrary.id, data).then((dataset) => {

                    if (dataset.error != "") {
                        alert(dataset.error);
                    }
                    else {
                        //setFileBlob(JSON.parse(dataset.jsonData));
                        setPreviewDataSet(JSON.parse(dataset.jsonData));
                        setPreviewDataSetFile(dataset.fileName);
                        setPreviewDataSetFileCount(dataset.totalLines);

                    }
                });
            });
        }
    };



    const ImportFile = (owerWrite: boolean) => {
        getAuthObject().then((authResult) => {
            ImportPreviewPhraseLib(authResult, activePhraseLibrary.id, previewDataSetFile, owerWrite).then((result) => {
                setPreviewDataSet([]);
                setPreviewDataSetFile("");
                setIsImporting(true);
                setPreviewOpen(false);



            });
        });
    }



    function ImportDialog() {

        return (

            <Dialog open={previewOpen}


            >


                <DialogSurface
                    style={{
                        maxWidth: "60vw",
                        minWidth: "60vw"
                    }}
                >

                    <DialogBody>
                        <DialogTitle
                            action={
                                <Button
                                    appearance="subtle"
                                    aria-label="Close"
                                    icon={<DismissRegular />}
                                    onClick={() => {
                                        setPreviewOpen(false); setPreviewDataSet([])
                                    }}
                                />
                            }
                        >
                            {previewDataSetFileCount == undefined || previewDataSetFileCount == 0 ? "Preview import from Excel" : "Preview import from Excel (first 20 records out of " + previewDataSetFileCount + ")"}
                        </DialogTitle>
                        <DialogContent>
                            {previewDataSet.length < 1 && <>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    Please Wait loading preview of data import.
                                    <Spinner size={"large"} />
                                </div>
                            </>}
                            <DetailsList
                                key={"import"}
                                styles={{ root: { height: "40vh", width: "100%" } }}
                                items={previewDataSet}
                              
                                layoutMode={DetailsListLayoutMode.fixedColumns}

                            >

                            </DetailsList>



                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                setPreviewOpen(false); setPreviewDataSet([])

                            }} >Close</Button>

                            {/*  {items.length > 0 && <Button appearance="primary"  onClick={() => { ImportFile(true); }}>Replace existing dataset</Button>}*/}
                            <Button appearance="primary" onClick={() => { setIsImporting(true); ImportFile(false); }}>Import</Button>



                        </DialogActions>


                    </DialogBody>
                </DialogSurface>
            </Dialog>

        )
    }

    const ImportingDialog = () => {
        return (
            <Dialog
                open={isImporting}
                modalType="alert">

                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>Importing in progress</DialogTitle>
                        <DialogContent>
                            The system is currently importing the data. Please check back later.
                        </DialogContent>
                        <DialogActions>

                            <Button appearance="primary"
                                onClick={() => {
                                    props.refresh()
                                    props.closeGrid(true);

                                }}

                            >Ok</Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        );
    };
    const ExportingDialog = () => {
        return (
            <Dialog
                open={isExporting}
                modalType="alert">

                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>Export in progress</DialogTitle>
                        <DialogContent>
                            The system is currently exporting the data. You will receive a email with the exported dataset.
                        </DialogContent>
                        <DialogActions>

                            <Button appearance="primary"
                                onClick={() => {
                                    props.refresh()
                                    props.closeGrid(true);

                                }}

                            >Ok</Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        );
    };

 

    function getColumns(): TableColumnDefinition<DataSetColumnsType>[] {
        var outlist: TableColumnDefinition<DataSetColumnsType>[] = [];

        var col2 = createTableColumn<DataSetColumnsType>({
            columnId: "Phrase",
        })

        outlist.push(col2);

        
        return outlist;
    }

    React.useEffect(() => {
        var tmpcol = getColumns();

        setColumns(tmpcol);

        if (!noRefreshData) {
            setItems([]);

            fetchData('', page, pageSize, continuationToken, true).then(() => {
                setIsLoading(false);

            });
        }
        else {
            setNoRefreshData(false);
        }

    }, [activePhraseLibrary])


    //const computeNextFocus = (currentItem, currentColumn) => {
    //    // Get current item index and column index
    //    const currentItemIndex = itemsRef.current.findIndex(item => item.id === currentItem["id"].toString());
    //    const currentColumnIndex = columnsRef.current.findIndex(col => col.columnId === currentColumn);

    //    let nextItem, nextColumn;

    //    // Check if it's the last column in the current item
    //    if (currentColumnIndex === columnsRef.current.length - 1) {
    //        // If it's the last item, loop back to the first item, else go to the next item
    //        nextItem = itemsRef.current[(currentItemIndex + 1) % itemsRef.current.length];
    //        nextColumn = columnsRef.current[0];
    //    } else {
    //        // Not the last column, so just move to the next column
    //        nextItem = currentItem;
    //        nextColumn = columnsRef.current[currentColumnIndex + 1];
    //    }

    //    return { nextItem, nextColumn };
    //};
    //const computePreviousFocus = (currentItem, currentColumn) => {
    //    // Get current item index and column index
    //    const currentItemIndex = itemsRef.current.findIndex(item => item.id === currentItem["id"]);
    //    const currentColumnIndex = columnsRef.current.findIndex(col => col.columnId === currentColumn);

    //    let prevItem, prevColumn;

    //    // Check if it's the first column in the current item
    //    if (currentColumnIndex === 0) {
    //        // If it's the first item, loop back to the last item, else go to the previous item
    //        prevItem = currentItemIndex === 0 ? itemsRef.current[items.length - 1] : itemsRef.current[currentItemIndex - 1];
    //        prevColumn = columnsRef.current[columnsRef.current.length - 1];
    //    } else {
    //        // Not the first column, so just move to the previous column
    //        prevItem = currentItem;
    //        prevColumn = columnsRef.current[currentColumnIndex - 1];
    //    }

    //    return { nextItem: prevItem, nextColumn: prevColumn };
    //};



    const ExportCurrentPhraseLibrary = async (phraseLib: IPhraseLibrary) => {

        var authObj = await getAuthObject();
        var result = await ExportPhraseLib(authObj, phraseLib.id);

        setShowMessageBar(true);
        setMessageBarType("success");
        setMessageBarText("Export started - you will receive the data in a Excel file on your email");




    }

    const UpdateDataRowNote = (phaseLibraryId: string, data: any): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            getAuthObject().then((authResult) => {
                UpdatePhraseLibRowNote(authResult, phaseLibraryId, data).then((Ok) => {
                    dirtyRow = false;
                    resolve(Ok);
                }).catch((error) => {
                    reject(error);
                });
            }).catch((error) => {
                reject(error);
            });
        });
    }

    const UpdateDataRow = (phaseLibraryId: string, data: any): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            getAuthObject().then((authResult) => {
                UpdatePhraseLibRow(authResult, phaseLibraryId, data).then((Ok) => {
                    dirtyRow = false;
                    resolve(Ok);
                }).catch((error) => {
                    reject(error);
                });
            }).catch((error) => {
                reject(error);
            });
        });
    }

    const AddDataRow = (phaseLibraryId: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            getAuthObject().then((authResult) => {
                AddPhraseLibRow(authResult, phaseLibraryId).then((dataRow) => {
                    dirtyRow = false;
                    resolve(dataRow);
                }).catch((error) => {
                    reject(error);
                });
            }).catch((error) => {
                reject(error);
            });
        });
    }

    const deleteDataRow = async (authResult: IUserProps, phraseLibId: string, rowId: string) => {

        await DeletePhraseLibRow(authResult, phraseLibId, rowId);


    }
    const DeleteSelectedRows = () => {
        return getAuthObject().then(async (authResult) => {
            var IdArray = Array.from(rows.filter(x => x.selected));

            var tmp = items;

            // Use map to create an array of promises for each deletion
            const deletePromises = IdArray.map(async (rowid) => {
                await deleteDataRow(authResult, activePhraseLibrary.id, rowid.item.id);
                return rowid.item.id; // Return the id of the deleted item
            });

            // Wait for all delete operations to complete
            const deletedIds = await Promise.all(deletePromises);

            // Filter out the deleted items
            tmp = tmp.filter(item => !deletedIds.includes(item.id));

            // Update the state after all deletions have been processed
            setItems(tmp);
        });
    };

    function minimalGuid(): string {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }

   

  

    const useStyles = makeStyles({
        headerContainer:
        {
            display: 'flex',
            justifyContent: 'space-between',

        },
        headerColumn1: {
            marginRight: 'auto'
        },
        tableHeader:
        {
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: tokens.colorNeutralBackground1
        }
        ,


    })
    const styles = useStyles();

    function Header() {
        return (
            <div className={styles.headerContainer}
                style={{
                    marginLeft: tokens.spacingHorizontalXL,
                    marginRight: tokens.spacingHorizontalXL,
                    marginTop: tokens.spacingVerticalXXXL,
                    marginBottom: tokens.spacingVerticalXL,
                }}

            >
                <div

                    className={styles.headerColumn1}>


                    <h3>Editing {props.phraseLibrary.name}</h3>
                </div>


                <Toolbar


                >
                    <ToolbarButton

                        icon={<DismissRegular />} onClick={() => {

                            props.closeGrid(true);


                        }} />
                </Toolbar>
            </div>

        )
    }

    const UpdateEnabledOnPhraseLibrary = (dataSet: IPhraseLibrary) => {
        getAuthObject().then((authResult) => {
            ChangePhraseLibraryEnabled(authResult, dataSet.id, dataSet.enabled).then((updateLibrary) => {
                setShowMessageBar(true);
                setMessageBarType("success");
                setMessageBarText(updateLibrary.enabled ? "Table now enabled" : "Table now disabled");
                setActivePhraseLibrary(updateLibrary);
                props.refresh();

            });
        });
    }

   

    function ShowMessageDialog() {

        return (


            <MessageBar key={messageBarType} intent={messageBarType}   >
                <MessageBarBody>
                    <MessageBarTitle>{messageBarText}</MessageBarTitle>


                </MessageBarBody>
                <MessageBarActions
                    containerAction={
                        <Button
                            aria-label="dismiss"
                            appearance="transparent"
                            icon={<DismissRegular />}
                            onClick={() => setShowMessageBar(false)}
                        />
                    }
                >
                </MessageBarActions>
            </MessageBar>


        )
    }

    const {
        getRows,
        selection: {
            allRowsSelected,
            someRowsSelected,
            toggleAllRows,
            toggleRow,
            isRowSelected,
        },
    } = useTableFeatures(
        {
            columns,
            items,

        },
        [
            useTableSelection({
                selectionMode: "multiselect",
                defaultSelectedItems: []

            }),
        ]
    );

    const rows = getRows((row) => {

        const selected = isRowSelected(row.rowId);
       

        return {
            ...row,
            onClick: (e: React.MouseEvent) => toggleRow(e, row.rowId),
            onKeyDown: (e: React.KeyboardEvent) => {
                //e.preventDefault();
                //toggleRow(e, row.rowId);
            },
            selected,
            appearance: selected ? ("brand" as const) : ("none" as const),
        };
    });

    const toggleAllKeydown = React.useCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => {
           
            if (e.key === " ") {
                //toggleAllRows(e);
                //e.preventDefault();
            }
        },
        [toggleAllRows]
    );

    const [listHeight, setListHeight] = useState(window.innerHeight * 0.7);
    useEffect(() => {
        const handleResize = () => {
            setListHeight(window.innerHeight * 0.7); // Update the height to be 70% of the viewport height
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

   

    return (

        <CustomFluentProvider isDark={isDark} colorConfiguration={portalConfig?.colorConfiguration}>
            <div
                style={{
                    height: "100vh",
                    width: "100vw",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 9999,
                    background:
                        `radial-gradient(circle at 64% 46%,${customTokens.gradientBackground1} 0,${customTokens.gradientBackground2} 57%,${customTokens.gradientBackground3} 100%)`

                }}
            >
                {showMessageBar && ShowMessageDialog()}
                {Header()}
                {ImportingDialog()}
                {ImportDialog()}
                {ExportingDialog()}
                <input
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    style={{ display: 'none' }}
                    id="fileselect"
                    onChange={onChangeFileInput} />

                <div
                    style={{
                        overflowX: "hidden",

                        backgroundColor: tokens.colorNeutralBackground1,
                        color: tokens.colorNeutralForeground1,
                        borderTopLeftRadius: tokens.borderRadiusXLarge,
                        borderTopRightRadius: tokens.borderRadiusXLarge,
                        marginLeft: tokens.spacingHorizontalL,
                        marginRight: tokens.spacingHorizontalL,
                        height: "80dvh",
                        width: "97vw"


                    }}>

                    <Toolbar aria-label="Vertical Button"  >
                        <ToolbarButton icon={<AddRegular></AddRegular>} onClick={() => {


                            var newRow = {
                                id: '',
                                DataSetId: activePhraseLibrary.id,
                                Type: 'DataSetRow',
                                Phrase : '',
                            };

                            AddDataRow(activePhraseLibrary.id).then((dataRow) => {
                                newRow.id = dataRow.id;
                            })
                           
                            setItems(prevData => [newRow, ...prevData]);


                        }}>Add Row
                        </ToolbarButton>
                       
                        <ToolbarButton disabled={rows.filter(x => x.selected).length == 0} icon={<DeleteRegular></DeleteRegular>} onClick={async () => {

                            await DeleteSelectedRows();


                        }}>Delete Row
                        </ToolbarButton>





                        <ToolbarButton icon={<ArrowExportRegular></ArrowExportRegular>} onClick={async () => {

                            await ExportCurrentPhraseLibrary(activePhraseLibrary);

                        }}>Export Excel
                        </ToolbarButton>
                        <ToolbarButton icon={<ArrowImportRegular />} onClick={() => {
                            document.getElementById("fileselect").click();


                        }}>Import Excel
                        </ToolbarButton>
                        <ToolbarButton icon={activePhraseLibrary.enabled ? <LockOpenRegular /> : <LockClosedRegular />} onClick={() => {
                            var _activeLib = activePhraseLibrary;
                            _activeLib.enabled = !_activeLib.enabled;
                            UpdateEnabledOnPhraseLibrary(_activeLib);


                        }}>{activePhraseLibrary.enabled ? "Disable" : "Enable"}
                        </ToolbarButton>

                      

                        {/*<ToolbarToggleButton*/}
                        {/*    aria-label="Underline"*/}
                        {/*    icon={<DocumentTableRegular />}*/}
                        {/*    name="textOptions"*/}
                        {/*    value="underline"*/}

                        {/*/>Drop down in questionnaire*/}
                    </Toolbar>
                    {/* {items.length} {hasMoreData?"HasMoreData":"NoMoreData"}*/}
                    <InfiniteScroll
                        height="74dvh"
                        dataLength={items.length}
                        next={() => fetchData('', page, pageSize, continuationToken, false)}

                        hasMore={hasMoreData}

                        loader={<h5>Loading ... </h5>}
                        endMessage={items.length > 50 ?
                            <p style={{ textAlign: 'center' }}>
                                <b>Yay! You have seen it all</b>
                            </p> : ""}

                    >
                        <Table
                            noNativeElements

                        >
                            <TableHeader
                                className={styles.tableHeader}
                            >
                                <TableRow>
                                    <TableSelectionCell
                                        checked={
                                            allRowsSelected ? true : someRowsSelected ? "mixed" : false
                                        }
                                        onClick={toggleAllRows}
                                        onKeyDown={toggleAllKeydown}
                                        checkboxIndicator={{ "aria-label": "Select all rows " }}
                                    />
                                    <TableHeaderCell>Phrase</TableHeaderCell>  
                                    <TableHeaderCell>Guidance note</TableHeaderCell>  
                                </TableRow>
                                
                            </TableHeader>



                            <TableBody


                            >



                                {rows.map(({ item, selected, onClick,  onKeyDown, appearance }) => (
                                    <TableRow
                                        key={item.id}
                                        aria-selected={selected}
                                        appearance={appearance}
                                        data-itemID={item.id}
                                        //onClick={onClick}
                                        //onKeyDown={onKeyDown}
                                        style={{ height: '50px' }}
                                    >
                                        <TableSelectionCell
                                           
                                            onClick={onClick}
                                            checked={selected}
                                            checkboxIndicator={{ "aria-label": "Select row" }}
                                        />
                                        <TableCell>
                                            <TableCellLayout style={{ width: '100%' }} >
                                                <Textarea
                                                    defaultValue={item.Phrase}
                                                    appearance="outline"
                                                    data-id={item.id}
                                                    onBlur={(e) => {
                                                       
                                                        updateCellDataTextArea(e.currentTarget,item.id);
                                                        setFocusedCell(null);

                                                    }}
                                                    style={{ width: `calc(${(window.innerWidth/2) - 75}px)` }}
                                                    resize="vertical"
                                                    maxLength={5000}
                                                    size="small"

                                                ></Textarea>
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell>
                                            <TableCellLayout style={{ width: '100%' }} >
                                                <Input
                                                    defaultValue={item.Note}
                                                    appearance="outline"
                                                    data-id={item.id}
                                                    onBlur={(e) => {
                                                        updateCellDataInput(e.currentTarget);
                                                        setFocusedCell(null);

                                                    }}
                                                    style={{ width: `calc(${(window.innerWidth /2) - 75}px)` }}
                                                   
                                                    maxLength={200}
                                                    size="small"

                                                ></Input>
                                            </TableCellLayout>
                                        </TableCell>

                                    </TableRow>
                                ))}

                            </TableBody>


                        </Table>
                    </InfiniteScroll>



                </div>
                <div
                    style={{
                        height: "33px",
                        width: "97vw",
                        backgroundColor: tokens.colorNeutralBackground1,
                        borderBottomRightRadius: tokens.borderRadiusXLarge,
                        borderBottomLeftRadius: tokens.borderRadiusXLarge,
                        marginLeft: tokens.spacingHorizontalL,
                        marginRight: tokens.spacingHorizontalL,
                    }}
                />

            </div>

        </CustomFluentProvider>

    )
}